// src/components/ModalForm.js

import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import dayjs from "dayjs";

const AddComponentsModal = ({
  visible,
  onClose,
  brandList,
  vendorList,
  brandLoading,
  vendorLoading,
  userData,
  getList = () => {},
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const isEdit = Object.keys(selectedRow || {})?.length > 0;

  const AddComponentAction = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `addcomponent`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        form.resetFields();
        message.destroy();
        getList();
        message.success(response?.message);
        onClose();
      } else {
        setSubmitLoading(false);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setSubmitLoading(false);
      message.error(error?.message);
    }
  };

  const EditComponentAction = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `update-component/${selectedRow?.id}`,
        "PATCH",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        form.resetFields();
        message.destroy();
        getList();
        message.success(response?.message);
        onClose();
      } else {
        setSubmitLoading(false);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setSubmitLoading(false);
      message.error(error?.message);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (isEdit) {
          EditComponentAction({
            ...values,
            date_active: values.date_active?.format("YYYY-MM-DD"),
          });
          return;
        }
        AddComponentAction({
          ...values,
          date_active: values.date_active?.format("YYYY-MM-DD"),
        });
      })
      .catch((info) => {});
  };

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        ...selectedRow,
        date_active: dayjs(selectedRow?.date_active, "YYYY-MM-DD"),
      });
    }
  }, [isEdit]);

  return (
    <Modal
      title={isEdit ? "Edit Component" : "Add Component"}
      visible={visible}
      onCancel={onClose}
      onOk={handleOk}
      okText={isEdit ? "Update" : "Submit"}
      width={800}
      okButtonProps={{ loading: submitLoading }}
    >
      <Form form={form} layout="vertical" name="itemForm">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="item_description"
              label="Item Description"
              rules={[
                { required: true, message: "Please enter item description!" },
              ]}
            >
              <Input placeholder="Enter item description" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="unit_cost"
              label="Unit Cost"
              rules={[{ required: true, message: "Please enter unit cost!" }]}
            >
              <InputNumber
                min={0}
                size="large"
                placeholder="Enter unit cost"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="upc"
              label="UPC"
              rules={[{ required: true, message: "Please enter UPC!" }]}
            >
              <Input placeholder="Enter UPC" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="case_quantity"
              label="Case Quantity"
              rules={[
                { required: true, message: "Please enter case quantity!" },
              ]}
            >
              <InputNumber
                min={1}
                size="large"
                placeholder="Enter case quantity"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="brand_id"
              label="Select Brand"
              rules={[{ required: true, message: "Please select a brand!" }]}
            >
              <Select
                size="large"
                placeholder="Select a brand"
                loading={brandLoading}
                options={brandList?.map((d) => ({
                  label: d?.b_name,
                  value: d?.b_id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vendor_id"
              label="Select Vendor"
              rules={[{ required: true, message: "Please select a vendor!" }]}
            >
              <Select
                size="large"
                placeholder="Select a vendor"
                loading={vendorLoading}
                options={vendorList?.map((d) => ({
                  label: d?.name,
                  value: d?.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="moq"
              label="MOQ"
              rules={[{ required: true, message: "Please enter MOQ!" }]}
            >
              <InputNumber
                min={1}
                size="large"
                placeholder="Enter MOQ"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vendor_sku"
              label="Vendor SKU"
              rules={[{ required: true, message: "Please enter vendor SKU!" }]}
            >
              <Input placeholder="Enter vendor SKU" />
            </Form.Item>
          </Col>
          {!isEdit && (
            <>
              <Col span={8}>
                <Form.Item name="unit_cost" label="Unit Cost History">
                  <Input placeholder="Enter unit cost history" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="vendor" label="Vendor History">
                  <Select
                    size="large"
                    placeholder="Select a Vendor History"
                    loading={vendorLoading}
                    options={vendorList?.map((d) => ({
                      label: d?.name,
                      value: d?.id,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="history_note" label="History Note">
                  <Input placeholder="Enter history note" />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={8}>
            <Form.Item
              name="date_active"
              label="Active Date"
              rules={[
                { required: true, message: "Please select an active date!" },
              ]}
            >
              <DatePicker size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="note" label="Component Note">
              <Input placeholder="Enter component note" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddComponentsModal;
