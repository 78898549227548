import {
  Avatar,
  Button,
  DatePicker,
  message,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import React, { useLayoutEffect, useState } from "react";
import { DotChartOutlined } from "@ant-design/icons";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import dayjs from "dayjs";
import { MakeApiCall } from "../../../apis";
import {
  ConvertParams,
  FormatNumber,
  PropsFilter,
  RenderTable,
} from "../../../config";
import MarketplaceSelect from "../../../Components/marketplace";
import RangePickerCF from "../../../Components/datepicker";
import Table1 from "../../../Components/tables/table1";
import { TableLoading } from "../../../Components/table-animation";
import { CFTooltip } from "../../../Components/tooltip";
import { render } from "less";

export default function SubscribeAndSave({ userData }) {
  const sign = "$";

  const [cardsData, setCardsData] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [filtersList, setFiltersList] = useState({});

  const [tableData, setTableData] = useState({});

  const [selectedGraphData, setSelectedGraphData] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    marketplace_id: null,
    brand_id: null,
    product_line_id: null,
    sku: null,
    asin: null,
    start_date: dayjs().add(-6, "d").subtract(1, "d").format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    period_start_date: dayjs()
      .add(-15, "d")
      .subtract(2, "d")
      .format("YYYY-MM-DD"),
    period_end_date: dayjs().add(-7, "d").subtract(1, "d").format("YYYY-MM-DD"),
  });

  const [loaders, setLoaders] = useState({
    marketplace: true,
    filters: true,
    table: true,
    cards: true,
  });

  const userSettingFilter = async (data) => {
    try {
      setFiltersList({});
      setLoaders((prev) => ({
        ...prev,
        filters: true,
      }));
      const {
        start_date,
        end_date,
        period_start_date,
        period_end_date,
        ...rest
      } = data;
      const response = await MakeApiCall(
        `user-setting/filters${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setFiltersList(response?.data);
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
      } else {
        setFiltersList({});
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setFiltersList({});
      setLoaders((prev) => ({
        ...prev,
        filters: false,
      }));
    }
  };

  const SNSForecastCardData = async (data) => {
    try {
      setLoaders((prev) => ({
        ...prev,
        cards: true,
      }));
      const response = await MakeApiCall(
        `sns-forecast-data${ConvertParams(data)}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const product_line_chart = Object.entries(
          response?.data?.product_line_chart || {}
        )?.map(([key, value]) => ({
          label: key,
          value: key,
          pr: value,
        }));

        setSelectedGraphData(product_line_chart?.slice(0, 5) || []);

        setCardsData(
          { ...response?.data, product_line_chart: product_line_chart } || {}
        );
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
      } else {
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setLoaders((prev) => ({
        ...prev,
        cards: false,
      }));
    }
  };
  const SNSForecastTableData = async (data) => {
    try {
      setLoaders((prev) => ({
        ...prev,
        table: true,
      }));
      const response = await MakeApiCall(
        // `sns-subscription-data${ConvertParams(data)}`, // ${ConvertParams(data)}
        `sns-subscription-data?marketplace_id=ATVPDKIKX0DER&brand_id&product_line_id&asin&sku`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setTableData(response?.data?.subscription_data || {});
        setLoaders((prev) => ({
          ...prev,
          table: false,
        }));
      } else {
        setLoaders((prev) => ({
          ...prev,
          table: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setLoaders((prev) => ({
        ...prev,
        table: false,
      }));
    }
  };

  const callAfterMarketPlace = async (marketplace_id) => {
    try {
      await userSettingFilter({ marketplace_id });
      await SNSForecastCardData({ ...selectedFilters, marketplace_id });
      await SNSForecastTableData({ ...selectedFilters, marketplace_id });
    } catch (error) {
      message.warning(error?.message);
    }
  };

  const MassFire = async (data) => {
    try {
      await SNSForecastCardData({ ...data });
      await SNSForecastTableData({ ...data });
    } catch (error) {
      message.warning(error?.message);
    }
  };

  const FilterUpdate = (data) => {
    userSettingFilter(data);
  };

  useLayoutEffect(() => {
    if (selectedGraphData?.length === 0) return;
    am5.addLicense("AM5C1231231231");
    const root = am5.Root.new(document.getElementById("chartdiv"));

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        innerRadius: am5.percent(20),
        startAngle: -90,
        endAngle: 180,
        creditsDisabled: true,
      })
    );
    chart.set("credits", null);
    // Data

    const data = selectedGraphData?.map((d, i) => ({
      category: d?.label,
      value: d?.pr,
      full: 100,
      columnSettings: {
        fill: chart.get("colors").getIndex(i),
      },
      creditsDisabled: true,
    }));

    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    const cursor = chart.set(
      "cursor",
      am5radar.RadarCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineY.set("visible", false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    const xRenderer = am5radar.AxisRendererCircular.new(root, {
      //minGridDistance: 50
    });

    xRenderer.labels.template.setAll({
      radius: 10,
    });

    xRenderer.grid.template.setAll({
      forceHidden: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'",
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 20,
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: "500",
      fontSize: 14,
      templateField: "columnSettings",
    });

    yRenderer.grid.template.setAll({
      forceHidden: true,
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
      })
    );

    yAxis.data.setAll(data);

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    const series1 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "full",
        categoryYField: "category",
        fill: root.interfaceColors.get("alternativeBackground"),
      })
    );

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20,
    });

    series1.data.setAll(data);

    const series2 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "value",
        categoryYField: "category",
      })
    );

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "{category}: {valueX}%",
      cornerRadius: 20,
      templateField: "columnSettings",
    });

    series2.data.setAll(data);

    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [selectedGraphData]);

  const formatData = (data) => {
    const formattedData = [];
    let keyCounter = 1;

    for (const brandName in data) {
      if (data.hasOwnProperty(brandName)) {
        const brandData = data[brandName];
        const formattedBrand = {
          key: keyCounter.toString(),
          brand: brandName,
          children: [],
        };

        // Get all years dynamically
        const years = Object.keys(brandData);

        // Dynamically add year-specific fields for brand data
        years.forEach((year) => {
          formattedBrand[`year`] = years;
          formattedBrand[`current_value${year}`] = `${brandData[
            year
          ]?.active_subscriptions?.current_value.toFixed(2)}`;
          formattedBrand[`change${year}`] =
            brandData[year]?.active_subscriptions.change;
          formattedBrand[`variance${year}`] =
            brandData[year]?.active_subscriptions.variance;
        });

        formattedBrand[`children`] = Object.entries(
          data?.[brandName] || {}
        ).reduce((acc, [key, value]) => {
          const childData = value?.child_data?.map((g) => ({
            ...g,
            year: key,
            [`current_value${key}`]: `${g?.active_subscriptions?.current_value.toFixed(
              2
            )}`,
            [`change${key}`]: g?.active_subscriptions?.change,
            [`variance${key}`]: g?.active_subscriptions?.variance,
          }));
          return [...acc, ...(childData || [])];
        }, []);

        formattedData.push(formattedBrand);
        keyCounter++;
      }
    }

    return formattedData;
  };

  const columns__ = [
    {
      title: "#",
      dataIndex: "expand",
      key: "expand",
      align: "center",
      fixed: "left",
      width: 60,
      render: (_, record, index) => {
        return {
          props: {
            style: {
              padding: "15px",
              opacity: record?.children ? 1 : 0,
              display: "flex",
              alignItems: "center",
            },
          },
        };
      },
    },
    {
      title: "Brand",

      fixed: "left",
      width: 300,
      render: (e) => {
        if (!e?.children) {
          return (
            <div
              className="d-flex align-items-center"
              style={{ color: "#252f4a", fontWeight: "bold" }}
            >
              <Avatar
                shape="square"
                className="me-3"
                src={e?.image_url}
                loading="lazy"
                size={40}
              />
              <div className="max-2">{e?.product_name || "-"}</div>
            </div>
          );
        }
        return (
          <div style={{ color: "#0079ae", fontWeight: "bold" }}>
            <CFTooltip placement={"right"} row={2} rule>
              {e?.brand}
            </CFTooltip>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: "Item",
      width: 500,
      filterKey: "asin",
      render: (d) => {
        return (
          <div>
            <a href className="fw-bolder fs-6 text-gray-800">
              {d?.product_name || ""}
            </a>
            <div className="fs-7 mt-1 text-gray-600">
              ASIN: <b>{d?.asin}</b>
            </div>
          </div>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Active Subscriptions",
      dataIndex: "active_subscriptions",
      key: "active_subscriptions",
    },
    {
      title: "Week 1 Start Date",
      dataIndex: "week_1_start_date",
      key: "week_1_start_date",
    },
    {
      title: "Scheduled Units Week 1",
      dataIndex: "scheduled_sns_units_week_1",
      key: "scheduled_sns_units_week_1",
    },
    {
      title: "Scheduled Units Week 2",
      dataIndex: "scheduled_sns_units_week_2",
      key: "scheduled_sns_units_week_2",
    },
    {
      title: "Scheduled Units Week 3",
      dataIndex: "scheduled_sns_units_week_3",
      key: "scheduled_sns_units_week_3",
    },
    {
      title: "Scheduled Units Week 4",
      dataIndex: "scheduled_sns_units_week_4",
      key: "scheduled_sns_units_week_4",
    },
    {
      title: "Scheduled Units Week 5",
      dataIndex: "scheduled_sns_units_week_5",
      key: "scheduled_sns_units_week_5",
    },
    {
      title: "Scheduled Units Week 6",
      dataIndex: "scheduled_sns_units_week_6",
      key: "scheduled_sns_units_week_6",
    },
    {
      title: "Scheduled Units Week 7",
      dataIndex: "scheduled_sns_units_week_7",
      key: "scheduled_sns_units_week_7",
    },
    {
      title: "Scheduled Units Week 8",
      dataIndex: "scheduled_sns_units_week_8",
      key: "scheduled_sns_units_week_8",
    },
  ];

  const SubscriptionSort = (a, b, d) => {
    if (!b?.children && !a?.children && isExpanded) {
      return (
        parseFloat(a[`current_value${d}`] || 0) -
        parseFloat(b[`current_value${d}`] || 0)
      );
    }
    if (b?.children && a?.children && !isExpanded) {
      return (
        parseFloat(a[`current_value${d}`] || 0) -
        parseFloat(b[`current_value${d}`] || 0)
      );
    }
  };

  const VarianceSort = (a, b, d) => {
    if (!b?.children && !a?.children && isExpanded) {
      return (
        parseFloat(a[`variance${d}`] || 0) - parseFloat(b[`variance${d}`] || 0)
      );
    }
    if (b?.children && a?.children && !isExpanded) {
      return (
        parseFloat(a[`variance${d}`] || 0) - parseFloat(b[`variance${d}`] || 0)
      );
    }
  };

  const ChangeSort = (a, b, d) => {
    if (!b?.children && !a?.children && isExpanded) {
      return (
        parseFloat(a[`change${d}`] || 0) - parseFloat(b[`change${d}`] || 0)
      );
    }
    if (b?.children && a?.children && !isExpanded) {
      return (
        parseFloat(a[`change${d}`] || 0) - parseFloat(b[`change${d}`] || 0)
      );
    }
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <MarketplaceSelect
            showSearch
            callAfterMarketPlace={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };

              callAfterMarketPlace(e);
              setSelectedFilters(obj);
            }}
            MarketplaceData={(e) => {
              setLoaders({
                ...loaders,
                marketplace: false,
              });
              const obj = {
                ...selectedFilters,
                marketplace_id: e?.[0]?.value,
              };

              setSelectedFilters(obj);
            }}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };
              MassFire(obj);
              FilterUpdate(obj);
              setSelectedFilters(obj);
            }}
            userData={userData}
          />
        </div>
        <div className="position-relative">
          <RangePickerCF
            size="large"
            style={{ width: "240px" }}
            needConfirm
            allowClear={false}
            id={Math.random()}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                start_date: dayjs(e[0]).format("YYYY-MM-DD"),
                end_date: dayjs(e[1]).format("YYYY-MM-DD"),
              };
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
            value={[
              dayjs(selectedFilters?.start_date, "YYYY-MM-DD"),
              dayjs(selectedFilters?.end_date, "YYYY-MM-DD"),
            ]}
          />
        </div>
        <div className="position-relative">
          <RangePickerCF
            size="large"
            style={{ width: "240px" }}
            needConfirm
            allowClear={false}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                period_start_date: dayjs(e[0]).format("YYYY-MM-DD"),
                period_end_date: dayjs(e[1]).format("YYYY-MM-DD"),
              };
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
            value={[
              dayjs(selectedFilters?.period_start_date, "YYYY-MM-DD"),
              dayjs(selectedFilters?.period_end_date, "YYYY-MM-DD"),
            ]}
            id={Math.random()}
          />
        </div>

        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            showSearch
            placeholder="Select Brand"
            optionFilterProp="value"
            dropdownMatchSelectWidth={false}
            options={filtersList?.brand?.map((d) => ({
              ...d,
              label: d?.label,
            }))}
            allowClear
            loading={loaders?.filters}
            value={selectedFilters?.brand_id}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                brand_id: e,
              };
              FilterUpdate(obj);
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-3">
          <div className="border rounded border-dashed border-primary bg-white p-3">
            <div className="d-flex align-items-center mb-0 pe-5">
              <div className="symbol symbol-45px w-40px me-5">
                <span className="symbol-label bg-primary shadow-sm">
                  <i
                    className="ki-duotone ki-tag text-light"
                    style={{ fontSize: "2.25rem" }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </span>
              </div>
              <div className="d-flex align-items-center flex-wrap w-100">
                <div className="mb-1 pe-3 flex-grow-1">
                  <a
                    href="#"
                    className="fs-5 text-gray-800 text-hover-primary fw-semibold"
                  >
                    Current Subscriptions
                  </a>
                </div>
                <div className="d-flex align-items-center ms-4">
                  <div
                    className="fw-bolder fs-3 text-gray-800 pe-1 counted"
                    data-kt-countup="true"
                    data-kt-countup-value={14581}
                    data-kt-countup-prefix
                    data-kt-initialized={1}
                  >
                    14,581
                  </div>
                  <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="border rounded border-dashed border-gray-800 p-3">
            <div className="d-flex align-items-center mb-0 pe-5">
              <div className="symbol symbol-45px w-40px me-5">
                <span className="symbol-label bg-dark shadow-sm">
                  <i className="ki-duotone ki-directbox-default fs-1 text-light">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </span>
              </div>
              <div className="d-flex align-items-center flex-wrap w-100">
                <div className="mb-1 pe-3 flex-grow-1">
                  <a
                    href="#"
                    className="fs-5 text-gray-800 text-hover-primary fw-semibold"
                  >
                    Previous Subscriptions
                  </a>
                </div>
                <div className="d-flex align-items-center ms-4">
                  <div
                    className="fw-bolder fs-3 text-gray-800 pe-1 counted"
                    data-kt-countup="true"
                    data-kt-countup-value={14699}
                    data-kt-countup-prefix
                    data-kt-initialized={1}
                  >
                    14,699
                  </div>
                  <i className="ki-duotone ki-arrow-up fs-5 text-success ms-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row gx-5 gx-xl-7">
        <div className="col-xl-4 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  SnS by Product Line
                </span>
              </h3>
              <div className="card-toolbar">
                <Select
                  className="w-200px"
                  mode="tags"
                  variant="filled"
                  allowClear
                  onChange={(e, r) => {
                    setSelectedGraphData(r);
                  }}
                  // maxTagPlaceholder={(omittedValues) => (
                  //   <Tooltip
                  //     overlayStyle={{
                  //       pointerEvents: "none",
                  //     }}
                  //     title={omittedValues.map(({ label }) => label).join(", ")}
                  //   >
                  //     <span>Hover Me</span>
                  //   </Tooltip>
                  // )}
                  maxTagCount={"responsive"}
                  value={selectedGraphData?.map((d) => d?.value)}
                  options={cardsData?.product_line_chart}
                  loading={loaders?.cards}
                />
              </div>
            </div>
            <div className="card-body h-400px position-relative pt-0 pb-0">
              {loaders?.cards ? (
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    margin: "auto",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <div
                  id="chartdiv"
                  style={{ width: "100%", height: "400px" }}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-8 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100 mb-5">
            <div className="card-header mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  SnS Forecast
                </span>
              </h3>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body pt-0 pb-0">
              {loaders?.cards ? (
                <TableLoading
                  id="test-table"
                  row={6}
                  columns={[1, 2, 3, 4, 5, 6, 7, 8]}
                  checkBoxVal={false}
                  actions={[]}
                  style={{ height: "330px", overflow: "hidden" }}
                />
              ) : (
                <Table1
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  pagination={false}
                  columns={columns?.map((d) => ({
                    ...d,
                    ...PropsFilter(d?.dataIndex || d?.filterKey),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d),
                  }))}
                  dataSource={cardsData?.table_data}
                  scroll={{ x: "max-content", y: 330 }}
                />
              )}

              <div className="table-responsive d-none">
                <table className="table align-middle table-row-dashed border-gray-300 table-row-gray-300 gy-3 gx-4 gs-4 mb-0">
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bolder text-gray-800 bg-main-light">
                      <th className="min-w-250px ">Item</th>
                      <th className="min-w-150px ">Brand</th>
                      <th className="min-w-175px ">SKU</th>
                      <th className="min-w-175px ">Active Subscriptions</th>
                      <th className="min-w-150px ">Week 1 Start Date</th>
                      <th className="min-w-200px">Scheduled Units Week 1</th>
                      <th className="min-w-200px">Scheduled Units Week 2</th>
                      <th className="min-w-200px">Scheduled Units Week 3</th>
                      <th className="min-w-200px">Scheduled Units Week 4</th>
                      <th className="min-w-200px">Scheduled Units Week 5</th>
                      <th className="min-w-200px">Scheduled Units Week 6</th>
                      <th className="min-w-200px">Scheduled Units Week 7</th>
                      <th className="min-w-200px">Scheduled Units Week 8</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Nuggets, Cat, Turkey, 5.5 oz
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Primal Pet Foods
                          </a>
                        </div>
                      </td>
                      <td>PARIMAL2-DGDUCK14-B00BP2VC</td>
                      <td>47</td>
                      <td>4/28/2024</td>
                      <td>2</td>
                      <td>0</td>
                      <td>4</td>
                      <td>1</td>
                      <td>4</td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Nuggets, Cat, Turkey, 5.5 oz
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Primal Pet Foods
                          </a>
                        </div>
                      </td>
                      <td>PARIMAL2-DGDUCK14-B00BP2VC</td>
                      <td>47</td>
                      <td>4/28/2024</td>
                      <td>2</td>
                      <td>0</td>
                      <td>4</td>
                      <td>1</td>
                      <td>4</td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Nuggets, Cat, Turkey, 5.5 oz
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Primal Pet Foods
                          </a>
                        </div>
                      </td>
                      <td>PARIMAL2-DGDUCK14-B00BP2VC</td>
                      <td>47</td>
                      <td>4/28/2024</td>
                      <td>2</td>
                      <td>0</td>
                      <td>4</td>
                      <td>1</td>
                      <td>4</td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Nuggets, Cat, Turkey, 5.5 oz
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Primal Pet Foods
                          </a>
                        </div>
                      </td>
                      <td>PARIMAL2-DGDUCK14-B00BP2VC</td>
                      <td>47</td>
                      <td>4/28/2024</td>
                      <td>2</td>
                      <td>0</td>
                      <td>4</td>
                      <td>1</td>
                      <td>4</td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                  {/* <tfoot>
                                                      <tr class="bg-gray-100 fw-bolder border-top border-bottom border-dashed border-gray-300">
                                                          <th colspan="3">Total</th>
                                                          <th>$381,911.19</th>
                                                          <th>8</th>
                                                          <th>10</th>
                                                          <th>199.55</th>
                                                          <th>361</th>
                                                          <th>571</th>
                                                          <td>167</td>
                                                          <td>921</td>
                                                        
                                                      </tr>
                                                  </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-12 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  SnS Subscriptions
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pb-0 pt-0 ">
              {loaders?.table ? (
                <TableLoading
                  id="test-table"
                  row={10}
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={false}
                  actions={[]}
                  style={{ height: "calc(100vh - 500px)", overflow: "hidden" }}
                />
              ) : (
                <Table1
                  columns={[
                    ...columns__,
                    ...formatData(tableData)?.[0]?.year?.map((d) => ({
                      title: d,
                      children: [
                        {
                          title: "Subscriptions",
                          dataIndex: `current_value${d}`,
                          key: `current_value${d}`,
                          align: "center",
                          sorter: (a, b) => SubscriptionSort(a, b, d),
                          render: (e) => {
                            return <>${FormatNumber(e || 0)}</>;
                          },
                        },
                        {
                          title: "Variance",
                          dataIndex: `variance${d}`,
                          key: `variance${d}`,
                          align: "center",
                          sorter: (a, b) => VarianceSort(a, b, d),
                          render: (e) => {
                            return FormatNumber(e || 0);
                          },
                        },
                        {
                          title: "Change",
                          dataIndex: `change${d}`,
                          key: `change${d}`,
                          align: "center",
                          sorter: (a, b) => ChangeSort(a, b, d),
                          render: (e) => {
                            return FormatNumber(e || 0);
                          },
                        },
                      ],
                    })),
                  ]}
                  dataSource={formatData(tableData)}
                  rowKey={"key"}
                  scroll={{ x: "max-content" }}
                  pagination={false}
                  expandable={{
                    indentSize: 0,

                    expandIconColumnIndex: 0, // Change this to the index of the column where you want the icon
                    expandIcon: ({ expanded, onExpand, record }) => (
                      <Button
                        icon={
                          <div
                            className="symbol symbol-30px  cursor-pointer"
                            style={{
                              transform: `rotate(${expanded ? 90 : 0}deg)`,
                            }}
                          >
                            <span className="symbol-label accordion-icon bg-light-primary">
                              <i className="ki-outline ki-right fs-3 text-gray-600" />
                            </span>
                          </div>
                        }
                        onClick={(e) => {
                          onExpand(record, e);

                          setIsExpanded(!isExpanded);
                        }}
                        size="small"
                        style={{ border: "none", background: "none" }}
                      />
                    ),
                  }}
                />
              )}
              <div className="d-none table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4">
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bolder text-gray-800 bg-white border-top border-dashed fs-6">
                      <th
                        colSpan={2}
                        className="border-end border-dashed border-gray-300 w-275px "
                        style={{ verticalAlign: "middle" }}
                      />
                      <th
                        className="min-w-225px text-center border-end border-dashed border-gray-300"
                        colSpan={3}
                      >
                        November 2023
                      </th>
                      <th
                        className="min-w-225px text-center border-end border-dashed border-gray-300"
                        colSpan={3}
                      >
                        December 2023
                      </th>
                      <th
                        className="min-w-225px text-center border-end border-dashed border-gray-300"
                        colSpan={3}
                      >
                        January 2024
                      </th>
                      <th
                        className="min-w-225px text-center border-gray-300"
                        colSpan={3}
                      >
                        Year 2021
                      </th>
                    </tr>
                    <tr className="fw-bolder text-gray-800 bg-main-light">
                      <th className="w-75px " />
                      <th className="w-200px border-end border-dashed border-gray-300 ">
                        Brand
                      </th>
                      <th className="min-w-75px">Subscriptions</th>
                      <th className="min-w-75px">Variance</th>
                      <th className="min-w-75px border-end border-dashed border-gray-300">
                        Change
                      </th>
                      <th className="min-w-75px">Subscriptions</th>
                      <th className="min-w-75px">Variance</th>
                      <th className="min-w-75px border-end border-dashed border-gray-300">
                        Change
                      </th>
                      <th className="min-w-75px ">Subscriptions</th>
                      <th className="min-w-75px ">Variance</th>
                      <th className="min-w-75px border-end border-dashed border-gray-300">
                        Change
                      </th>
                      <th className="min-w-75px ">Subscriptions</th>
                      <th className="min-w-75px ">Variance</th>
                      <th className="min-w-75px ">Change</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                    <tr>
                      <td className="expand ">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_1"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td className>
                        <div className=" min-w-175px">
                          <a href className="text-primary fs-6 fw-bold ">
                            Primal Pet Food{" "}
                          </a>
                        </div>
                      </td>
                      <td>9,347</td>
                      <td>9,347</td>
                      <td>0</td>
                      <td>9,568</td>
                      <td>221</td>
                      <td>2.36%</td>
                      <td>9,404</td>
                      <td>-168</td>
                      <td>-1.71%</td>
                      <td>10,196</td>
                      <td>792</td>
                      <td>8.42%</td>
                    </tr>
                    <tr className>
                      <td colSpan={14} className="hiddenRow p-0 ">
                        <div
                          className=" collapse show"
                          id="kt_accordion_3a_item_1"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            {/*begin::Table head*/}
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="w-200px border-end border-dashed border-gray-300  p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td className />
                                <td className>
                                  <div className=" w-175px">
                                    <a
                                      href
                                      className="text-gray-800 fs-6 fw-bold "
                                    >
                                      B007CVS555S
                                    </a>
                                  </div>
                                </td>
                                <td>9,347</td>
                                <td>9,347</td>
                                <td>0</td>
                                <td>9,568</td>
                                <td>221</td>
                                <td>2.36%</td>
                                <td>9,404</td>
                                <td>-168</td>
                                <td>-1.71%</td>
                                <td>10,196</td>
                                <td>792</td>
                                <td>8.42%</td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td />
                                <td>
                                  <a href className="text-gray-800  fw-bold">
                                    B007CVS555S{" "}
                                  </a>
                                </td>
                                <td>9,347</td>
                                <td>9,347</td>
                                <td>0</td>
                                <td>9,568</td>
                                <td>221</td>
                                <td>2.36%</td>
                                <td>9,404</td>
                                <td>-168</td>
                                <td>-1.71%</td>
                                <td>10,196</td>
                                <td>792</td>
                                <td>8.42%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_2"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Charlee Beer
                        </a>
                      </td>
                      <td>9,347</td>
                      <td>9,347</td>
                      <td>0</td>
                      <td>9,568</td>
                      <td>221</td>
                      <td>2.36%</td>
                      <td>9,404</td>
                      <td>-168</td>
                      <td>-1.71%</td>
                      <td>10,196</td>
                      <td>792</td>
                      <td>8.42%</td>
                    </tr>
                    <tr className>
                      <td colSpan={33} className="hiddenRow p-0 ">
                        <div
                          className=" collapse  "
                          id="kt_accordion_3a_item_2"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="w-200px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td />
                                <td>
                                  <a href className="text-gray-800  fw-bold">
                                    B007CVS555S{" "}
                                  </a>
                                </td>
                                <td>9,347</td>
                                <td>9,347</td>
                                <td>0</td>
                                <td>9,568</td>
                                <td>221</td>
                                <td>2.36%</td>
                                <td>9,404</td>
                                <td>-168</td>
                                <td>-1.71%</td>
                                <td>10,196</td>
                                <td>792</td>
                                <td>8.42%</td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td />
                                <td>
                                  <a href className="text-gray-800  fw-bold">
                                    B007CVS555S{" "}
                                  </a>
                                </td>
                                <td>9,347</td>
                                <td>9,347</td>
                                <td>0</td>
                                <td>9,568</td>
                                <td>221</td>
                                <td>2.36%</td>
                                <td>9,404</td>
                                <td>-168</td>
                                <td>-1.71%</td>
                                <td>10,196</td>
                                <td>792</td>
                                <td>8.42%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="expand">
                        <div
                          className="symbol symbol-30px cursor-pointer"
                          aria-expanded="false"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_3a_item_3"
                        >
                          <span className="symbol-label accordion-icon bg-light-primary">
                            <i className="ki-outline ki-right fs-3 text-gray-600" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href className="text-primary fs-6 fw-bold">
                          Cobalt
                        </a>
                      </td>
                      <td>9,347</td>
                      <td>9,347</td>
                      <td>0</td>
                      <td>9,568</td>
                      <td>221</td>
                      <td>2.36%</td>
                      <td>9,404</td>
                      <td>-168</td>
                      <td>-1.71%</td>
                      <td>10,196</td>
                      <td>792</td>
                      <td>8.42%</td>
                    </tr>
                    <tr className>
                      <td colSpan={33} className="hiddenRow p-0 ">
                        <div
                          className=" collapse  "
                          id="kt_accordion_3a_item_3"
                          data-bs-parent="#kt_accordion_3a"
                          style={{}}
                        >
                          <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4 mb-0">
                            <thead>
                              <tr className="fw-bolder text-gray-800 bg-gray-100">
                                <th className="w-75px p-0" />
                                <th className="w-200px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px border-end border-dashed border-gray-300 p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                                <th className="min-w-75px p-0" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td />
                                <td>
                                  <a href className="text-gray-800  fw-bold">
                                    B007CVS555S{" "}
                                  </a>
                                </td>
                                <td>9,347</td>
                                <td>9,347</td>
                                <td>0</td>
                                <td>9,568</td>
                                <td>221</td>
                                <td>2.36%</td>
                                <td>9,404</td>
                                <td>-168</td>
                                <td>-1.71%</td>
                                <td>10,196</td>
                                <td>792</td>
                                <td>8.42%</td>
                              </tr>
                              <tr style={{ backgroundColor: "#f1f8fa" }}>
                                <td />
                                <td>
                                  <a href className="text-gray-800  fw-bold">
                                    B007CVS555S{" "}
                                  </a>
                                </td>
                                <td>9,347</td>
                                <td>9,347</td>
                                <td>0</td>
                                <td>9,568</td>
                                <td>221</td>
                                <td>2.36%</td>
                                <td>9,404</td>
                                <td>-168</td>
                                <td>-1.71%</td>
                                <td>10,196</td>
                                <td>792</td>
                                <td>8.42%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            {/*end::Body*/}
          </div>
        </div>
      </div>
    </div>
  );
}
