import { Modal } from "antd";
import React, { useState } from "react";
import LinkItems2 from "../../items-link-items1";

const LinkComponents = ({
  onClose,
  show,
  filter,
  setFilter,
  getList,
  userData,
}) => {
  return (
    <Modal
      title="Link Components"
      visible={show}
      onCancel={onClose}
      width={"80%"}
      footer={null}
    >
      {show && (
        <LinkItems2
          showView={show ? "TRUE" : "FALSE"}
          userData={userData}
          setFilter={setFilter}
          filter={filter}
          onClose={onClose}
          getList={getList}
        />
      )}
    </Modal>
  );
};

export default LinkComponents;
