// reducers.js

import { combineReducers } from "redux";

// Example reducer
const userReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  // add other reducers here
});

export default rootReducer;
