// SimpleForm.js
import React from "react";
import { Form, Input, InputNumber, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

const BulkModalAction = ({
  form,
  show,
  selectedBulkData,
  onSubmit,
  onClose,
}) => {
  const { label, key, componentType, options } = selectedBulkData;
  return (
    <Modal
      title={label}
      visible={show}
      onOk={() => onSubmit(selectedBulkData)}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" name="BulkModalAction">
        <Form.Item
          name={key}
          label={label}
          rules={[{ required: true, message: `${label} is required` }]}
        >
          {componentType === "number" ? (
            <InputNumber style={{ width: "100%" }} placeholder={label} />
          ) : componentType === "textarea" ? (
            <TextArea placeholder={label} />
          ) : componentType === "select" ? (
            <Select placeholder={label} options={options} allowClear />
          ) : (
            <Input placeholder="Enter a value" />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BulkModalAction;
