import React from "react";
import {
  Form,
  Flex,
  Card,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Space,
  Row,
  Col,
  Table,
  Typography,
  Button,
  Tooltip,
} from "antd";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const { Option } = Select;

const CreatePo = () => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
    {
      title: "ASIN Title",
      dataIndex: "asinTitle",
      key: "asinTitle",
      render: (text) => <Text ellipsis>{text}</Text>, // Ellipsis for long text
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => (
        <a
          href={`https://www.amazon.com/dp/${text}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: "MSKU",
      dataIndex: "msku",
      key: "msku",
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      key: "moq",
      align: "center",
    },
    {
      title: "Replenishment Qty",
      dataIndex: "replenishmentQty",
      key: "replenishmentQty",
      render: (value) => (
        <Space>
          <Tooltip title="Replenishment Warning">
            <ExclamationCircleOutlined style={{ color: "#faad14" }} />
          </Tooltip>
          <InputNumber min={1} defaultValue={value} style={{ width: "60px" }} />
        </Space>
      ),
    },
    {
      title: "ASIN Cost",
      dataIndex: "asinCost",
      key: "asinCost",
      render: (text) => <Text>${text}</Text>,
    },
    {
      title: "Purchase Cost",
      dataIndex: "purchaseCost",
      key: "purchaseCost",
      render: (text) => <Text>${text}</Text>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="text"
          danger
          icon={<DeleteOutlined />}
          onClick={() => console.log(`Delete item with ID: ${record.key}`)}
        />
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      index: 1,
      asinTitle:
        "Primal Freeze Dried Raw Dog Food Nuggets, Pork, Complete & Balanced...",
      asin: "B010I3K206",
      msku: "PRIMAL2-DGPORK14-B010I3K206",
      fnsku: "X0032XFFO9",
      moq: 3,
      replenishmentQty: 3,
      asinCost: "17.84",
      purchaseCost: "53.52",
    },
  ];

  const componentColumns = [
    {
      title: "COMPONENT ID",
      dataIndex: "componentId",
      key: "componentId",
    },
    {
      title: "PO DESCRIPTION",
      dataIndex: "poDescription",
      key: "poDescription",
      render: (text) => <Text ellipsis>{text}</Text>, // Truncate long text
    },
    {
      title: "ORDER QUANTITY",
      dataIndex: "orderQuantity",
      key: "orderQuantity",
      render: (_, record) => (
        <Input
          type="number"
          defaultValue={record.orderQuantity}
          style={{ width: "60px" }}
        />
      ),
    },
    {
      title: "CASE ORDER QUANTITY",
      dataIndex: "caseOrderQuantity",
      key: "caseOrderQuantity",
      align: "center",
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: "COMPONENT COST",
      dataIndex: "componentCost",
      key: "componentCost",
      render: (text) => <Text>${text}</Text>,
    },
    {
      title: "DISCOUNT",
      dataIndex: "discount",
      key: "discount",
      render: (_, record) => (
        <Input placeholder="Enter" style={{ width: "80px" }} />
      ),
    },
    {
      title: "PURCHASE COST",
      dataIndex: "purchaseCost",
      key: "purchaseCost",
      render: (_, record) => (
        <Space>
          <Text>${record.purchaseCost}</Text>
          <Tooltip title="Apply Filter">
            <Button
              type="text"
              icon={<FilterOutlined />}
              style={{ color: "#fa8c16" }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "VENDOR SKU",
      dataIndex: "vendorSku",
      key: "vendorSku",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "CASE QUANTITY",
      dataIndex: "caseQuantity",
      key: "caseQuantity",
      align: "center",
    },
  ];
  const componentDataSource = [
    {
      key: "1",
      componentId: "9WcVg5",
      poDescription: "Primal Dog Freeze-Dried Pork Nuggets 14 oz",
      orderQuantity: 0,
      caseOrderQuantity: 0,
      quantity: 1,
      componentCost: "17.84",
      discount: "",
      purchaseCost: "0.00",
      vendorSku: "CPKF14-FD/4",
      upc: "895135000595",
      caseQuantity: 4,
    },
  ];

  return (
    <div>
      <Row justify={"space-between"} className="mb-5">
        <Col flex="none">
          <Flex gap={7}>
            <Button color="primary" variant="solid">
              Edit PO
            </Button>
            <Button variant="solid" color="default">
              Clone PO
            </Button>
            <Button variant="solid" color="danger">
              Delete
            </Button>
            <Button color="primary" variant="link">
              PO List
            </Button>
          </Flex>
        </Col>
        <Col flex="none">
          <Flex gap={7}>
            <Button variant="filled" color="default">
              Create Prep Log
            </Button>
            <Button variant="solid" color="primary">
              Download To
            </Button>
          </Flex>
        </Col>
      </Row>
      <Card
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
        bodyStyle={{
          padding: "15px 15px 0px 15px",
        }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col flex="auto">
              <Form.Item label="Vendor" name="vendor">
                <Select>
                  <Option value="test vendpr">test vendpr</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                label="Estimated Inbound Delivery Date"
                name="estimatedInboundDeliveryDate"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Submission Date" name="submissionDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                label="Estimated On-Shelf Date"
                name="estimatedOnShelfDate"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Status" name="status">
                <Select>
                  <Option value="Open">Open</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Reference" name="reference">
                <Input />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="PO Template" name="poTemplate">
                <Select>
                  <Option value="PO Template">PO Template</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col flex="auto">
              <Form.Item label="Total Purchase Cost" name="totalPurchaseCost">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Notes" name="notes">
                <Input.TextArea rows={1} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Shipping Fee" name="shippingFee">
                <Input size="middle" />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                label="Actual Inbound Delivery Date"
                name="actualInboundDeliveryDate"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Actual On Shelf Date" name="actualOnShelfDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Discount Type" name="discountType">
                <Select>
                  <Option value="Price">Price</Option>
                  <Option value="Percentage">Percentage</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.discountType !== currentValues.discountType
                }
              >
                {({ getFieldValue }) => {
                  const discountType = getFieldValue("discountType");
                  return (
                    <Form.Item
                      label="Total Discount"
                      name="totalDiscount"
                      rules={[
                        {
                          required: true,
                          message: "Total Discount is required!",
                        },
                        ...(discountType === "Percentage"
                          ? [
                              {
                                pattern:
                                  /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/,
                                message: "Enter a valid percentage (0-100)!",
                              },
                            ]
                          : [
                              {
                                pattern: /^\d+(\.\d{1,2})?$/,
                                message: "Enter a valid price!",
                              },
                            ]),
                      ]}
                    >
                      <Input
                        addonAfter={discountType === "Percentage" ? "%" : "₹"}
                        placeholder={`Enter ${
                          discountType === "Percentage" ? "percentage" : "price"
                        } value`}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        className="mt-5"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
        bodyStyle={{
          padding: "15px 15px 0px 15px",
        }}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: "max-content" }}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                columns={componentColumns}
                dataSource={componentDataSource}
                pagination={false}
                bordered
                rowClassName="custom-row"
                style={{
                  margin: "20px",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              />
            ),
            rowExpandable: (record) => record.description !== "",
          }}
        />
        <Row gutter={16} className="p-7">
          <Col flex="auto">
            <div>
              <b>Total Units:</b> 0
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <b>ASIN Count:</b> 1
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <b>Ordered ASIN Unit(s):</b> 3
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <b>Total Cases:</b> 0
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <b>Final Purchase Cost:</b> $0
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <b>Shipping Fee:</b> $0.00
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <b>Total Discount:</b> $0
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CreatePo;
