import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Checkbox,
  Table,
} from "antd";
import { MakeApiCall } from "../../../../../apis";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FormatNumber } from "../../../../../config";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const GetUnitCostHistory = ({
  setIsModalVisible,
  isModalVisible,
  form,
  vendorList,
  vendorLoading,
  userData,
  selectedRow,
  historyType,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [componentList, setComponentList] = useState([]);
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const GetUnitCostHistoryList = async (id) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `get-unit-cost-history-list/${id}`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setComponentList(response?.data || []);
        setSubmitLoading(false);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const UnitCostHistoryList = async (id, historyId) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `unit-cost-history-list/${id}`,
        "post",
        {
          history: historyId,
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setComponentList((prev) =>
          prev?.map((d) => {
            if (d?.id === historyId) {
              return {
                ...d,
                default: true,
              };
            } else {
              return { ...d, default: false };
            }
          })
        );
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const UnitCostHistoryDelete = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `delete-unit-cost-history/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.destroy();
        GetUnitCostHistoryList(selectedRow?.id);
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };
  const SaveUnitCostHistoryList = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `save-unit-cost-history-list`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.destroy();
        handleCancel();
        // GetUnitCostHistoryList(selectedRow?.id);
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleSubmit = async () => {
    const convertData = componentList?.reduce((acc, item) => {
      // Create a new object to store the main item data
      const processedItem = { ...item };

      // Find and extract keys ending with '_new'
      const newKeys = {};
      Object.keys(item).forEach((key) => {
        if (key.endsWith("_new")) {
          newKeys[key?.replace("_new", "")] = item[key];
          // Remove the '_new' key from the main object
          delete processedItem[key];
        }
      });

      // If there are any '_new' keys, add them as a nested 'new' property
      if (Object.keys(newKeys).length > 0) {
        processedItem.new = newKeys;
      }

      // Use the 'id' as the key for the new object
      acc[item.id] = processedItem?.new;
      return acc;
    }, {});
    const obj = {
      history: convertData,
    };
    SaveUnitCostHistoryList(obj);
  };

  useEffect(() => {
    if (selectedRow?.id) {
      GetUnitCostHistoryList(selectedRow?.id);
    }
  }, [selectedRow?.id]);

  // Define table columns
  const columns = [
    {
      title: "", // Empty title for the checkbox
      dataIndex: "default",
      key: "default",
      render: (_, record) => (
        <Checkbox
          onChange={(e) => {
            UnitCostHistoryList(selectedRow?.id, record?.id);
          }}
          checked={_}
        />
      ),
    },
    {
      title: "History ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (e, record) => {
        return historyType === "view" ? (
          e
        ) : (
          <Input
            onChange={(e) => {
              setComponentList((prev) => {
                return prev?.map((d) => {
                  if (d?.id === record?.id) {
                    return {
                      ...d,
                      unit_cost_new: e.target.value,
                      unit_cost: e.target.value,
                    };
                  } else {
                    return { ...d };
                  }
                });
              });
            }}
            value={e}
          />
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor_id",
      key: "vendor_id",
      render: (e, row) => {
        return historyType === "view" ? (
          row?.vendors?.v_name
        ) : (
          <Select
            placeholder="Select a vendor"
            loading={vendorLoading}
            value={e}
            onChange={(e) => {
              setComponentList((prev) => {
                return prev?.map((d) => {
                  if (d?.id === row?.id) {
                    return {
                      ...d,
                      vendor_id_new: e,
                      vendor_id: e,
                    };
                  } else {
                    return { ...d };
                  }
                });
              });
            }}
            className="w-150px"
            options={vendorList?.map((d) => ({
              label: d?.name,
              value: d?.id,
            }))}
          />
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "history_note",
      key: "history_note",
      render: (e, row) => {
        return historyType === "view" ? (
          e
        ) : (
          <TextArea
            onChange={(e) => {
              setComponentList((prev) => {
                return prev?.map((d) => {
                  if (d?.id === row?.id) {
                    return {
                      ...d,
                      history_note_new: e.target.value,
                      history_note: e.target.value,
                    };
                  } else {
                    return { ...d };
                  }
                });
              });
            }}
            value={e}
          />
        );
      },
    },
    {
      title: "Date Active",
      dataIndex: "date_active",
      key: "date_active",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to permanently delete this History?"
          onConfirm={() => handleDelete(record)}
          okText="Yes"
          placement="left"
          cancelText="No"
        >
          <Button
            color="danger"
            variant="filled"
            icon={<Icon width={22} icon="ic:round-delete" />}
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  // Handle delete button click
  const handleDelete = (key) => {
    UnitCostHistoryDelete(key?.id);
  };

  return (
    <>
      <Modal
        title="Unit Cost History"
        width={980}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={
          historyType === "view"
            ? []
            : [
                <Button type="dashed">Close</Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                  Save
                </Button>,
              ]
        }
      >
        <Table
          dataSource={componentList}
          columns={columns}
          pagination={false}
          scroll={{ x: "max-content" }}
          loading={submitLoading}
          rowKey="key"
        />
      </Modal>
    </>
  );
};

export default GetUnitCostHistory;
