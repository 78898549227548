import { Button, Empty, Typography } from "antd";
import React from "react";

const MarketplaceNoData = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "calc(100vh - 235px)" }}
    >
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 100,
        }}
        description={
          <Typography.Text style={{ wordBreak: "break-all" }}>
            <a href="#API">
              To continue using the application,
              <br /> please add the Marketplace credentials
            </a>
          </Typography.Text>
        }
      >
        <Button color="danger" href="/callback_spapi" variant="filled">
          Connect to Marketplace
        </Button>
      </Empty>
    </div>
  );
};

export default MarketplaceNoData;
