import React from "react";
import Table2 from "../../../../Components/tables/table2";
import { Space } from "antd";

const Brand = () => {
  return (
    <div>
      <Table2
        columns={[
          {
            title: "Brand Name",
            dataIndex: "brandName",
            key: "brandName",
          },
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Contact",
            dataIndex: "contact",
            key: "contact",
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (d) => {
              return (
                <div className="d-flex flex-shrink-0 justify-content-center">
                  <a className="btn btn-icon bg-light btn-active-color-primary btn-sm me-2">
                    <i className="ki-outline ki-pencil fs-2 text-gray-800" />
                  </a>
                  <a className="btn btn-icon bg-light btn-active-color-primary btn-sm">
                    <i className="ki-outline ki-trash fs-2 text-primary" />
                  </a>
                </div>
              );
            },
          },
        ]}
        dataSource={[
          {
            key: "1",
            brandName: "John Brown",
            date: "32",
            contact: "New York No. 1 Lake Park",
          },
          {
            key: "2",
            brandName: "Jim Green",
            date: "42",
            contact: "London No. 1 Lake Park",
          },
          {
            key: "3",
            brandName: "Joe Black",
            date: "32",
            contact: "Sydney No. 1 Lake Park",
          },
        ]}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default Brand;
