import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-table-expanded-row-fixed {
    background: #0179af38;
    padding: 11px !important;
  }
`;
export const CompoenntWrapper = styled.div``;
export const AdvancedFilterWrapper = styled.div`
  .ant-list-item {
    border-radius: 15px;
    border: none !important;
    padding-left: 15px;
    &:hover {
      transition: 0.6s;
      background: #0179af42;
    }
  }
  .active-card-item {
    background: #0179af21;
    border: 1px solid #0179af !important;
  }
`;
