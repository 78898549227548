import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Inter, Helvetica, sans-serif;
    background-color: #0079af;
  }

  .text-truncate_ {
  overflow: hidden;
      height: max-content;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 320px;
}
  .app-wrapper {
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  #root {
    height: 100%;
  }
  a {
    text-decoration: none;
  }
  .apexcharts-xaxistooltip.apexcharts-theme-light {
    background: #fff !important;
  }
  .ant-select-selection-placeholder,
  .ant-picker-input > input {
    font-size: 0.95rem !important;
    color: #31343c !important;
  }
  .ant-picker-input > input::placeholder {
    font-size: 0.95rem !important;
    color: #99a1b7 !important;
  }
  .bdr-left-user {
    position: relative;
  }
  .bdr-left-user::before {
    content: "";
    position: absolute;
    left: 0;
    top: 27%;
    width: 2px;
    height: 30px;
    background-color: var(--bs-primary) !important;
    z-index: 9;
  }
  .main-box .table:not(.table-bordered).table-row-gray-300 tr {
    border-bottom-color: var(--bs-gray-100);
    border-bottom: 10px solid #fff;
    background-color: var(--bs-gray-100);
  }
  .ant-breadcrumb-link {
    color: #4b5675 !important;

    font-weight: 600 !important;
  }

  .fade-in-element {
    position: relative;
    opacity: 0; /* Start with the element hidden */
    animation: fadeInTopToBottom 1s ease-out forwards; /* Apply the animation */
  }

  /* Define the keyframes for the animation */
  @keyframes fadeInTopToBottom {
    0% {
      top: -50px; /* Start above the initial position */
      opacity: 0; /* Fully transparent */
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  
  .fs-6 {
    font-size: 1.075rem !important;
  }
  .fw-bold {
    font-weight: 600 !important;
  }

  html::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #00000000;
    border-radius: 10px;
  }

  html::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  html::-webkit-scrollbar-thumb {
    background-color: #015276;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(
      90deg,
      #016d9d 25%,
      transparent 25%,
      transparent 50%,
      #016d9d 50%,
      #016d9d 75%,
      transparent 75%,
      transparent
    );
  }
  .shimmer {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
      100%;

    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }

  .flipper {
    animation: flip 1.4s forwards;
    transform-style: preserve-3d;
    position: relative;
  }

  @keyframes flip {
    0% {
      transform: rotateY(0deg) scale(1) translateX(100px);
      opacity: 0;
    }

    50% {
      transform: rotateY(180deg) scale(1.5) translateX(-30px);
      opacity: 0.75;
    }

    100% {
      transform: rotateY(360deg) scale(1) translateX(0px);
      opacity: 1;
    }
  }

  .slide-logo {
    width: 0;
    overflow: hidden;
    white-space: nowrap; /* Prevents line breaks within the content */
    animation: slide 1.4s forwards;
    animation-delay: 0.6s; /* Delay the animation by 1.5 seconds */
  }

  @keyframes slide {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .anticon-ellipsis {
    color: #fff;
  }
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: none !important;
  }

  /* App.css */

  .ant-tooltip,
  .ant-tooltip-inner {
    background-color: #fff !important;
    color: #333 !important;
    border-color: #ddd !important;
  }
  .ant-tooltip .ant-tooltip-arrow:before {
    background: rgb(255 255 255 / 85%) !important;
  }

  .ant-tooltip-arrow {
    border-top-color: #ddd !important;
  }

  .ant-tooltip-inner {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15 !important);
  }
  .antd_selected_presets_date {
    color: #fff;
    background: #0079af;
    &:hover {
      font-weight: 600;
      color: #0079af;
    }
  }

  .custom-summary-row {
    background-color: #f9f9f9 !important;
    td {
      font-weight: 600;
    }
    .ant-table-cell-fix-left {
      background: #f9f9f9 !important;
    }
  }
  .ant-select-selection-item > div {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none !important;
    padding-bottom: 0px !important;
    word-break: revert !important;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
 .ant-table-row-level-1 {
      background: #f1f8fa !important;
      .ant-table-cell-fix-left {
        background: #f1f8fa !important;
      }
 }

 .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #0079af59 !important;
 }
  .dashboard-tooltip-custom-view {
      border: 1px dashed #0479af99;
      max-height: 500px;
    overflow: auto;
    background: white;
    border-radius: 14px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .header-view {
          display: grid;
              background: #0479af1a;
    justify-items: center;

    }
    .graph-tooltip-view {
          background: #f7f7f7;
    // height: 170px;
    margin: 0px 10px;
    // padding: 10px;
    border-radius: 10px;
    }
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
gap: 9px 0px;   
}

.col {
  flex: 1 1 calc(25% - 15px); /* Fit 4 boxes per row with gap */
  box-sizing: border-box;
}

.box {
  background: #ffffff; /* Neutral background */
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  color: #333;
  border: 1px solid #333; /* Default border color */
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  animation: fadeInUp 0.6s ease forwards;
  opacity: 0; /* Ensure elements are hidden before animation */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box .icon {
  font-size: 24px;
  color: #ff6347; /* Colorful icon */
  margin-bottom: 10px;
}

.box:hover {
  transform: translateY(-8px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.label {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.value {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Darker color for better contrast */
}


  }


 #root {
      overflow: auto;
 }
      .max-2 {
  display: -webkit-box;        
  -webkit-box-orient: vertical;
  overflow: hidden;       
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  width: 200px;         
}
  .ant-table-wrapper .ant-table-filter-trigger {
    color: rgb(255 255 255 / 62%) !important;
  }
  
`;

export default GlobalStyles;
