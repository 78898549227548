import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  // List,
  Menu,
  message,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Wrapper } from "./style";

import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import RangePickerCF from "../../../Components/datepicker";
import MarketplaceSelect from "../../../Components/marketplace";
import {
  ConvertParams,
  PaginationConfig,
  PropsFilter,
  RenderTable,
  URL,
} from "../../../config";
import { CreateOrderStatusList } from "../../../config/static";
import { ReplenishmentValues } from "./all-fun-logic";
import OnOrderModal from "./lib/on-order";
import UpdateProfile from "./lib/Replenishment-edit";
import ReplenishmentModal from "./lib/Replenishment-view";
import SellsOutInViewModal from "./lib/sells-OutIn";
import Table2 from "../../../Components/tables/table2";
import InTransitInventoryModal from "./lib/in-transit-inventory";
import UnitHistory from "../item-master/lib/unit-history";
import { TableLoading } from "../../../Components/table-animation";
import ASINNote from "../item-master/lib/asin-note";
import EditModal from "./lib/edit-modal";
import ASINNoteItem from "../item-master/lib/asin-note-multi";
import AdvancedFilter from "../item-master/lib/advanced-filter";
import VendorDropdownList from "./lib/vendor-list";

const Table1 = lazy(() => import("../../../Components/tables/table1"));

const CreateOrder = ({ userData }) => {
  const navigate = useNavigate();
  const [Filterform] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [replenishmentBE, setReplenishmentBE] = useState({
    show: false,
    replenishment_data: {},
  });
  const [replenishmentModal, setReplenishmentModal] = useState({
    value: "",
    visible: false,
  });
  const [onOrderModal, setOnOrderModal] = useState({
    value: "",
    visible: false,
  });
  const [inTransitInventory, setInTransitInventory] = useState({
    value: "",
    visible: false,
  });
  const [replenishmentLogicModalSells, setReplenishmentLogicModalSells] =
    useState({
      value: "",
      visible: false,
    });
  const [asinNote, setAsinNote] = useState({
    show: false,
  });
  const [unitCostHistory, setUnitCostHistory] = useState({
    show: false,
  });
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState({
    marketplace_id: null,
    to_date: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    from_date: dayjs().subtract(60, "d").format("YYYY-MM-DD"),
    action: "all_data",
    filter_value: null,
    page: 1,
    "page-size": 20,
    totalPage: 0,
    page_count: 0,
  });
  console.log(filters?.action, "filters");

  const [itemNoteModal, setItemNoteModal] = useState({
    show: false,
    data: {},
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [advanceFilterList, setAdvanceFilterList] = useState([]);
  const [advanceFilterLoading, setAdvanceFilterLoading] = useState(true);
  const [selectedAdvanceFilter, setSelectedAdvanceFilter] = useState(null);
  const [moreFilterView, setMoreFilterView] = useState(false);
  const [linkList, setLinkList] = useState(null);
  const [vendorModal, setVendorModal] = useState(false);

  const handleBulkAction = async (api, data) => {
    message.destroy();
    message.loading("Please wait...", 0);
    try {
      const response = await MakeApiCall(
        api,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleCancel = () => {
    setUnitCostHistory({
      show: false,
    });
    setAsinNote({
      show: false,
    });
    setItemNoteModal({
      show: false,
      data: {},
    });
    setSelectedRows([]);
  };

  const GetBrandListAction = async () => {
    setBrandLoading(true);
    setBrandList([]);
    try {
      const response = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setBrandList(response?.data?.records || []);

        setBrandLoading(false);
      } else {
        setBrandList([]);
        message.destroy();
        setBrandLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setBrandList([]);
      setBrandLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetVendorListAction = async () => {
    setVendorLoading(true);
    setVendorList([]);
    try {
      const response = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setVendorList(response?.data?.records || []);
        setVendorLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setVendorLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorLoading(false);
      setVendorList([]);
      message.destroy();
      message.error(error?.message);
    }
  };

  const UnitCostHistoryAction = async (id) => {
    try {
      const response = await MakeApiCall(
        `item-unit-cost-history/${id}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        if (response?.data?.length > 0) {
          setUnitCostHistory({
            show: true,
            data: response?.data || [],
          });
        } else {
          message.destroy();
          message.success(response?.message);
        }
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleDeleteUnitCost = async (data) => {
    try {
      const response = await MakeApiCall(
        `delete-unit-cost-history/${data?.id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setUnitCostHistory({
          show: true,
          data: unitCostHistory?.data.filter((item) => item?.id !== data?.id),
        });
        if (response?.data?.length > 0) {
        } else {
          message.destroy();
          message.success(response?.message);
        }
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    GetBrandListAction();
    GetVendorListAction();
    return () => {};
  }, []);

  // Table columns configuration
  const columnsUnitCost = [
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "History ID",
      dataIndex: "unique_id",
      key: "unique_id",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (text) => `$${parseFloat(text || 0)?.toFixed(2)}`,
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Date Active",
      dataIndex: "date_active",
      key: "date_active",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to delete this unit cost?"
          onConfirm={() => handleDeleteUnitCost(record)}
          okText="Yes"
          placement="left"
          cancelText="No"
        >
          <Button
            color="danger"
            variant="filled"
            icon={<Icon width={22} icon="ic:round-delete" />}
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  const columns = [
    {
      title: "Detail",
      width: 200,
      fixed: "left",
      align: "center",
      render: (row) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Tooltip
              placement="top"
              title={row.linkStatus === false ? "unlinked" : "linked"}
            >
              <a
                href={
                  row.linkStatus === false
                    ? "#"
                    : `/link-items?searchKeyword=${row.asin1}`
                }
                id={row.linkStatus === false ? "unlinked" : "linked"}
              >
                <Icon
                  icon={
                    row.linkStatus !== undefined && row.linkStatus === true
                      ? "bx:link"
                      : "tabler:unlink"
                  }
                  width={26}
                  style={{
                    color:
                      row.linkStatus !== undefined && row.linkStatus === true
                        ? "green"
                        : "red",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(
                      row.linkStatus !== undefined && row.linkStatus === true
                        ? "#"
                        : `/link-items?searchKeyword=${row.asin1}`
                    )
                  }
                />
              </a>
            </Tooltip>
            <Icon
              icon="ri:amazon-fill"
              width={26}
              onClick={() =>
                window.open(`https://www.amazon.com/dp/${row.asin1}`)
              }
              style={{ color: "orange", cursor: "pointer" }}
            />
            <Icon
              onClick={() =>
                window.open(
                  `https://sellercentral.amazon.com/skucentral?mSku=${row.seller_sku}&ref=myi_skuc`
                )
              }
              style={{
                cursor: "pointer",
              }}
              icon="solar:tag-bold-duotone"
              width={26}
            />
            <Icon
              onClick={() => {
                setSelectedRow(row);
                setEditModal(true);
              }}
              style={{ cursor: "pointer" }}
              // onClick={() => {
              //   setSelectId(row.fald_id);
              //   UpdateFBA(row.fald_id);
              //   if (brandList.length === 0) {
              //     getList("brands?page=all");
              //   }
              //   if (supplyList.length === 0) {
              //     getList("supplys?page=all");
              //   }
              //   if (vendorList.length === 0) {
              //     getList("vendors?page=all");
              //   }
              // }}
              icon="flowbite:edit-solid"
              width={26}
            />
          </div>
        );
      },
    },
    {
      title: "Item Description",
      width: 473,
      fixed: "left",
      align: "left",
      dataIndex: "item_name",
      sortOn: true,

      render: (_, row) => {
        return (
          <div
            className="d-flex box-view-columns"
            style={{
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #0179b0a8",
              background: "#ecf1f4",
            }}
          >
            <Image
              loading="lazy"
              alt="Image"
              width={70}
              height={70}
              style={{
                objectFit: "contain",
                width: "70px",
              }}
              src={
                row.image_url ||
                "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png"
              }
            />
            <div className="d-grid">
              <div
                style={{ marginLeft: "10px" }}
                className="text-truncate_"
                title={row.item_name}
              >
                {row.item_name}
              </div>

              <div className="d-flex mt-2 ms-2">
                <Tag bordered={false} color="blue">
                  ASIN: {row?.asin1}
                </Tag>
                <Tag bordered={false} color="purple">
                  FNSKU: {row?.fnsku}
                </Tag>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Replenishment",
      width: 185,
      align: "center",
      dataIndex: "replenishment_QTYNew",
      sortOn: true,
      fixed: "left",
      render: (_, row) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              size="small"
              className="me-1 px-2"
              style={{
                background: row.replenishment_QTY_Color,
              }}
              type="primary"
              onClick={() => {
                setReplenishmentBE({
                  show: true,

                  replenishment_data: row?.replenishment_data,
                });
              }}
            >
              {row.replenishment_QTYNew || 0}
            </Button>
            <Button
              size="small"
              color="primary"
              className="mr-1"
              onClick={() =>
                setReplenishmentModal({
                  value: {
                    ...row,
                    replenishment_QTY: row.replenishment_QTYNew || 0,
                  },
                  visible: true,
                })
              }
            >
              <Icon icon="lucide:edit" />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Product Short Description",
      width: 240,
      align: "center",
      dataIndex: "product_short_description",
      sortOn: true,

      render: (e, row) => {
        return (
          <div>
            <Input
              value={
                row?.product_short_description_edit
                  ? row?.product_short_description_value
                  : row?.product_short_description
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        product_short_description_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={
                row?.product_short_description_edit ? "outlined" : "filled"
              }
              suffix={
                <div className="d-flex align-items-center">
                  {row?.product_short_description_edit ? (
                    <>
                      {row?.product_short_description_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={async (e) => {
                            e.stopPropagation();

                            const obj = {
                              product_short_description:
                                row?.product_short_description_value,
                              fald_id: row?.fald_id,
                            };

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    product_short_description_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                            await handleBulkAction("pro-desc-save", obj);
                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    product_short_description:
                                      row?.product_short_description_value,
                                    product_short_description_edit: false,
                                    product_short_description_loading: false,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.fald_id === row?.fald_id) {
                                delete d?.product_short_description_value;
                                return {
                                  ...d,
                                  product_short_description_edit: false,
                                  product_short_description_loading: false,
                                  product_short_description_value:
                                    row?.product_short_description,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.fald_id === row?.fald_id) {
                              return {
                                ...d,
                                product_short_description_edit: true,
                                product_short_description_loading: false,
                                product_short_description_value:
                                  row?.product_short_description || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      style={{ cursor: "pointer" }}
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: "In-Transit Inventory",
      width: 180,
      align: "center",

      dataIndex: "in_transit",
      sortOn: true,
      render: (_, row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          <Button
            size="small"
            className="mr-1"
            variant="filled"
            color="primary"
            onClick={() => {
              setInTransitInventory({ value: row, visible: true });
            }}
          >
            {Math.ceil(row.in_transit)}
          </Button>
        </div>
      ),
    },
    {
      title: "On Order",
      width: 120,
      align: "center",
      dataIndex: "on_order_",
      sortOn: true,

      render: (_, row) => {
        const onOrder = (row) => {
          return parseInt(row?.on_order_);
        };
        return (
          <Button
            size="small"
            className="mr-1"
            color="primary"
            onClick={() => setOnOrderModal({ value: row, visible: true })}
          >
            {onOrder(row) || 0}
          </Button>
        );
      },
    },

    {
      title: "Sells Out In",
      width: 180,
      align: "center",
      dataIndex: "sells_out_in_",
      sortOn: true,

      render: (_, row) => {
        const length = row?.sells_out_in_?.length;
        return (
          <div style={{ textAlign: "center" }} className="table-row">
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setReplenishmentLogicModalSells({
                  visible: true,
                  value: allData.replenishment[row.fald_id],
                });
              }}
            >
              {parseFloat(
                row?.sells_out_in_?.[length - 1] || row?.sells_out_in_
              ).toFixed(0) || 0.0}
            </Button>
          </div>
        );
      },
    },
    {
      title: "Sells Out",
      width: 120,
      align: "center",
      dataIndex: "vendorData",
      sortOn: true,
      render: (_, row) => {
        const length = row?.sells_out_in_?.length;
        const Days = row?.sells_out_in_?.[length - 1] || row?.sells_out_in_;
        const Date =
          row?.sells_out_in_date_?.[length - 1] || row?.sells_out_in_date_;
        const text = <span>Sells Out</span>;
        const content = (
          <div>
            <p>Sells Out</p>
            <p>In {parseFloat(Days).toFixed(2)} Days</p>
            <p>By {Date}</p>
          </div>
        );
        return (
          <div style={{ textAlign: "center" }} className="table-row">
            <Popover
              placement="left"
              title={text}
              content={content}
              trigger="hover"
            >
              <Icon
                icon="material-symbols:info"
                width={20}
                style={{
                  color:
                    parseInt(Days) === 0
                      ? "orange"
                      : parseInt(Days) >
                        parseInt(row?.vendorData?.lead_time || 0)
                      ? "purple"
                      : "red",
                }}
              />
            </Popover>
          </div>
        );
      },
    },

    {
      title: "Sale Velocity/Day",
      width: 180,
      align: "center",
      dataIndex: "salesVelocityPerDay_",
      sortOn: true,

      render: (_, row) => {
        return (
          <div style={{ textAlign: "center" }} className="table-row">
            {row?.salesVelocityPerDay_ || 0}
          </div>
        );
      },
    },
    {
      title: "Sale Velocity/Month",
      width: 180,
      align: "center",
      dataIndex: "salesVelocityPerMonth_",
      sortOn: true,

      render: (_, row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          {row?.salesVelocityPerMonth_ || 0}
        </div>
      ),
    },
    {
      title: "Product Group",
      width: 150,
      align: "center",
      dataIndex: "product_group",
      sortOn: true,

      render: (_, row) => {
        if (row.product_group) {
          return <span className="table-row">{row.product_group}</span>;
        } else {
          return (
            <Input
              placeholder="Enter Product Group"
              onBlur={(e) => {
                if (e.target.value) {
                  row.product_group = e.target.value;
                  // handleBulkAction(
                  //   "pro-group-save",
                  //   e.target.value,
                  //   row.fald_id
                  // );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value != row.map_price) {
                  e.target.blur();
                }
              }}
            />
          );
        }
      },
    },
    {
      title: "Profit Margin",
      width: 150,
      align: "center",
      dataIndex: "profit_margin",
      sortOn: true,

      render: (_, row) => {
        return (
          <>
            <div style={{ textAlign: "center" }} className="table-row">
              {row.profit_margin ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {parseFloat(row.profit_margin || 0).toFixed(2)} %
                </div>
              ) : (
                "0%"
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Profit",
      width: 150,
      align: "center",
      dataIndex: "profit",
      sortOn: true,

      render: (_, row) => {
        const textTool = (
          <div className="popover-inner">
            <h3 className="popover-header">Profit</h3>
            <Divider className="my-2" />
            <div className="popover-body">
              BuyBox Price : <b>${row.buy_box_price || row.price || 0}</b>
              <br />
              Storage Fees : <b>${row.storage_fees || "0"}</b>
              <br />
              Referral Fee :{" "}
              <b>${row.estimated_referral_fee_per_unit || "0"}</b>
              <br />
              Fba Fees : <b>${row.expected_fulfillment_fee_per_unit || "0"}</b>
              <br />
              Asin Cost : <b>${row.asin_cost || "0"}</b>
              <br />
              Profit : <b>${row.profit || "0"}</b>
            </div>
          </div>
        );

        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip placement="left" title={textTool}>
              <Icon icon="material-symbols:info" width={16} color="#717171" />
            </Tooltip>
            &nbsp;&nbsp;
            <span className="table-row">
              {" "}
              {row.profit ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {parseFloat(row.profit || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </div>
              ) : (
                "0"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Units Sold/Day",
      width: 140,
      align: "center",

      render: (_, row) => {
        // const content = (
        //   <List
        //     size="small"
        //     bordered
        //     dataSource={[
        //       {
        //         title: "Yesterday",
        //         value: (row && Math.ceil(row.units_sold_yesterday)) || 0,
        //       },
        //       {
        //         title: "3 days",
        //         value: (row && Math.ceil(row.units_sold_3_days)) || 0,
        //       },
        //       {
        //         title: "7 days",
        //         value: (row && Math.ceil(row.units_sold_7_days)) || 0,
        //       },
        //       {
        //         title: "14 days",
        //         value: (row && Math.ceil(row.units_sold_14_days)) || 0,
        //       },
        //       {
        //         title: "30 days",
        //         value: (row && Math.ceil(row.units_sold_30_days)) || 0,
        //       },
        //       {
        //         title: "60 days",
        //         value: (row && Math.ceil(row.units_sold_60_days)) || 0,
        //       },
        //       {
        //         title: "90 days",
        //         value: (row && Math.ceil(row.units_sold_90_days)) || 0,
        //       },
        //     ]}
        //     renderItem={(item) => (
        //       <List.Item>
        //         <Text type="secondary">{item.title || "-"}:</Text>{" "}
        //         <Tag color="default" bordered={false}>
        //           {item.value || 0}
        //         </Tag>
        //       </List.Item>
        //     )}
        //   />
        // );
        return (
          <>
            <Popover
              placement="right"
              content={<div>content</div>}
              title="Units Sold/Day"
              trigger={"click"}
            >
              <Avatar
                className="ml-2 cursor-pointer"
                // onClick={() =>
                // setUnitDetailModal({ value: row, visible: true })
                // }
                color={"light-primary"}
                icon={<Icon icon="material-symbols:visibility" size={18} />}
              />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Available Inventory",
      width: 180,
      align: "center",
      dataIndex: "manage_afn_fulfillable_quantity_",
      sortOn: true,

      render: (_, row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          {parseInt(row?.reserved_fc_transfers_) +
            parseInt(row?.manage_afn_fulfillable_quantity_) || 0}
        </div>
      ),
    },
    {
      title: "MSKU",
      width: 180,
      align: "center",

      dataIndex: "seller_sku",
      sortOn: true,
      render: (_, row) => <span className="table-row">{row.seller_sku}</span>,
    },
    {
      title: "MOQ",
      width: 100,
      align: "center",
      dataIndex: "moq",
      sortOn: true,

      render: (_, row) => <span className="table-row">{row.moq ?? "0"}</span>,
    },
    {
      title: "Vendor",
      width: 200,
      align: "center",

      dataIndex: "vendorName",
      sortOn: true,
      render: (_, row) => (
        <span className="table-row">{row.vendorName || "N/A"}</span>
      ),
    },
    {
      title: "FBA Fee",
      width: 100,
      align: "center",
      dataIndex: "estimated_fee_total",
      sortOn: true,

      render: (_, row) => {
        return (
          <span className="table-row">
            $
            {row.estimated_fee_total === "" || row.estimated_fee_total === 0
              ? 0
              : Number(row.estimated_fee_total || 0).toLocaleString("en-US")}
          </span>
        );
      },
    },
    {
      title: "Seller Fee",
      width: 140,
      align: "center",
      dataIndex: "estimated_referral_fee_per_unit",
      sortOn: true,

      render: (_, row) => {
        return (
          <span className="table-row">
            $
            {row.estimated_referral_fee_per_unit
              ? parseFloat(row.estimated_referral_fee_per_unit).toLocaleString(
                  "en-US"
                )
              : 0}
          </span>
        );
      },
    },
    {
      title: "Storage Fee",
      width: 150,
      align: "center",
      dataIndex: "storage_fees",
      sortOn: true,

      render: (_, row) => {
        return (
          <span className="table-row">
            $
            {row.storage_fees
              ? Number(row.storage_fees).toLocaleString("en-US")
              : 0}
          </span>
        );
      },
    },
    {
      title: "Total Fee",
      width: 130,
      align: "center",
      dataIndex: "estimated_fee_total",
      sortOn: true,

      render: (_, row) => {
        return (
          <span className="table-row">
            $
            {(
              (parseFloat(row.estimated_fee_total) || 0) +
              (parseFloat(row.estimated_referral_fee_per_unit) || 0) +
              (parseFloat(row.storage_fees) || 0)
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </span>
        );
      },
    },
    {
      title: "Prep Type",
      width: 130,
      align: "center",
      dataIndex: "prep_type",
      sortOn: true,

      render: (_, row) => (
        <Link
          to={row.prep_type ? `/link-items?searchKeyword=${row.asin1}` : "#"}
        >
          {row.prep_type ?? "N/A"}
        </Link>
      ),
    },
    {
      title: "Brand",
      width: 200,
      align: "center",
      dataIndex: "brandName",
      sortOn: true,

      render: (_, row) => (
        <span className="table-row">{row.brandName ?? "N/A"}</span>
      ),
    },
    {
      title: "ASIN Cost",
      width: 130,
      align: "center",
      dataIndex: "asin_cost",
      sortOn: true,

      render: (_, row) => {
        return (
          <span className="table-row">
            {`$${parseFloat(row.asin_cost || 0).toFixed(2)}` || "$0.00"}
          </span>
        );
      },
    },
    {
      title: "Unit Cost History",
      dataIndex: "unit_cost_history_loading",
      sortOn: true,
      align: "center",
      width: 200,
      render: (_, row) => (
        <Button
          type="link"
          size="small"
          loading={row?.unit_cost_history_loading}
          icon={<Icon icon="uim:clock" width={24} />}
          className="ms-3"
          onClick={async () => {
            setList((prev) => {
              return prev?.map((d) => {
                if (d?.fald_id === row?.fald_id) {
                  return {
                    ...d,
                    unit_cost_history_loading: true,
                  };
                }
                return { ...d };
              });
            });
            try {
              await UnitCostHistoryAction(row?.fald_id);
            } finally {
              setList((prev) => {
                return prev?.map((d) => {
                  if (d?.fald_id === row?.fald_id) {
                    return {
                      ...d,
                      unit_cost_history_loading: false,
                    };
                  }
                  return { ...d };
                });
              });
            }
          }}
        ></Button>
      ),
    },
    {
      title: "ASIN Note",
      dataIndex: "item_note",
      sortOn: true,
      align: "center",
      width: 130,
      render: (_, row) => (
        <Button
          onClick={() => {
            setAsinNote({
              show: true,
              data: row,
            });
          }}
          color="primary"
          variant="filled"
          size="small"
        >
          <Icon icon={row?.item_note ? "mdi:eye" : "si:add-fill"} width={18} />
        </Button>
      ),
    },
    {
      title: "Inventory Restriction",
      width: 200,
      align: "center",
      sorter: (a, b) =>
        a?.maximum_shipment_quantity_?.replace("\r", "")
          ? a?.maximum_shipment_quantity_?.replace("\r", "")
          : 0 - b?.maximum_shipment_quantity_?.replace("\r", "")
          ? b?.maximum_shipment_quantity_?.replace("\r", "")
          : 0,
      render: (row) => (
        <span className="table-row">
          {row?.maximum_shipment_quantity_?.replace("\r", "") || 0}
        </span>
      ),
    },
    {
      title: "Open Date",
      width: 220,
      align: "center",
      // sorter: (a, b) =>
      //   formateDate(a.open_date).localeCompare(formateDate(b.open_date)),
      render: (row) => <span className="table-row">{row.open_date}</span>,
    },
    {
      title: "Attribute1",
      width: 220,
      align: "center",
      sorter: (a, b) =>
        (a.attribute1 || "N/A").localeCompare(b.attribute1 || "N/A"),
      render: (row) => (
        <span className="table-row">{row.attribute1 || "N/A"}</span>
      ),
    },
    {
      title: "Attribute2",
      width: 220,
      align: "center",
      sorter: (a, b) =>
        (a.attribute2 || "N/A").localeCompare(b.attribute2 || "N/A"),
      render: (row) => (
        <span className="table-row">{row.attribute2 || "N/A"}</span>
      ),
    },
    {
      title: "Attribute3",
      width: 220,
      align: "center",
      sorter: (a, b) =>
        (a.attribute3 || "N/A").localeCompare(b.attribute3 || "N/A"),
      render: (row) => (
        <span className="table-row">{row.attribute3 || "N/A"}</span>
      ),
    },

    {
      title: "Total Inventory",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        parseInt(a?.manage_afn_total_quantity_) -
        parseInt(b?.manage_afn_total_quantity_),
      render: (row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          {row?.manage_afn_total_quantity_ || 0}
        </div>
      ),
    },
    {
      title: "Inbound QTY",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        parseInt(a?.manage_afn_inbound_shipped_quantity_) -
        parseInt(b?.manage_afn_inbound_shipped_quantity_),
      render: (row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          {row?.manage_afn_inbound_shipped_quantity_ || 0}
        </div>
      ),
    },
    {
      title: "Reserved Inventory",
      width: 180,
      align: "center",
      sorter: (a, b) => parseInt(a?.reserved_qty_) - parseInt(b?.reserved_qty_),
      render: (row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          {row?.reserved_qty_ || 0}
        </div>
      ),
    },

    {
      title: "Days in stock",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        parseInt(a?.sales_for_forcast_ || 0) -
        parseInt(b?.sales_for_forcast_ || 0),
      render: (row) => {
        return (
          <div style={{ textAlign: "center" }} className="table-row">
            {row?.sales_for_forcast_ || 0}
          </div>
        );
      },
    },
    {
      title: "Fulfillment Type",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        parseInt(a.get_fullfillment_type) - parseInt(b.get_fullfillment_type),
      render: (row) => (
        <div style={{ textAlign: "center" }} className="table-row">
          {row.get_fullfillment_type || 0}
        </div>
      ),
    },
  ];

  const CreatePurchaseOrderDemo = async (data) => {
    try {
      setLoading(true);
      setAdvanceFilterLoading(true);
      setList([]);
      const response = await MakeApiCall(
        `create-purchase-order-demo${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setLoading(false);
        setAdvanceFilterLoading(false);
        setAdvanceFilterList(response?.data?.savedFilter || []);
        const { componentCountData, replenishment, salesSum } = response.data;
        const records = response?.data?.records?.map((d) => ({
          ...d,
          fald_id: d?.id,
        }));
        const tableData = (
          records?.map((e) => {
            const index = Object.keys(componentCountData).findIndex(
              (d) => parseInt(d) === parseInt(e.fald_id)
            );

            const replenishment_ = replenishment[e?.fald_id];
            const salesSum_ = salesSum[e?.fald_id];
            return {
              ...e,
              linkStatus: index !== -1 ? true : false,
              replenishment_QTYNew: ReplenishmentValues(response.data, e) || 0,
              salesVelocityPerDay_: replenishment_?.salesVelocityPerDay,
              salesVelocityPerMonth_: replenishment_?.salesVelocityPerMonth,
              sells_out_in_: replenishment_?.sells_out_in,
              sells_out_in_date_: replenishment_?.sells_out_in_date,
              sales_for_forcast_: replenishment_?.sales_for_forcast,
              manage_afn_fulfillable_quantity_:
                salesSum_?.manage_afn_fulfillable_quantity,
              reserved_fc_transfers_: salesSum_?.reserved_fc_transfers,
              manage_afn_total_quantity_: salesSum_?.manage_afn_total_quantity,
              manage_afn_inbound_shipped_quantity_:
                salesSum_?.manage_afn_inbound_shipped_quantity,
              reserved_qty_: salesSum_?.reserved_qty,
              maximum_shipment_quantity_: salesSum_?.maximum_shipment_quantity,
              on_order_: salesSum_?.on_order,
              replenishment_data: replenishment_,
              replenishment_QTY_Color:
                salesSum[e.fald_id] !== undefined &&
                salesSum?.[e.fald_id]?.replenishment_QTY !== null
                  ? "#ff9f43"
                  : "#39d27c",
            };
          }) || []
        )?.sort(
          (a, b) =>
            parseFloat(b.replenishment_QTYNew) -
            parseFloat(a.replenishment_QTYNew)
        );

        if (tableData?.length > 0) {
          setFilters({
            ...data,
            page: response.data?.pagination?.page,
            "page-size": response.data?.pagination?.page_size,
            page_count: response.data?.pagination?.page_count,
            totalPage: response.data?.pagination?.totalCount,
          });
        }
        setList(tableData);
        setAllData({
          ...response.data,
          records: tableData,
        });
      } else {
        setAdvanceFilterLoading(false);
        setAdvanceFilterList([]);
        setLoading(false);
        setList([]);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      setList([]);
      setAdvanceFilterLoading(false);
      setAdvanceFilterList([]);
      message.warning(error?.message);
    }
  };

  const UpdateItemRepAction = async (api) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        api,
        "POST",
        {
          selectedPoItems: selectedRows?.map((d) => d?.id),
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSelectedRows([]);
        message.destroy();
        message.success(response?.message);
        setLoading(true);
        CreatePurchaseOrderDemo({
          ...filters,
        });
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const getLink = async (rows) => {
    const response = await MakeApiCall(
      "check-for-linked",
      "POST",
      { selectedPoItems: rows?.map((d) => d?.id) },
      true,
      {},
      userData?.user?.auth_token
    );

    if (response.status) {
      setLinkList(response.data.vendorList);
      setVendorModal(true);
    } else {
      message.destroy();
      message.warning(response.message);
    }
  };

  const createPOAction = (type) => {
    if (type === "create_po_for_items") {
      const findLinked = selectedRows?.filter((d) => d?.linkStatus);
      console.log(findLinked, selectedRows, "selectedRows");
      if (findLinked?.length === selectedRows?.length) {
      } else {
        getLink(selectedRows);
      }
      // selected.forEach((e) => {
      //   if (e.linkStatus) {
      //     linked.push(e);
      //   }
      // });
    } else {
      // working on
    }
  };

  const bulkAction = (key) => {
    switch (key) {
      case "item_note":
        setItemNoteModal({
          show: true,
          data: selectedRows,
        });
        break;
      case "set_as_replenishment":
        UpdateItemRepAction("update-item-rep");
        break;
      case "set_as_non_replenishment":
        UpdateItemRepAction("update-item-non-rep");
        break;
      case "set_lead_time":
        UpdateItemRepAction("set-lead-time");
        break;
      case "create_po_for_items":
        createPOAction("create_po_for_items");
        break;
      case "add_items_to_existing_po":
        createPOAction("add_items_to_existing_po");
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-2 flex-wrap">
          <MarketplaceSelect
            callAfterMarketPlace={(e) => {
              setLoading(true);
              CreatePurchaseOrderDemo({
                ...filters,
                marketplace_id: e,
              });
              setFilters({ ...filters, marketplace_id: e });
            }}
            showSearch
            MarketplaceData={(e) => {
              // console.log("MarketplaceData");
            }}
            onChange={(e) => {
              setFilters({ ...filters, marketplace_id: e });
              setLoading(true);
              CreatePurchaseOrderDemo({
                ...filters,
                marketplace_id: e,
              });
            }}
            userData={userData}
          />
          <Select
            options={CreateOrderStatusList}
            value={filters?.action}
            onChange={(e) => {
              setLoading(true);
              CreatePurchaseOrderDemo({
                ...filters,
                action: e,
              });
              setFilters({ ...filters, action: e });
            }}
            size="large"
            placeholder="Status"
            className="w-150px"
          />
          <Input
            className="w-250px"
            placeholder="Search by Brand, Prep Type, Title"
            value={filters?.filter_value}
            onChange={(e) => {
              setFilters({
                ...filters,
                filter_value: e.target.value,
              });
            }}
            onPressEnter={() => {
              setLoading(true);
              CreatePurchaseOrderDemo({
                ...filters,
              });
            }}
          />
          <Select
            options={advanceFilterList?.map((d) => ({
              label: d?.user_filter_name,
              value: d?.usf_id,
              ...d,
            }))}
            onChange={(e, _) => {
              const obj = {
                action: _?.component_type,
                "filters[unit_cost]": _?.unit_cost,
                "filters[single_pack]": _?.single_pack ? 1 : 0,
                "filters[multi_pack]": _?.multi_pack ? 1 : 0,
                "filters[kit]": _?.kit ? 1 : 0,
                "filters[standard_size]": _?.standard_size ? 1 : 0,
                "filters[oversize]": _?.oversize ? 1 : 0,
                "filters[vendor_id]": _?.vendor_id,
                "filters[brand_id]": _?.brand_id,
                search_type: "advance",
                filter_value: _?.user_filter_value,
                usf_id: _?.usf_id,
              };

              Filterform.setFieldsValue({
                user_filter_name: _?.user_filter_name,
                user_filter_value: _?.user_filter_value,
                link_type: _?.component_type,
                single_pack: _?.single_pack ? true : false,
                multi_pack: _?.multi_pack ? true : false,
                kit: _?.kit ? true : false,
                standard_size: _?.standard_size ? true : false,
                oversize: _?.oversize ? true : false,
                vendor_id: _?.vendor_id,
                brand_id: _?.brand_id,
                unit_cost: _?.unit_cost,
              });
              setSelectedAdvanceFilter(_);
              setFilters({
                ...filters,
                ...obj,
              });
              CreatePurchaseOrderDemo({
                ...filters,
                ...obj,
              });
            }}
            value={filters?.usf_id}
            allowClear
            className="w-200px"
            size="large"
            placeholder="Saved Filter"
          />
          <Button
            onClick={() => setMoreFilterView(true)}
            color="primary"
            variant="filled"
          >
            <Icon width={22} icon="solar:filter-bold-duotone" />
          </Button>
          <RangePickerCF
            allowClear={false}
            value={
              filters?.from_date && filters?.to_date
                ? [dayjs(filters?.from_date), dayjs(filters?.to_date)]
                : []
            }
            onChange={(e) => {
              setFilters({
                ...filters,
                from_date: e[0].format("YYYY-MM-DD"),
                to_date: e[1].format("YYYY-MM-DD"),
              });
              setLoading(true);
              CreatePurchaseOrderDemo({
                ...filters,
                from_date: e[0].format("YYYY-MM-DD"),
                to_date: e[1].format("YYYY-MM-DD"),
              });
            }}
          />
          <Dropdown
            overlay={
              <Menu
                onClick={(e) => {
                  const { key } = e;
                  bulkAction(key);
                }}
              >
                <Menu.Item key="item_note">Add Item Note</Menu.Item>
                <Menu.Item key="set_as_replenishment">
                  Set as Replenishment
                </Menu.Item>
                <Menu.Item key="set_as_non_replenishment">
                  Set as Non-Replenishment
                </Menu.Item>
                <Menu.Item key="set_lead_time">Set Lead Time</Menu.Item>
                <Menu.Item key="create_po_for_items">
                  Create PO for {selectedRows?.length || 0} Item(s)
                </Menu.Item>
                <Menu.Item key="add_items_to_existing_po">
                  Add {selectedRows?.length || 0} Item(s) to Existing PO
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
            disabled={selectedRows?.length === 0}
          >
            <Button type="primary">
              Bulk Actions <Icon icon="bxs:down-arrow" />
            </Button>
          </Dropdown>
        </div>
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              window.open(`${URL}/uploads/Cheddy-Forecasting-Template.xlsx`);
            }}
            type="dashed"
          >
            <Icon icon="material-symbols:download" width={20} />
            Forecast Template
          </Button>
          <Button onClick={() => navigate("/po-template")} type="primary">
            <Icon icon="fluent-mdl2:web-template" width={22} /> PO Template
          </Button>
          <Button onClick={() => navigate("/po-list")} type="primary">
            <Icon icon="icon-park-outline:list" width={22} /> PO List
          </Button>
        </div>
      </div>

      <div className="card p-3 mt-3">
        <div className="card-body p-0">
          <Suspense fallback={<div>Loading...</div>}>
            {loading ? (
              <TableLoading
                id="test-table"
                row={10}
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={false}
                actions={[]}
                style={{ height: "calc(100vh - 500px)", overflow: "hidden" }}
              />
            ) : list?.length === 0 ? (
              <Empty />
            ) : (
              <Table2
                columns={columns?.map((d) => ({
                  ...d,
                  ...PropsFilter(d?.dataIndex),
                }))}
                dataSource={list}
                loading={loading}
                rowKey="id"
                pagination={PaginationConfig({
                  pageSize: filters["page-size"],
                  currentPage: filters.page,
                  totalPage: filters.totalPage,
                  onChange: (page, pageSize) => {
                    const obj = {
                      ...filters,
                      page: page,
                      "page-size": pageSize,
                    };
                    setFilters(obj);
                    setLoading(true);
                    CreatePurchaseOrderDemo({
                      ...obj,
                    });
                  },
                })}
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys: selectedRows?.map((d) => d.id),
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRows(selectedRows);
                  },
                }}
                expandIcon={({ expanded, onExpand, record }) => (
                  <Button
                    icon={
                      <div
                        className="symbol symbol-30px ms-5 cursor-pointer"
                        style={{
                          transform: `rotate(${expanded ? 90 : 0}deg)`,
                        }}
                      >
                        <span className="symbol-label accordion-icon bg-light-primary">
                          <Icon icon="mingcute:link-fill" />
                        </span>
                      </div>
                    }
                    onClick={(e) => onExpand(record, e)}
                    size="small"
                    style={{ border: "none", background: "none" }}
                  />
                )}
                scroll={{ x: "max-content", y: "calc(100vh - 380px)" }}
              />
            )}
          </Suspense>
        </div>
      </div>

      {replenishmentBE.show && (
        <ReplenishmentModal
          isOpen={replenishmentBE.show}
          close={() => {
            setReplenishmentBE({
              show: false,
              fald_id: null,
            });
          }}
          dataList={replenishmentBE?.replenishment_data}
          fald_id={replenishmentBE.fald_id}
        />
      )}
      {replenishmentModal.visible && (
        <UpdateProfile
          closeModal={() => {
            let object = { ...replenishmentModal };
            object.visible = false;
            setReplenishmentModal(object);
          }}
          submit={() => {
            setLoading(true);
            CreatePurchaseOrderDemo(filters);
          }}
          data={replenishmentModal}
          userData={userData}
          isOpen={replenishmentModal.visible}
        />
      )}
      {onOrderModal.visible && (
        <OnOrderModal
          closeModal={() => {
            let object = { ...onOrderModal };
            object.visible = false;
            setOnOrderModal(object);
          }}
          userData={userData}
          data={onOrderModal}
          isOpen={onOrderModal.visible}
        />
      )}
      {inTransitInventory.visible && (
        <InTransitInventoryModal
          closeModal={() => {
            let object = { ...inTransitInventory };
            object.visible = false;
            setInTransitInventory(object);
          }}
          userData={userData}
          data={inTransitInventory}
          isOpen={inTransitInventory.visible}
        />
      )}
      {replenishmentLogicModalSells.visible && (
        <SellsOutInViewModal
          closeModal={() => {
            let object = { ...replenishmentLogicModalSells };
            object.visible = false;
            object.DaysofStockReaming =
              replenishmentLogicModalSells.DaysofStockReaming;
            setReplenishmentLogicModalSells(object);
          }}
          data={replenishmentLogicModalSells.value}
          isOpen={replenishmentLogicModalSells.visible}
        />
      )}
      {unitCostHistory?.show && (
        <UnitHistory
          unitCostHistory={unitCostHistory}
          handleCancel={handleCancel}
          columnsUnitCost={columnsUnitCost}
        />
      )}

      <ASINNote
        userData={userData}
        onSubmit={(e, id) => {
          setSelectedRows([]);
          setList((prev) => {
            return prev.map((d) => {
              if (d.fald_id === id) {
                return {
                  ...d,
                  item_note: e?.itemNote,
                };
              }
              return d;
            });
          });
        }}
        asinNote={asinNote}
        selectedRows={selectedRows}
        handleCancel={handleCancel}
      />
      <ASINNoteItem
        userData={userData}
        onSubmit={(e, id) => {
          setSelectedRows([]);
          setList((prev) => {
            return prev.map((d) => {
              if (id?.includes(d?.id)) {
                return {
                  ...d,
                  item_note: e?.itemNote,
                };
              }
              return d;
            });
          });
        }}
        asinNote={itemNoteModal}
        selectedRows={selectedRows}
        handleCancel={handleCancel}
      />
      {editModal && (
        <EditModal
          show={editModal}
          brandList={brandList}
          brandLoading={brandLoading}
          vendorList={vendorList}
          vendorLoading={vendorLoading}
          userData={userData}
          selectedRow={selectedRow}
          onClose={() => {
            setSelectedRow({});
            setEditModal(false);
          }}
          getList={() => {
            CreatePurchaseOrderDemo({
              ...filters,
            });
          }}
        />
      )}
      <AdvancedFilter
        setMoreFilterView={setMoreFilterView}
        moreFilterView={moreFilterView}
        brandList={brandList}
        brandLoading={brandLoading}
        vendorList={vendorList}
        vendorLoading={vendorLoading}
        userData={userData}
        advanceFilterLoading={advanceFilterLoading}
        advanceFilterList={advanceFilterList}
        setSelectedAdvanceFilter={setSelectedAdvanceFilter}
        selectedAdvanceFilter={selectedAdvanceFilter}
        getList={() => {}}
        form={Filterform}
        applyFilter={(e) => {
          setMoreFilterView(false);
          setFilters({
            ...filters,
            ...e,
          });
          CreatePurchaseOrderDemo({
            ...filters,
            ...e,
          });
        }}
      />
      {vendorModal && (
        <VendorDropdownList
          closeModal={() => {
            setVendorModal(false);
          }}
          linkList={linkList}
          selectedRows={selectedRows}
          isOpen={vendorModal}
        />
      )}
    </Wrapper>
  );
};

export default CreateOrder;
