export const ReplenishmentValues = (data, row) => {
  if (data?.replenishment[row?.fald_id]?.rep_QTY !== undefined) {
    const repList = data?.replenishment[row?.fald_id]?.rep_QTY;
    return repList[repList.length - 1];
  }
  if (data?.salesSum?.[row.fald_id] === undefined) {
    return 0;
  }
  if (data?.salesSum?.[row.fald_id]?.replenishment_QTY !== null) {
    return parseInt(data.salesSum[row.fald_id].replenishment_QTY);
  }
  return parseInt(data?.replenishment[row?.fald_id]?.replenishment_QTY || 0);
};
