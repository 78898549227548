import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, DatePicker, Select, Table, Tag } from "antd";
import React from "react";
import Chart from "react-apexcharts";
import ImportFIle from "../../../Components/import-file";

export default function Purchase({ userData }) {
  const options = {
    series: [
      {
        name: "PO Spend",
        type: "column",
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
      },
      {
        name: "ASIN Unit Ordered",
        type: "line",
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
      },
    ],
    chart: {
      height: 300,
      type: "line",
      toolbar: { show: false },
      // Uncomment to enable drop shadow
      // dropShadow: {
      //   enabled: true,
      //   top: 12,
      //   left: 0,
      //   bottom: 0,
      //   right: 0,
      //   blur: 2,
      //   color: "rgba(132, 145, 183, 0.3)",
      //   opacity: 0.45,
      // },
    },
    markers: {
      size: 5,
      hover: {
        size: 9,
      },
    },
    colors: ["#0079ae", "#000"],
    plotOptions: {
      bar: { horizontal: false, columnWidth: "50%", borderRadius: 3 },
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: [0, 3.5],
      dashArray: [0, 4],
      lineCap: "round",
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    labels: [
      "01 Jan 2001",
      "02 Jan 2001",
      "03 Jan 2001",
      "04 Jan 2001",
      "05 Jan 2001",
      "06 Jan 2001",
      "07 Jan 2001",
      "08 Jan 2001",
      "09 Jan 2001",
      "10 Jan 2001",
      "11 Jan 2001",
      "12 Jan 2001",
    ],
    xaxis: {
      type: "datetime",
    },
    yaxis: [
      {
        title: {
          text: "",
          style: { colors: "#333", fontSize: "14px" },
        },
        labels: {
          formatter: function (val) {
            return val + "k";
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
      {
        opposite: true,
        title: {
          text: "",
          style: { colors: "#333", fontSize: "14px" },
        },
      },
    ],
  };

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
    },
    {
      title: "PO Spend",
      dataIndex: "poSpend",
      key: "poSpend",
    },
    {
      title: "ASIN Units Ordered",
      dataIndex: "asinUnitsOrdered",
      key: "asinUnitsOrdered",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color="red" style={{ fontWeight: 600 }} bordered={false}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
    },
    {
      title: "Estimated Vendor-3PL Delivery Date",
      dataIndex: "estimatedVendorDeliveryDate",
      key: "estimatedVendorDeliveryDate",
    },
    {
      title: "Actual Vendor-3PL Delivery Date",
      dataIndex: "actualVendorDeliveryDate",
      key: "actualVendorDeliveryDate",
    },
    {
      title: "Vendor-3PL Delivery Variance",
      dataIndex: "vendorDeliveryVariance",
      key: "vendorDeliveryVariance",
    },
    {
      title: "Adjusted Vendor Lead Time",
      dataIndex: "adjustedVendorLeadTime",
      key: "adjustedVendorLeadTime",
    },
    {
      title: "Estimated 3PL-Shelf Deliver Date",
      dataIndex: "estimatedShelfDeliverDate",
      key: "estimatedShelfDeliverDate",
    },
    {
      title: "Actual 3PL-Shelf Deliver Date",
      dataIndex: "actualShelfDeliverDate",
      key: "actualShelfDeliverDate",
    },
    {
      title: "3PL-Shelf Deliver Variance",
      dataIndex: "shelfDeliverVariance",
      key: "shelfDeliverVariance",
      render: (variance) => <span className="text-success">{variance}</span>,
    },
    {
      title: "Adjusted Outbound Lead Time",
      dataIndex: "adjustedOutboundLeadTime",
      key: "adjustedOutboundLeadTime",
    },
    {
      title: "Adjusted Total Lead Time",
      dataIndex: "adjustedTotalLeadTime",
      key: "adjustedTotalLeadTime",
    },
  ];

  const data = [
    {
      key: "1",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedVendorDeliveryDate: "1/6/2024",
      actualVendorDeliveryDate: "1/6/2024",
      vendorDeliveryVariance: 0,
      adjustedVendorLeadTime: 3,
      estimatedShelfDeliverDate: "1/23/2024",
      actualShelfDeliverDate: "1/8/2024",
      shelfDeliverVariance: "-15",
      adjustedOutboundLeadTime: 2,
      adjustedTotalLeadTime: 5,
    },
    {
      key: "2",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedVendorDeliveryDate: "1/6/2024",
      actualVendorDeliveryDate: "1/6/2024",
      vendorDeliveryVariance: 0,
      adjustedVendorLeadTime: 3,
      estimatedShelfDeliverDate: "1/23/2024",
      actualShelfDeliverDate: "1/8/2024",
      shelfDeliverVariance: "-15",
      adjustedOutboundLeadTime: 2,
      adjustedTotalLeadTime: 5,
    },
    {
      key: "3",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedVendorDeliveryDate: "1/6/2024",
      actualVendorDeliveryDate: "1/6/2024",
      vendorDeliveryVariance: 0,
      adjustedVendorLeadTime: 3,
      estimatedShelfDeliverDate: "1/23/2024",
      actualShelfDeliverDate: "1/8/2024",
      shelfDeliverVariance: "-15",
      adjustedOutboundLeadTime: 2,
      adjustedTotalLeadTime: 5,
    },
    // Repeat for other rows if needed
  ];

  const columns1 = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
    },
    {
      title: "PO Spend",
      dataIndex: "poSpend",
      key: "poSpend",
    },
    {
      title: "ASIN Units Ordered",
      dataIndex: "asinUnitsOrdered",
      key: "asinUnitsOrdered",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color="red" style={{ fontWeight: 600 }} bordered={false}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
    },
    {
      title: "Estimated Invoice Date",
      dataIndex: "estimatedInvoiceDate",
      key: "estimatedInvoiceDate",
    },
    {
      title: "Actual Invoice Date",
      dataIndex: "actualInvoiceDate",
      key: "actualInvoiceDate",
    },
  ];

  const data1 = [
    {
      key: "1",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedInvoiceDate: "1/6/2024",
      actualInvoiceDate: "1/6/2024",
    },
    {
      key: "2",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedInvoiceDate: "1/6/2024",
      actualInvoiceDate: "1/6/2024",
    },
    {
      key: "3",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedInvoiceDate: "1/6/2024",
      actualInvoiceDate: "1/6/2024",
    },
    {
      key: "4",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedInvoiceDate: "1/6/2024",
      actualInvoiceDate: "1/6/2024",
    },
    {
      key: "5",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedInvoiceDate: "1/6/2024",
      actualInvoiceDate: "1/6/2024",
    },
    {
      key: "6",
      brand: "Zerowater",
      poNumber: "1396",
      poSpend: "$1,979.22",
      asinUnitsOrdered: 180,
      status: "Closed",
      submissionDate: "1/6/2024",
      estimatedInvoiceDate: "1/6/2024",
      actualInvoiceDate: "1/6/2024",
    },

    // Add other rows if needed
  ];

  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            placeholder="Select Marketplace"
          />
        </div>
        <div className="position-relative">
          <DatePicker.RangePicker
            size="large"
            className="w-250px"
            suffixIcon={
              <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
              </i>
            }
          />
        </div>

        <div className="position-relative">
          <Select
            size="large"
            className="w-150px"
            placeholder="Select PO Status"
          />
        </div>
        <div className="position-relative">
          <Select size="large" className="w-175px" placeholder="Select Brand" />
        </div>
        <ImportFIle
          fileType=".xlsx"
          multiple={false}
          documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          // downloadSamplePath={`${ORIGIN}/elevayz-api/uploads/import_files/file_templates/product_template.xlsx`}
          trigger={["click"]}
          endpoint="upload-product"
          methodKey="POST"
          bodyKey={{
            marketplace_id: "ATVPDKIKX0DER",
          }}
          paramKey="UploadedDataFile[import_file]"
          onResponse={(e) => {}}
          tokenKey={userData?.token}
        >
          <Button type="primary" style={{ marginRight: "10px" }}>
            <Icon icon="bi:filetype-xlsx" width={20} /> Import
          </Button>
        </ImportFIle>
      </div>
      <div className="row g-3 g-lg-6 mb-7">
        {/*begin::Col*/}
        <div className="col">
          {/*begin::Items*/}
          <div className="bg-gray-100 bg-cus-grad rounded-2 px-6 py-5 position-relative overflow-hidden">
            <i
              className="ki-duotone ki-abstract-44 fs-1 text-primary card-des-icon"
              style={{ opacity: ".1" }}
            >
              <span className="path1" />
              <span className="path2" />
            </i>
            {/*begin::Stats*/}
            <div className="m-0">
              {/*begin::Number*/}
              <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-2">
                $3700
              </span>
              {/*end::Number*/}
              {/*begin::Desc*/}
              <span className="text-primary fw-semibold fs-6">
                3PL Shelf Delivery Variance
              </span>
              {/*end::Desc*/}
            </div>
            {/*end::Stats*/}
          </div>
          {/*end::Items*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col">
          {/*begin::Items*/}
          <div className="bg-gray-100 bg-cus-grad rounded-2 px-6 py-5 position-relative overflow-hidden">
            <i
              className="ki-duotone ki-abstract-44 fs-1 text-primary card-des-icon"
              style={{ opacity: ".1" }}
            >
              <span className="path1" />
              <span className="path2" />
            </i>
            {/*begin::Stats*/}
            <div className="m-0">
              {/*begin::Number*/}
              <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-2">
                596
              </span>
              {/*end::Number*/}
              {/*begin::Desc*/}
              <span className="text-primary fw-semibold fs-6">
                Vendor - 3PL Shelf Delivery Variance
              </span>
              {/*end::Desc*/}
            </div>
            {/*end::Stats*/}
          </div>
          {/*end::Items*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col">
          {/*begin::Items*/}
          <div className="bg-gray-100 bg-cus-grad rounded-2 px-6 py-5 position-relative overflow-hidden">
            <i
              className="ki-duotone ki-abstract-44 fs-1 text-primary card-des-icon"
              style={{ opacity: ".1" }}
            >
              <span className="path1" />
              <span className="path2" />
            </i>
            {/*begin::Stats*/}
            <div className="m-0">
              {/*begin::Number*/}
              <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-2">
                $1800
              </span>
              {/*end::Number*/}
              {/*begin::Desc*/}
              <span className="text-primary fw-semibold fs-6">
                Adjusted Vendor Lead Time
              </span>
              {/*end::Desc*/}
            </div>
            {/*end::Stats*/}
          </div>
          {/*end::Items*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col">
          {/*begin::Items*/}
          <div className="bg-gray-100 bg-cus-grad rounded-2 px-6 py-5 position-relative overflow-hidden">
            <i
              className="ki-duotone ki-abstract-44 fs-1 text-primary card-des-icon"
              style={{ opacity: ".1" }}
            >
              <span className="path1" />
              <span className="path2" />
            </i>
            {/*begin::Stats*/}
            <div className="m-0">
              {/*begin::Number*/}
              <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-2">
                31
              </span>
              {/*end::Number*/}
              {/*begin::Desc*/}
              <span className="text-primary fw-semibold fs-6">
                Adjusted Outbound Lead Time
              </span>
              {/*end::Desc*/}
            </div>
            {/*end::Stats*/}
          </div>
          {/*end::Items*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col">
          {/*begin::Items*/}
          <div className="bg-gray-100 bg-cus-grad rounded-2 px-6 py-5 position-relative overflow-hidden">
            <i
              className="ki-duotone ki-abstract-44 fs-1 text-primary card-des-icon"
              style={{ opacity: ".1" }}
            >
              <span className="path1" />
              <span className="path2" />
            </i>
            {/*begin::Stats*/}
            <div className="m-0">
              {/*begin::Number*/}
              <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-2">
                $123.51
              </span>
              {/*end::Number*/}
              {/*begin::Desc*/}
              <span className="text-primary fw-semibold fs-6">
                Adjusted Total Lead Time
              </span>
              {/*end::Desc*/}
            </div>
            {/*end::Stats*/}
          </div>
          {/*end::Items*/}
        </div>
        {/*end::Col*/}
        {/*begin::Col*/}
        <div className="col-md-4">
          <div className=" bg-light rounded p-3 px-5">
            <h4 className="text-center text-primary">Brand Lead Time</h4>
            <div className="separator separator-dashed" />
            <div className=" mt-2 d-flex justify-content-between">
              <div className="m-0 text-center">
                <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">
                  51
                </span>
                <span className="text-gray-700 fw-semibold fs-6">
                  Venodr Lead Time
                </span>
              </div>
              <div className="border-end border-end-dashed border-gray-300" />
              <div className="m-0 text-center">
                <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">
                  10
                </span>
                <span className="text-gray-700 fw-semibold fs-6">
                  Outbound Lead Time
                </span>
              </div>
              <div className="border-end border-end-dashed border-gray-300" />
              <div className="m-0 text-center">
                <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-1">
                  55
                </span>
                <span className="text-gray-700 fw-semibold fs-6">
                  Total Lead Time
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*end::Col*/}
      </div>
      <div className="row gx-5 gx-xl-7 mb-7">
        <div className="col-lg-12">
          <div className="card card-flush ">
            <div className="card-header  mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Variance Legend
                </span>
              </h3>
              <div className="card-toolbar">
                <div className="d-flex flex-wrap pt-0 ">
                  {/*begin::Item*/}
                  <div className="d-flex flex-row gap-6">
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-0 mb-sm-0">
                      <span className="bullet bullet-dot bg-success me-2 h-15px w-15px" />
                      <span className="fw-bold text-gray-900 fs-6">Faster</span>
                    </div>
                    {/*ed::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center">
                      <span className="bullet bullet-dot bg-danger me-2 h-15px w-15px" />
                      <span className="fw-bold text-gray-900 fs-6">Slower</span>
                    </div>
                    {/*ed::Item*/}
                  </div>
                  {/*ed::Item*/}
                </div>
              </div>
            </div>
            {/*begin::Body*/}
            <div className="card-body pt-2">
              <div className="table-responsive">
                {/*begin::Table*/}
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
                {/*end::Table*/}
              </div>
            </div>
          </div>
        </div>
        {/*begin::Col*/}
      </div>
      <div className="row gx-5 gx-xl-7">
        <div className="col-xl-7 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header mb-0 ">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Overview
                </span>
              </h3>
              <div className="card-toolbar">
                <div className=" rounded p-0 d-flex gap-5 pt-2">
                  <div className="d-flex align-items-center me-2 box-statics p-2 rounded ps-4 pe-7">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-40px me-5">
                      <div className="symbol-label bg-light-primary shadow-sm">
                        <i className="ki-duotone ki-wallet fs-2x text-primary">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>
                      </div>
                    </div>
                    {/*end::Symbol*/}
                    {/*begin::Title*/}
                    <div>
                      <div className="fs-5 text-gray-900 fw-bolder">
                        $34,272.09
                      </div>
                      <div className="fs-7 text-gray-800 fw-semibold">
                        Total PO Spend
                      </div>
                    </div>
                    {/*end::Title*/}
                  </div>
                  <div className="d-flex align-items-center me-2 box-statics p-2 rounded ps-4 pe-7">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-40px me-5">
                      <div className="symbol-label bg-light-primary shadow-sm">
                        <i className="ki-duotone ki-lots-shopping fs-2x text-primary">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                          <span className="path6" />
                          <span className="path7" />
                          <span className="path8" />
                        </i>
                      </div>
                    </div>
                    {/*end::Symbol*/}
                    {/*begin::Title*/}
                    <div>
                      <div className="fs-5 text-gray-900 fw-bolder">
                        474,340
                      </div>
                      <div className="fs-7 text-gray-800 fw-semibold">
                        ASIN Unit Ordered
                      </div>
                    </div>
                    {/*end::Title*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body pt-2 pb-2">
              <div className="table-responsive pb-0">
                <Table
                  scroll={{ x: "max-content" }}
                  pagination={false}
                  columns={columns1}
                  dataSource={data1}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100 mb-5">
            <div className="card-header mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  PO Spend Based on
                </span>
              </h3>
              <div className="card-toolbar">
                <ul className="nav" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 active"
                      data-kt-timeline-widget-1="tab"
                      data-bs-toggle="tab"
                      href="#kt_timeline_widget_1_tab_day"
                      aria-selected="true"
                      role="tab"
                    >
                      Invoice Date
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1"
                      data-kt-timeline-widget-1="tab"
                      data-bs-toggle="tab"
                      href="#kt_timeline_widget_1_tab_week"
                      aria-selected="false"
                      role="tab"
                      tabIndex={-1}
                    >
                      Submission Date
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <div className="tab-content">
                {/*begin::Tab pane*/}
                <Chart
                  options={options}
                  series={options.series}
                  type="line"
                  height={350}
                />
                {/*end::Tab pane*/}
                {/*begin::Tab pane*/}

                {/*end::Tab pane*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gx-5 gx-xl-7 mb-7">
        <div className="col-lg-12">
          <div className="card card-flush ">
            <div className="card-header  mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Purchase Order by
                </span>
              </h3>
              <div className="card-toolbar">
                <ul className="nav" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 fs-6 active"
                      data-kt-timeline-widget-1="tab"
                      data-bs-toggle="tab"
                      href="#kt_timeline_widget_1_tab_sub"
                      aria-selected="true"
                      role="tab"
                    >
                      Submission Date
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1 fs-6"
                      data-kt-timeline-widget-1="tab"
                      data-bs-toggle="tab"
                      href="#kt_timeline_widget_1_tab_inb"
                      aria-selected="false"
                      role="tab"
                      tabIndex={-1}
                    >
                      Inbound Date
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/*begin::Body*/}
            <div className="card-body pt-2">
              <div className="tab-content">
                {/*begin::Tab pane*/}
                <div
                  className="tab-pane fw-bold fade active show"
                  id="kt_timeline_widget_1_tab_sub"
                  role="tabpanel"
                  aria-labelledby="day-tab"
                >
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table align-middle table-row-dashed border-gray-300 table-row-gray-300 gy-3 gx-4 gs-4 mb-0">
                      {/*begin::Table head*/}
                      <thead>
                        <tr className="fw-bolder text-gray-800 bg-main-light">
                          <th className="min-w-100px">Brand</th>
                          <th className="min-w-100px">PO Number</th>
                          <th className="min-w-100px">PO Spend</th>
                          <th className="min-w-150px">ASIN Units Ordered</th>
                          <th className="min-w-100px">Status</th>
                          <th className="min-w-150px">Submission Date</th>
                          <th className="min-w-150px">
                            Estimated Vendor- 3PL Delivery Date
                          </th>
                          <th className="min-w-150px">
                            Actual- Vendor - 3PL Delivery Date
                          </th>
                          <th className="min-w-150px">
                            Vendor - 3PL Delivery Variance
                          </th>
                          <th className="min-w-150px">
                            Adjusted Vendor Lead Time
                          </th>
                          <th className="min-w-150px">
                            Estimated 3PL - Shelf Deliver Date
                          </th>
                          <th className="min-w-150px">
                            Actual 3PL - Shelf Deliver Date
                          </th>
                          <th className="min-w-150px">
                            3PL - Shelf Deliver Variance
                          </th>
                          <th className="min-w-175px">
                            Adjusted Outbound Lead Time
                          </th>
                          <th className="min-w-150px">
                            Adjusted Total Lead Time
                          </th>
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <a href className="fw-bold fs-6">
                                Zerowater
                              </a>
                            </div>
                          </td>
                          <td>1396</td>
                          <td>$1,979.22</td>
                          <td>180</td>
                          <td>
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">
                              Closed
                            </span>
                          </td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>0</td>
                          <td>3</td>
                          <td>1/23/2024</td>
                          <td>1/8/2024</td>
                          <td>
                            <span className="text-success">-15</span>
                          </td>
                          <td>2</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <a href className="fw-bold fs-6">
                                Zerowater
                              </a>
                            </div>
                          </td>
                          <td>1396</td>
                          <td>$1,979.22</td>
                          <td>180</td>
                          <td>
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">
                              Closed
                            </span>
                          </td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>0</td>
                          <td>3</td>
                          <td>1/23/2024</td>
                          <td>1/8/2024</td>
                          <td>
                            <span className="text-success">-15</span>
                          </td>
                          <td>2</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <a href className="fw-bold fs-6">
                                Zerowater
                              </a>
                            </div>
                          </td>
                          <td>1396</td>
                          <td>$1,979.22</td>
                          <td>180</td>
                          <td>
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">
                              Closed
                            </span>
                          </td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>0</td>
                          <td>3</td>
                          <td>1/23/2024</td>
                          <td>1/8/2024</td>
                          <td>
                            <span className="text-success">-15</span>
                          </td>
                          <td>2</td>
                          <td>5</td>
                        </tr>
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Tab pane*/}
                {/*begin::Tab pane*/}
                <div
                  className="tab-pane fw-bold fade"
                  id="kt_timeline_widget_1_tab_inb"
                  role="tabpanel"
                  aria-labelledby="week-tab"
                >
                  <div className="table-responsive">
                    {/*begin::Table*/}
                    <table className="table align-middle table-row-dashed border-gray-300 table-row-gray-300 gy-3 gx-4 gs-4 mb-0">
                      {/*begin::Table head*/}
                      <thead>
                        <tr className="fw-bolder text-gray-800 bg-main-light">
                          <th className="min-w-100px">Brand</th>
                          <th className="min-w-100px">PO Number</th>
                          <th className="min-w-100px">PO Spend</th>
                          <th className="min-w-150px">ASIN Units Ordered</th>
                          <th className="min-w-100px">Status</th>
                          <th className="min-w-150px">Submission Date</th>
                          <th className="min-w-150px">
                            Estimated Vendor- 3PL Delivery Date
                          </th>
                          <th className="min-w-150px">
                            Actual- Vendor - 3PL Delivery Date
                          </th>
                          <th className="min-w-150px">
                            Vendor - 3PL Delivery Variance
                          </th>
                          <th className="min-w-150px">
                            Adjusted Vendor Lead Time
                          </th>
                          <th className="min-w-150px">
                            Estimated 3PL - Shelf Deliver Date
                          </th>
                          <th className="min-w-150px">
                            Actual 3PL - Shelf Deliver Date
                          </th>
                          <th className="min-w-150px">
                            3PL - Shelf Deliver Variance
                          </th>
                          <th className="min-w-175px">
                            Adjusted Outbound Lead Time
                          </th>
                          <th className="min-w-150px">
                            Adjusted Total Lead Time
                          </th>
                        </tr>
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <a href className="fw-bold fs-6">
                                Zerowater
                              </a>
                            </div>
                          </td>
                          <td>1396</td>
                          <td>$1,979.22</td>
                          <td>180</td>
                          <td>
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">
                              Closed
                            </span>
                          </td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>0</td>
                          <td>3</td>
                          <td>1/23/2024</td>
                          <td>1/8/2024</td>
                          <td>
                            <span className="text-success">-15</span>
                          </td>
                          <td>2</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <a href className="fw-bold fs-6">
                                Zerowater
                              </a>
                            </div>
                          </td>
                          <td>1396</td>
                          <td>$1,979.22</td>
                          <td>180</td>
                          <td>
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">
                              Closed
                            </span>
                          </td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>0</td>
                          <td>3</td>
                          <td>1/23/2024</td>
                          <td>1/8/2024</td>
                          <td>
                            <span className="text-success">-15</span>
                          </td>
                          <td>2</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <a href className="fw-bold fs-6">
                                Zerowater
                              </a>
                            </div>
                          </td>
                          <td>1396</td>
                          <td>$1,979.22</td>
                          <td>180</td>
                          <td>
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">
                              Closed
                            </span>
                          </td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>1/6/2024</td>
                          <td>0</td>
                          <td>3</td>
                          <td>1/23/2024</td>
                          <td>1/8/2024</td>
                          <td>
                            <span className="text-success">-15</span>
                          </td>
                          <td>2</td>
                          <td>5</td>
                        </tr>
                      </tbody>
                      {/*end::Table body*/}
                    </table>
                    {/*end::Table*/}
                  </div>
                </div>
                {/*end::Tab pane*/}
              </div>
            </div>
          </div>
        </div>
        {/*begin::Col*/}
      </div>
    </div>
  );
}
