import { message, Select, Skeleton, Statistic, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import RangePickerCF from "../../../Components/datepicker";
import MarketplaceSelect from "../../../Components/marketplace";
import { MakeApiCall } from "../../../apis";
import { ConvertParams, FormatNumber } from "../../../config";
import { CFTooltip } from "../../../Components/tooltip";
import Table1 from "../../../Components/tables/table1";
import { TableLoading } from "../../../Components/table-animation";
import CountUp from "react-countup";

const { Text } = Typography;

const formatterNumber = (value) => {
  const formattedString = value;
  const normalizedString = formattedString.replace(/,/g, ""); // Remove commas
  const number = parseFloat(normalizedString);
  // Format the number with commas and two decimal places
  return number;
};

const formatter = (value) => (
  <CountUp
    end={formatterNumber(value)}
    separator=","
    decimals={formatterNumber(value)?.toString().split(".")[1]?.length ? 2 : 0}
  />
);

export default function Variance({ userData }) {
  const sign = "$";

  const [cardsData, setCardsData] = useState({});

  const [tableData, setTableData] = useState([]);
  const [tableSummaryData, setTableSummaryData] = useState({});
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [filtersList, setFiltersList] = useState({});

  const [selectedFilters, setSelectedFilters] = useState({
    marketplace_id: null,
    brand_id: null,
    product_line_id: null,
    sku: null,
    asin: null,
    start_date: dayjs().add(-6, "d").subtract(1, "d").format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    period_start_date: dayjs()
      .add(-15, "d")
      .subtract(2, "d")
      .format("YYYY-MM-DD"),
    period_end_date: dayjs().add(-7, "d").subtract(1, "d").format("YYYY-MM-DD"),
  });

  const [loaders, setLoaders] = useState({
    marketplace: true,
    filters: true,
    cards: true,
    table: true,
  });
  const userSettingFilter = async (data) => {
    try {
      setFiltersList({});

      setLoaders((prev) => ({
        ...prev,
        filters: true,
      }));
      const {
        start_date,
        end_date,
        period_start_date,
        period_end_date,

        ...rest
      } = data;
      const response = await MakeApiCall(
        `user-setting/filters${ConvertParams(rest)}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setFiltersList(response?.data);
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
      } else {
        setFiltersList({});
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setFiltersList({});
      setLoaders((prev) => ({
        ...prev,
        filters: false,
      }));
    }
  };
  const VarianceCardData = async (data) => {
    try {
      setLoaders((prev) => ({
        ...prev,
        cards: true,
      }));
      const response = await MakeApiCall(
        `variance-card-data${ConvertParams(data)}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setCardsData(response?.data || {});
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
      } else {
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setLoaders((prev) => ({
        ...prev,
        cards: false,
      }));
    }
  };
  const VarianceTableDataData = async (data) => {
    try {
      setLoaders((prev) => ({
        ...prev,
        table: true,
      }));
      const response = await MakeApiCall(
        `variance-table-data${ConvertParams({
          ...data,
          page: data?.page || currentPage || 1,
          "page-size": data?.["page-size"] || pageSize || 10,
        })}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.table_data || []);
        setTableSummaryData(response?.data?.total_sum_table || {});
        setLoaders((prev) => ({
          ...prev,
          table: false,
        }));
      } else {
        setLoaders((prev) => ({
          ...prev,
          table: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setLoaders((prev) => ({
        ...prev,
        table: false,
      }));
    }
  };

  const callAfterMarketPlace = async (marketplace_id) => {
    try {
      await userSettingFilter({ marketplace_id });
      await VarianceCardData({ ...selectedFilters, marketplace_id });
      await VarianceTableDataData({ ...selectedFilters, marketplace_id });
    } catch (error) {
      message.warning(error?.message);
    }
  };

  const MassFire = async (data) => {
    try {
      await VarianceCardData({ ...data });
      await VarianceTableDataData({ ...data });
    } catch (error) {
      console.error("Error in MassFire:", error);
    }
  };
  const FilterUpdate = (data) => {
    userSettingFilter(data);
  };

  const cardList = [
    {
      title: "Sales Variance",
      prefix: sign,
      key: "sales_variance",
      icon: "ki-chart-simple-3",
    },
    {
      title: "Sales Change",
      key: "sales_change",
      icon: "ki-percentage",
    },
    {
      title: "Units Variance",
      prefix: sign,
      key: "units_variance",
      icon: "ki-delivery-3",
    },
    {
      title: "Unit Change",
      key: "units_change",
      icon: "ki-percentage",
    },
    {
      title: "Ads Variance",
      prefix: sign,
      key: "adv_sales_variance",
      icon: "ki-monitor-mobile",
    },
    {
      title: "Ads Change",
      key: "adv_sales_change",
      prefix: sign,
      icon: "ki-percentage",
    },
    {
      title: "Buy Box Variance",
      key: "buybox_variance",
      suffix: "%",
      icon: "ki-award",
    },
    {
      title: "Buy Box Change",
      key: "buybox_change",
      suffix: "%",
      icon: "ki-percentage",
    },
  ];

  const columns = [
    {
      title: "Item", // footer
      fixed: "left",
      width: 200,

      render: (e) => {
        return (
          <div>
            <a href className="fw-bolder fs-6 text-gray-800">
              <CFTooltip placement={"right"} row={1} rule>
                {e?.product_name || "-"}
              </CFTooltip>
            </a>
            <div className="fs-7 mt-1 text-gray-600">
              ASIN: <b>{e?.asin || "-"}</b>
            </div>
          </div>
        );
      },
    },
    {
      title: "Brand",
      fixed: "left",
      width: 180,

      render: (e) => {
        return (
          <div>
            <a href className="fw-bold fs-6">
              {e?.brand_name || "-"}
            </a>
          </div>
        );
      },
    },
    {
      title: `Sales`,
      // width: 300,
      children: [
        {
          title: "Current Period",
          align: "left",
          width: 160,
          sorter: (a, b) => {
            const aValue = a?.item_price?.current_value || 0;
            const bValue = b?.item_price?.current_value || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return (
              <>
                {`$${FormatNumber(e?.item_price?.current_value || 0)}`.replace(
                  "$-",
                  "-$"
                )}
              </>
            );
          },
        },
        {
          title: "Previous Period",
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.item_price?.previous_value || 0;
            const bValue = b?.item_price?.previous_value || 0;
            return aValue - bValue;
          },
          width: 160,
          render: (e) => {
            return (
              <>
                {`$${FormatNumber(e?.item_price?.previous_value || 0)}`.replace(
                  "$-",
                  "-$"
                )}
              </>
            );
          },
        },
        {
          title: "Variance",
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.item_price?.variance || 0;
            const bValue = b?.item_price?.variance || 0;
            return aValue - bValue;
          },
          width: 160,
          render: (e) => {
            return (
              <div>
                {`$${FormatNumber(e?.item_price?.variance || 0)}`.replace(
                  "$-",
                  "-$"
                )}
                <div className="h-5px mx-0 mt-2 w-100 bg-light-success rounded">
                  <div
                    className="bg-success rounded h-5px"
                    role="progressbar"
                    style={{ width: "72%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            );
          },
        },
      ],
    },
    {
      title: `Units`,
      // width: 300,
      children: [
        {
          title: "Current Period",
          width: 160,
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.quantity?.current_value || 0;
            const bValue = b?.quantity?.current_value || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return <>{FormatNumber(e?.quantity?.current_value || 0)}</>;
          },
        },
        {
          title: "Previous Period",
          width: 160,
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.quantity?.previous_value || 0;
            const bValue = b?.quantity?.previous_value || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return <>{FormatNumber(e?.quantity?.previous_value || 0)}</>;
          },
        },
        {
          title: "Variance",
          width: 160,
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.quantity?.variance || 0;
            const bValue = b?.quantity?.variance || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return (
              <div>
                {FormatNumber(e?.quantity?.variance || 0)}
                <div className="h-5px mx-0 mt-2 w-100 bg-light-success rounded">
                  <div
                    className="bg-success rounded h-5px"
                    role="progressbar"
                    style={{ width: "72%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            );
          },
        },
      ],
    },
    {
      title: `Buy Box`,
      // width: 300,
      children: [
        {
          title: "Current Period",
          width: 160,
          align: "left",

          sorter: (a, b) => {
            const aValue = a?.buy_box_percentage?.current_value || 0;
            const bValue = b?.buy_box_percentage?.current_value || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return (
              <>{FormatNumber(e?.buy_box_percentage?.current_value || 0)}%</>
            );
          },
        },
        {
          title: "Previous Period",
          width: 160,
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.buy_box_percentage?.previous_value || 0;
            const bValue = b?.buy_box_percentage?.previous_value || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return (
              <>{FormatNumber(e?.buy_box_percentage?.previous_value || 0)}%</>
            );
          },
        },
        {
          title: "Variance",
          width: 160,
          align: "left",
          sorter: (a, b) => {
            const aValue = a?.buy_box_percentage?.variance || 0;
            const bValue = b?.buy_box_percentage?.variance || 0;
            return aValue - bValue;
          },
          render: (e) => {
            return (
              <div>
                {FormatNumber(e?.buy_box_percentage?.variance || 0)}%
                <div className="h-5px mx-0 mt-2 w-100 bg-light-success rounded">
                  <div
                    className="bg-success rounded h-5px"
                    role="progressbar"
                    style={{ width: "72%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            );
          },
        },
      ],
    },
  ];

  const MyTableSummaryRow = () => (
    <Table.Summary.Row className="custom-summary-row">
      <Table.Summary.Cell align="left" colSpan={2} index={0}>
        Total
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          ${FormatNumber(tableSummaryData?.item_price?.current_value || 0)}
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          ${FormatNumber(tableSummaryData?.item_price?.previous_value || 0)}
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          ${FormatNumber(tableSummaryData?.item_price?.variance || 0)}
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          {FormatNumber(tableSummaryData?.quantity?.current_value || 0)}
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          {FormatNumber(tableSummaryData?.quantity?.previous_value || 0)}
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>{FormatNumber(tableSummaryData?.quantity?.variance || 0)}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          {FormatNumber(
            tableSummaryData?.buy_box_percentage?.current_value || 0
          )}
          %
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          {FormatNumber(
            tableSummaryData?.buy_box_percentage?.previous_value || 0
          )}
          %
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell align="left">
        <Text>
          {FormatNumber(tableSummaryData?.buy_box_percentage?.variance || 0)}%
        </Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

  const salesCards = [
    {
      title: "Movers (Sales)",
      key: "sales_mover_data",
      prefix: sign,
      suffix: "",
    },
    {
      title: "Shakers (Sales)",
      key: "sales_shaker_data",
      prefix: sign,
      suffix: "",
    },
    {
      title: "Movers (Units)",
      key: "units_mover_data",
      prefix: "",
      suffix: "%",
    },
    {
      title: "Shakers (Units)",
      key: "units_shaker_data",
      prefix: "",
      suffix: "%",
    },
  ];

  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <MarketplaceSelect
            showSearch
            callAfterMarketPlace={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };

              callAfterMarketPlace(e);
              setSelectedFilters(obj);
            }}
            MarketplaceData={(e) => {
              setLoaders({
                ...loaders,
                marketplace: false,
              });

              const obj = {
                ...selectedFilters,
                marketplace_id: e?.[0]?.value,
              };

              setSelectedFilters(obj);

              setMarketplaceList(e);
            }}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };
              MassFire(obj);
              FilterUpdate(obj);
              setSelectedFilters(obj);
            }}
            userData={userData}
          />
        </div>
        <div className="position-relative">
          <RangePickerCF
            size="large"
            style={{ width: "240px" }}
            needConfirm
            allowClear={false}
            id={Math.random()}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                start_date: dayjs(e[0]).format("YYYY-MM-DD"),
                end_date: dayjs(e[1]).format("YYYY-MM-DD"),
              };
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
            value={[
              dayjs(selectedFilters?.start_date, "YYYY-MM-DD"),
              dayjs(selectedFilters?.end_date, "YYYY-MM-DD"),
            ]}
          />
        </div>
        <div className="position-relative">
          <RangePickerCF
            size="large"
            style={{ width: "240px" }}
            needConfirm
            allowClear={false}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                period_start_date: dayjs(e[0]).format("YYYY-MM-DD"),
                period_end_date: dayjs(e[1]).format("YYYY-MM-DD"),
              };
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
            value={[
              dayjs(selectedFilters?.period_start_date, "YYYY-MM-DD"),
              dayjs(selectedFilters?.period_end_date, "YYYY-MM-DD"),
            ]}
            id={Math.random()}
          />
        </div>
        <div className="position-relative">
          <Select
            showSearch
            size="large"
            optionFilterProp="value"
            className="w-150px"
            placeholder="Select ASIN"
            options={filtersList?.asin}
            allowClear
            loading={loaders?.filters}
            value={selectedFilters?.asin}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                asin: e,
              };
              MassFire({ ...obj });

              FilterUpdate(obj);
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            showSearch
            className="w-200px"
            optionFilterProp="value"
            placeholder="Select Product Line"
            options={filtersList?.productLine}
            allowClear
            loading={loaders?.filters}
            value={selectedFilters?.product_line_id}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                product_line_id: e,
              };
              FilterUpdate(obj);
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            className="w-275px"
            placeholder="Select Product"
            optionFilterProp="value"
            options={filtersList?.product?.map((d) => ({
              ...d,
              label: (
                <div
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    wordBreak: "break-all",
                    overflowWrap: "breakWord",
                    hyphens: "auto",
                    paddingBottom: "7px",
                    borderBottom: "1px solid #adadad",
                  }}
                >
                  <div>{d?.label}</div>
                </div>
              ),
            }))}
            allowClear
            showSearch
            loading={loaders?.filters}
            value={selectedFilters?.product}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                product: e,
              };
              FilterUpdate(obj);
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            optionFilterProp="value"
            showSearch
            placeholder="Select Brand"
            options={filtersList?.brand?.map((d) => ({
              ...d,
              label: (
                <div
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    wordBreak: "break-all",
                    overflowWrap: "breakWord",
                    hyphens: "auto",
                  }}
                >
                  <div>{d?.label}</div>
                </div>
              ),
            }))}
            allowClear
            loading={loaders?.filters}
            value={selectedFilters?.brand_id}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                brand_id: e,
              };
              FilterUpdate(obj);
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
      </div>
      <div className="mb-7">
        <h1>Sales Breakdown</h1>
      </div>
      <div className="row g-3 g-lg-6 mb-7">
        {cardList?.map((d, i) => (
          <div className="col" key={i}>
            {/*begin::Items*/}
            <div className="bg-gray-100 bg-cus-grad1 rounded-2 px-6 py-5 position-relative overflow-hidden ">
              <i
                className={`ki-outline ${d?.icon} fs-1 text-gray-100 card-des-icon`}
              />
              {/*begin::Symbol*/}
              <div className="symbol symbol-50px me-5 mb-8">
                <span className="symbol-label bg-white shadow-sm">
                  <i className={`ki-outline ${d?.icon} fs-1 text-primary`} />
                </span>
              </div>
              {/*end::Symbol*/}
              {/*begin::Stats*/}
              <div className="m-0">
                {/*begin::Number*/}
                <span className="text-gray-800 fw-bolder d-block fs-1 lh-1 ls-n1 mb-2">
                  {loaders.cards ? (
                    <Skeleton.Button active size="small" />
                  ) : (
                    <>
                      <Statistic
                        value={FormatNumber(
                          cardsData?.card_data?.[d?.key] || 0
                        )}
                        precision={2}
                        formatter={formatter}
                        valueRender={(e) => (
                          <div className="fs-1 text-gray-800 fw-bolder">
                            {d?.prefix || ""}
                            {e}
                            {d?.suffix || ""}
                          </div>
                        )}
                      />
                    </>
                  )}
                </span>
                {/*end::Number*/}
                {/*begin::Desc*/}
                <span className="text-gray-700 fw-semibold fs-6">
                  {loaders?.cards ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    d?.title
                  )}
                </span>
                {/*end::Desc*/}
              </div>
              {/*end::Stats*/}
            </div>
            {/*end::Items*/}
          </div>
        ))}
      </div>
      <div className="row gx-5 gx-xl-7">
        {salesCards?.map((d, i) => (
          <div key={i} className="col-xl-3 mb-5 mb-xl-5">
            <div className="card card-flush h-xl-100 mb-5">
              <div className="card-header min-h-50px mb-0">
                <h3 className="card-title pt-0 align-items-start flex-column">
                  {loaders?.cards ? (
                    <>
                      <Skeleton.Input active size="small" />
                    </>
                  ) : (
                    <span className="card-label fw-bold text-gray-800">
                      {d?.title || "-"}
                    </span>
                  )}
                </h3>
                <div className="card-toolbar"></div>
              </div>
              <div className="card-body pt-0 pb-0">
                <Table
                  columns={[
                    {
                      title: "Item",
                      className: "min-w-175px",
                      render: (d) => {
                        return (
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                border: "1px solid #cacaca",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "9px",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                width={35}
                                height={35}
                                style={{ borderRadius: "7px" }}
                                loading="lazy"
                                src={d?.image_url}
                              />
                            </div>
                            <div className="mw-150px">
                              <a href className="fw-bolder fs-7  text-gray-800">
                                <CFTooltip row={1} rule>
                                  {d?.product_name || "-"}
                                </CFTooltip>
                              </a>
                              <div className="d-flex fs-7 mt-1 text-gray-600">
                                ASIN:{" "}
                                <b className="fw-bold">{d?.asin || "-"}</b>
                              </div>
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Variance",

                      sorter: (a, b) =>
                        parseFloat(a?.item_price?.variance) -
                        parseFloat(b?.item_price?.variance),
                      align: "right",
                      render: (e) => {
                        return (
                          <div
                            className="d-grid"
                            style={{ justifyItems: "end" }}
                          >
                            <div className="fw-bolder">
                              {`${d?.prefix || ""}${FormatNumber(
                                e?.item_price?.variance || 0
                              )}${d?.suffix || ""}`?.replace(
                                `${d?.prefix || ""}-`,
                                `-${d?.prefix || ""}`
                              )}
                            </div>
                            <div className="h-5px mx-0 mt-2 w-100 bg-light-success rounded">
                              <div
                                className="bg-success rounded h-5px"
                                role="progressbar"
                                style={{ width: "72%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={cardsData?.box_data?.[d?.key]}
                  pagination={false}
                  size="small"
                  scroll={{ y: 300, x: "max-content" }}
                  loading={loaders?.cards}
                />
                <div className="d-none table-responsive hover-scroll-x h-300px ">
                  <table className="table align-middle table-row-dashed border-gray-300 table-row-gray-300 gy-3 gx-4 gs-0 mb-0">
                    <thead>
                      <tr className="fw-bolder text-gray-800 bg-white">
                        <th className="min-w-175px">Item</th>
                        <th className="min-w-100px text-end">Variance</th>
                      </tr>
                    </thead>

                    <tbody className="">
                      {loaders?.cards ? (
                        <>
                          {Array(20)
                            .fill(1)
                            ?.map((_) => (
                              <tr>
                                <td>
                                  <div className="mt-2">
                                    <Skeleton
                                      paragraph={{
                                        rows: 1,
                                      }}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="text-end">
                                    <Skeleton.Button active />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        cardsData?.box_data?.[d?.key]?.map((s, si) => (
                          <tr key={si}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  width={35}
                                  height={35}
                                  className="me-3"
                                  loading="lazy"
                                  src={s?.image_url}
                                />
                                <div>
                                  <a
                                    href
                                    className="fw-bolder fs-7 text-gray-800"
                                  >
                                    <CFTooltip row={1} rule>
                                      {s?.product_name || "-"}
                                    </CFTooltip>
                                  </a>
                                  <div className="fs-7 mt-1 text-gray-600">
                                    ASIN:{" "}
                                    <b className="fw-bold">{s?.asin || "-"}</b>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="text-end">
                                <div className="fw-bolder">
                                  {`${d?.prefix || ""}${FormatNumber(
                                    s?.item_price?.variance || 0
                                  )}${d?.suffix || ""}`?.replace(
                                    `${d?.prefix || ""}-`,
                                    `-${d?.prefix || ""}`
                                  )}
                                </div>
                                <div className="h-5px mx-0 mt-2 w-100 bg-light-success rounded">
                                  <div
                                    className="bg-success rounded h-5px"
                                    role="progressbar"
                                    style={{ width: "72%" }}
                                    aria-valuenow={50}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row gx-5 gx-xl-7 mb-7">
        <div className="col-lg-12">
          <div className="card card-flush h-xl-100">
            {/*begin::Body*/}
            <div className="card-body pt-2">
              {loaders?.table ? (
                <TableLoading
                  id="test-table"
                  row={10}
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={false}
                  actions={[]}
                  style={{ height: "calc(100vh - 500px)", overflow: "hidden" }}
                />
              ) : (
                <Table1
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: "max-content" }}
                  virtual
                  summary={MyTableSummaryRow}
                  pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    total: totalPage,
                    onChange: (e, f) => {
                      setPageSize(f);
                      setCurrentPage(e);
                      VarianceTableDataData({
                        ...selectedFilters,
                        page: e,
                        "page-size": f,
                      });
                    },
                    showSizeChanger: true, // To show page size options
                    pageSizeOptions: [10, 20, 50, 100], // Page size options
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  className="custom-pagination-table"
                />
              )}
            </div>
          </div>
        </div>
        {/*begin::Col*/}
      </div>
    </div>
  );
}
