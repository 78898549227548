import { Modal, Card, Table, Input, Button, message, Badge, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { Link } from "react-router-dom";
const PrepLogModal = ({ visible, view, onCancel, userData }) => {
  const [loading, setLoading] = useState(false);
  const [dataUpdate, setDataUpdate] = useState([]);
  const [loadingAction, setLoadingAction] = useState(false);

  const Replenishment_qty = (row) => {
    if (row.replenishment_qty === "0") {
      if (row.salesSum && row.salesSum.replenishment_QTY) {
        return parseInt(row.salesSum.replenishment_QTY);
      }
    } else {
      return row.replenishment_qty;
    }
    return 0;
  };

  const GetComponentsList = async (ids) => {
    try {
      const response = await MakeApiCall(
        `get-components-list`,
        "post",
        {
          selectedIds: ids.map((d) => d.fald_id),
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const dataUpdateSet = [];
        view.forEach((ee) => {
          const index = Object.keys(response.data.linkedItemsList).findIndex(
            (d) => parseInt(d) === parseInt(ee.fald_id)
          );
          if (index !== -1) {
            dataUpdateSet.push({
              ...ee,
              quantityUpdate: Replenishment_qty(ee),
              componentData: Object.values(response.data.linkedItemsList)[
                index
              ],
            });
          } else {
            dataUpdateSet.push({
              ...ee,
              quantityUpdate: ee.replenishment_qty,
              componentData: [],
            });
          }
        });
        setDataUpdate(dataUpdateSet);
        setLoading(false);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    if (visible) {
      if (view?.filter((d) => d.isLinked === true).length !== 0) {
        setLoading(true);
        GetComponentsList(view.filter((d) => d.isLinked));
      } else {
        setDataUpdate(view);
        setLoading(false);
      }
    }
    return () => {
      message.destroy();
    };
  }, [visible]);

  const updateQuantity = (e, row) => {
    const dataUpdateSet = dataUpdate;
    const index = dataUpdateSet.findIndex((d) => d.fald_id === row.fald_id);
    if (index !== -1) {
      dataUpdateSet[index].quantityUpdate = e;
      setDataUpdate([...dataUpdateSet]);
    }
  };

  const columns = [
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e, d) => {
        return <span>{d.asin1 || d.fbaAllListing.asin1 || null}</span>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (e, d) => {
        return (
          <>
            <div
              class="item_description"
              style={{
                borderBottom: "1px solid #808080b0",
                margin: "10px 0px",
              }}
            >
              {d.item_name ||
                d.fbaAllListing.item_name ||
                "no item description"}
            </div>
            {d.componentData && d.componentData.length > 0
              ? d.componentData.map((r, i) => (
                  <div class="label-gap" key={i}>
                    <span class="">
                      {i + 1} {r.componentData.item_description}
                    </span>
                  </div>
                ))
              : "Not Defined"}
          </>
        );
      },
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text, d) => (
        <>
          <Link
            to={
              d.isLinked === false &&
              `/link-items?searchKeyword=${
                d.asin1 || d.fbaAllListing.asin1 || null
              }`
            }
          >
            <Tag
              color={d.isLinked ? "blue-inverse" : "blue"}
              style={{ cursor: "pointer" }}
            >
              {d.isLinked ? "Linked" : "Link"}
            </Tag>
          </Link>
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, d) => (
        <Input
          type="number"
          class="component_qty"
          value={d.quantityUpdate || ""}
          name="quantity"
          onChange={(e) => {
            updateQuantity(e.target.value, d);
          }}
        />
      ),
    },
  ];

  const submitPrep = async () => {
    const index = dataUpdate.findIndex((d) => d.isLinked === false);
    if (index !== -1) {
      message.destroy();
      message.warning("All Asin Should Be Linked up With Component.");
      return;
    }
    try {
      const data = [];
      dataUpdate.forEach((e) => {
        if (
          e.quantityUpdate &&
          e.quantityUpdate !== undefined &&
          e.quantityUpdate !== null &&
          parseInt(parseFloat(e.quantityUpdate).toFixed(0)) !== 0
        ) {
          data.push({ [e.fald_id]: parseInt(e.quantityUpdate) });
        }
      });

      if (dataUpdate.length === data.length) {
        var result = {};
        for (var i = 0; i < data.length; i++) {
          result[Object.entries(data[i])[0][0]] = Object.entries(data[i])[0][1];
        }
        setLoadingAction(true);

        const response = await MakeApiCall(
          `create-prep-log`,
          "post",
          { quantity: result },
          true,
          {},
          userData?.user?.auth_token
        );
        if (response?.status) {
          const link = document.createElement("a");
          link.href = response.data.file;
          link.click();
          message.destroy();
          setLoadingAction(false);
          onCancel();
          message.success(response?.message);
        } else {
          message.destroy();
          setLoadingAction(false);
          message.warning(response?.message);
        }
      } else {
        message.destroy();
        setLoadingAction(false);
        message.warning("Please provide a quantity");
      }
    } catch (error) {
      message.destroy();
      setLoadingAction(false);
      message.error(error?.message);
    }
  };

  return (
    <Modal
      title="Create Prep Log"
      width={800}
      visible={visible}
      onCancel={() => {
        onCancel();
        message.destroy();
      }}
      footer={[
        <Button
          loading={loadingAction}
          onClick={submitPrep}
          key="export"
          type="primary"
        >
          Export
        </Button>,
      ]}
    >
      <Card>
        <Table
          columns={columns}
          loading={loading}
          dataSource={dataUpdate}
          pagination={false}
          style={{ marginTop: "16px" }}
        />
      </Card>
    </Modal>
  );
};

export default PrepLogModal;
