import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Divider,
  Flex,
  Input,
  Tag,
  message,
  Radio,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
  Tooltip,
  Form,
} from "antd";
import { useLocation } from "react-router-dom";
import { ConvertParams, FormatNumber } from "../../../config";
import { MakeApiCall } from "../../../apis";
import { Icon } from "@iconify/react/dist/iconify.js";
import { SearchOutlined } from "@ant-design/icons";
import GetUnitCostHistory from "../item-master/lib/components-lib/add-history";
import AddHistoryModal from "../item-master/lib/components-lib/add-history-plus";
import ComponentList from "./component-list";

const LinkItems = ({
  userData,
  asin,
  id,
  record,
  expandView = false,
  getList,
  onClose,
}) => {
  const [filter, setFilter] = useState({
    itemStatus: "1",
    searchKeyword: asin,
  });
  const [itemList, setItemList] = useState([]);
  const [itemLoading, setItemLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [componentList, setComponentList] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedComponents, setSelectedComponents] = useState([]);
  const [pageView, setPageView] = useState(true);
  const [linkedLoading, setLinkedLoading] = useState(true);

  const [brandList, setBrandList] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [form] = Form.useForm();
  const [historyType, setHistoryType] = useState("view");
  const [selectedRow, setSelectedRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unitCostHistory, setUnitCostHistory] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [linkComponentModal, setLinkComponentModal] = useState(false);

  const getItemComponents = async (id) => {
    setComponentLoading(true);
    setComponentList([]);
    try {
      const response = await MakeApiCall(
        `get-item-components`,
        "post",
        {
          item_id: id,
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setComponentLoading(false);
        const selectedRows =
          response?.data?.componentList?.filter((d) =>
            response?.data?.linkedComponentList
              ?.map((d) => d?.components_id)
              ?.includes(d?.id)
          ) || [];
        setComponentList(response?.data?.componentList || []);
        setSelectedRowKeys(
          response?.data?.componentList
            ?.filter((d) => selectedRows?.map((d) => d?.id)?.includes(d?.id))
            ?.map((d) => ({ ...d, save: true }))
        );
      } else {
        setComponentLoading(false);
        message.destroy();
        setComponentList([]);
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setComponentList([]);
      setComponentLoading(false);
      message.error(error?.message);
    }
  };

  const ItemSearchAction = async (data) => {
    setItemLoading(true);
    setItemList([]);
    try {
      const response = await MakeApiCall(
        `item-search`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setItemLoading(false);
        setItemList(response?.data || []);
        if (response?.data?.length > 0) {
          setComponentLoading(true);
          getItemComponents(response?.data?.[0]?.id);
          setSelectedId(response?.data?.[0]?.id);
        } else {
          setComponentLoading(false);
          setComponentList([]);
        }
      } else {
        setItemLoading(false);
        setItemList([]);
        setSelectedId(null);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setItemList([]);
      setItemLoading(false);
      setSelectedId(null);
      message.destroy();
      message.error(error?.message);
    }
  };
  const ItemComponentSelectedAction = async () => {
    setSelectedComponents([]);
    setLinkedLoading(true);
    try {
      const response = await MakeApiCall(
        `get-components-list`,
        "post",
        {
          selectedIds: [id],
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setLinkedLoading(false);
        setSelectedComponents(
          Object.values(response.data.linkedItemsList)[0]?.map((d) => ({
            ...d?.componentData,
          }))
        );
      } else {
        setLinkedLoading(false);
        setSelectedComponents([]);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setLinkedLoading(false);
      setSelectedComponents([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const LinkComponents = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `link-components`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
        setComponentLoading(true);
        getItemComponents(id);
      } else {
        setSubmitLoading(false);

        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);

      message.destroy();
      message.error(error?.message);
    }
  };

  const GetBrandListAction = async () => {
    setBrandLoading(true);
    setBrandList([]);
    try {
      const response = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setBrandList(response?.data?.records || []);

        setBrandLoading(false);
      } else {
        setBrandList([]);
        message.destroy();
        setBrandLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setBrandList([]);
      setBrandLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetVendorListAction = async () => {
    setVendorLoading(true);
    setVendorList([]);
    try {
      const response = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setVendorList(response?.data?.records || []);
        setVendorLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setVendorLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorLoading(false);
      setVendorList([]);
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    GetBrandListAction();
    GetVendorListAction();
    ItemComponentSelectedAction();
    if (filter?.searchKeyword) {
      ItemSearchAction(filter);
    } else {
      setSubmitLoading(false);
      setItemLoading(false);
      setComponentLoading(false);
    }
    return () => {};
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onPressEnter={() => {
            const findRow = componentList?.filter(
              (d) => d?.component_id === searchText
            );

            const index = selectedRowKeys?.findIndex(
              (d) => d?.component_id === searchText
            );
            if (findRow?.length === 0) {
              message.destroy();
              message.warning("Item not found");
              return;
            }
            if (index !== -1) {
              message.destroy();
              message.warning("Item already added");
              return;
            }
            if (findRow?.length > 0 && index === -1) {
              setSelectedRowKeys([
                ...selectedRowKeys,
                { ...findRow[0], save: false },
              ]);
              setSearchText(null);
            }
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            onClick={() => {
              const findRow = componentList?.filter(
                (d) => d?.component_id === searchText
              );

              const index = selectedRowKeys?.findIndex(
                (d) => d?.component_id === searchText
              );
              if (findRow?.length === 0) {
                message.destroy();
                message.warning("Item not found");
                return;
              }
              if (index !== -1) {
                message.destroy();
                message.warning("Item already added");
                return;
              }
              if (findRow?.length > 0 && index === -1) {
                setSelectedRowKeys([
                  ...selectedRowKeys,
                  { ...findRow[0], save: false },
                ]);
                setSearchText(null);
              } else {
              }
            }}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
      // ...getColumnSearchProps("component_id"),
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "Brand",
      dataIndex: "brand_id",
      key: "brand_id",
    },
    {
      title: "Vendor SKU",
      dataIndex: "vendor_sku",
      key: "vendor_sku",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (text) => `$${FormatNumber(parseFloat(text).toFixed(2))}`,
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 160,
      render: (e, row) => {
        if (row?.save) {
          return e;
        }
        return (
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Input
              type="number"
              value={e}
              autoFocus
              onChange={(e) => {
                const val = parseInt(e.target.value);
                setComponentList((prev) => {
                  return prev?.map((d) => {
                    if (d?.id === row?.id) {
                      return {
                        ...d,
                        quantity: val,
                      };
                    } else {
                      return { ...d };
                    }
                  });
                });
                setSelectedRowKeys((prev) => {
                  return prev?.map((d) => {
                    if (d?.component_id === row?.component_id) {
                      return {
                        ...d,
                        quantity: val,
                      };
                    } else {
                      return { ...d };
                    }
                  });
                });
              }}
            />
            <Button
              loading={submitLoading}
              onClick={() => {
                const obj = {
                  item_id: id,
                  link_components: selectedRowKeys?.map((d) => ({
                    component_id: d?.id,
                    quantity: d?.quantity,
                  })),
                };
                LinkComponents(obj);
              }}
              type="primary"
            >
              Save
            </Button>
          </Space.Compact>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const columnsSelectComponent = [
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
      render: (e) => {
        return <Tag color="blue">{e || "-"}</Tag>;
      },
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Quantity",
      dataIndex: "lic_quantity",
      key: "lic_quantity",
      render: (e) => {
        return (
          <Flex gap={5}>
            <Typography.Text>{FormatNumber(e || 0)}</Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "MOQ",
      dataIndex: "ic_moq",
      key: "ic_moq",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (e) => {
        return (
          <Flex gap={5}>
            <Typography.Text>${FormatNumber(e || 0)}</Typography.Text>
          </Flex>
        );
      },
    },

    {
      title: "Unit Cost History",
      dataIndex: "unitCostHistory",
      key: "unitCostHistory",
      render: (_, e) => {
        return (
          <Flex gap={5}>
            <Button
              color="default"
              className="p-2 py-4"
              variant="filled"
              size="small"
              onClick={() => {
                setUnitCostHistory(true);
                setSelectedRow(e);
                setHistoryType("view");
              }}
            >
              <Icon width={20} icon="material-symbols:history" />
            </Button>
            <Button
              size="small"
              color="primary"
              className="p-2 py-4"
              variant="filled"
              onClick={() => {
                setUnitCostHistory(true);
                setSelectedRow(e);
                setHistoryType("edit");
              }}
            >
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
            <Button
              size="small"
              color="primary"
              className="p-2 py-4"
              variant="solid"
              onClick={() => {
                showModal();
                setSelectedRow(e);
                setHistoryType("edit");
              }}
            >
              <Icon width={20} icon="ic:baseline-plus" />
            </Button>
          </Flex>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: ["brands", "b_name"],
      key: ["brands", "b_name"],
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Vendor",
      dataIndex: ["vendors", "name"],
      key: ["vendors", "name"],
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Vendor SKU",
      dataIndex: "vendor_sku",
      key: "vendor_sku",
    },
    {
      title: (
        <Flex align="center" gap={10}>
          <span>Item Note</span>{" "}
          {/* <Tooltip placement="left" title="Configure Items">
            <Button
              variant="solid"
              size="small"
              color="default"
              onClick={() => setPageView(true)}
            >
              <Icon width={16} icon="lets-icons:setting-fill" />
            </Button>
          </Tooltip> */}
        </Flex>
      ),
      dataIndex: "note",
      render: (e) => {
        return e || "-";
      },
      key: "note",
    },
  ];

  return (
    <Wrapper>
      <div className="card-body p-0">
        {!expandView ? (
          <>
            <div className="d-none align-items-center justify-content-between  mb-2 mt-3">
              <div
                className="d-flex gap-2 align-items-center "
                style={{ background: "#FFF", borderRadius: "14px" }}
              >
                <Input
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      searchKeyword: e.target.value,
                    });
                  }}
                  onPressEnter={() => {
                    ItemSearchAction({
                      ...filter,
                    });
                  }}
                  value={filter?.searchKeyword}
                  placeholder="Search"
                  className="me-3"
                />
                <Tabs
                  activeKey={filter?.itemStatus || "1"}
                  type="line"
                  tabBarStyle={{ marginBottom: 0 }}
                  onChange={(value) => {
                    setFilter({
                      ...filter,
                      itemStatus: value,
                    });
                    ItemSearchAction({
                      ...filter,
                      itemStatus: value,
                    });
                  }}
                  size="small"
                  items={[
                    {
                      key: "1",
                      label: <b style={{ fontSize: "14px" }}>Linked</b>,
                    },
                    {
                      key: "2",
                      label: <b style={{ fontSize: "14px" }}>Unlinked</b>,
                    },
                    {
                      key: "3",
                      label: <b style={{ fontSize: "14px" }}>All</b>,
                    },
                  ]}
                />
              </div>
              <div>
                <Button
                  loading={submitLoading}
                  onClick={() => {
                    LinkComponents({
                      item_id: selectedId,
                      link_components: selectedRowKeys?.map((d) => ({
                        component_id: d?.id,
                        quantity: d?.quantity,
                      })),
                    });
                  }}
                  // size="small"
                  className="me-3"
                  // type="primary"
                  variant="solid"
                  color="default"
                >
                  Save {!expandView ? " & Exit" : ""}
                </Button>
                {/* <Button
                  onClick={() => setPageView(false)}
                  className="me-3"
                  variant="link"
                  color="danger"
                  size="small"
                >
                  <Icon icon="akar-icons:cross" />
                </Button> */}
              </div>
            </div>

            {/* {itemList?.length > 0 && (
              <div className="card p-5">
                <Spin spinning={itemLoading}>
                  <Radio.Group
                    value={selectedId}
                    onChange={(e) => {
                      getItemComponents(e.target.value);
                      setSelectedId(e.target.value);
                    }}
                  >
                    <Space direction="vertical">
                      {itemList?.map((d) => (
                        <Radio className="mb-1" value={d?.id}>
                          <div>
                            <a style={{ color: "#0179af" }}>{d?.fbaitem}</a>
                            {d?.item_name}
                          </div>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Spin>
              </div>
            )} */}

            <div className="card mt-3 p-5 pt-0">
              <div className="card-header px-0" style={{ minHeight: "45px" }}>
                <div className="card-title">Component List</div>
                <div className="card-toolbar">
                  <Button
                    onClick={() => {
                      setLinkComponentModal(true);
                    }}
                    variant="filled"
                    color="primary"
                  >
                    Link Component
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={selectedRowKeys}
                loading={componentLoading}
                rowKey={"id"}
                pagination={false}
                scroll={{ x: "max-content" }}
              />
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  background: "#FFF",
                }}
              >
                <div></div>
              </div>
            </div>
          </>
        ) : (
          <div className="my-4">
            <Table
              columns={columnsSelectComponent}
              dataSource={selectedComponents}
              rowKey={"id"}
              loading={linkedLoading}
              pagination={false}
              scroll={{ x: "max-content" }}
            />
          </div>
        )}
      </div>

      {linkComponentModal && (
        <ComponentList
          show={linkComponentModal}
          selectedComponents={selectedComponents}
          record={record}
          componentList={componentList}
          userData={userData}
          setComponentList={setComponentList}
          close={() => {
            ItemComponentSelectedAction();
            getItemComponents(id);
            setLinkComponentModal(false);
          }}
        />
      )}
      {/* <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ marginBottom: "0" }}
        type="card"
      >
        <Tabs.TabPane tab="Preview" key="1">
          <Table
            columns={columnsSelectComponent}
            dataSource={selectedComponents}
            rowKey={"id"}
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
            }}
            scroll={{ x: "max-content" }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Edit" key="2">
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="d-flex gap-2 align-items-center px-10  mb-3"
                style={{ background: "#FFF", borderRadius: "14px" }}
              >
                <Tabs
                  activeKey={filter?.itemStatus || "1"}
                  type="line"
                  tabBarStyle={{ marginBottom: 0 }}
                  onChange={(value) => {
                    setFilter({
                      ...filter,
                      itemStatus: value,
                    });
                    ItemSearchAction({
                      ...filter,
                      itemStatus: value,
                    });
                  }}
                  size="large"
                  items={[
                    {
                      key: "1",
                      label: <b>Linked</b>,
                    },
                    {
                      key: "2",
                      label: <b>Unlinked</b>,
                    },
                    {
                      key: "3",
                      label: <b>All</b>,
                    },
                  ]}
                />
              </div>
              <div>
                <Button
                  loading={submitLoading}
                  onClick={() => {
                    LinkComponents({
                      item_id: selectedId,
                      link_components: selectedRowKeys?.map((d) => ({
                        component_id: d?.id,
                        quantity: d?.quantity,
                      })),
                    });
                  }}
                  className="me-3"
                  type="primary"
                >
                  Save
                </Button>
              </div>
            </div>

            <div className="card p-5">
              <Spin spinning={itemLoading}>
                <Radio.Group
                  value={selectedId}
                  onChange={(e) => {
                    getItemComponents(e.target.value);
                    setSelectedId(e.target.value);
                  }}
                >
                  <Space direction="vertical">
                    {itemList?.map((d) => (
                      <Radio className="mb-1" value={d?.id}>
                        <div>
                          <a style={{ color: "#0179af" }}>{d?.fbaitem}</a>
                          {d?.item_name}
                        </div>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Spin>
            </div>

            <div className="card mt-3 p-5">
              <Table
                columns={columns}
                dataSource={componentList}
                loading={componentLoading}
                rowKey={"id"}
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false,
                }}
                scroll={{ x: "max-content" }}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
              />
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  background: "#FFF",
                }}
              >
                <div></div>
              </div>
            </div>
          </>
        </Tabs.TabPane>
      </Tabs> */}
      <GetUnitCostHistory
        setIsModalVisible={() => {
          setUnitCostHistory(false);
        }}
        isModalVisible={unitCostHistory}
        form={form}
        vendorList={vendorList}
        userData={userData}
        vendorLoading={vendorLoading}
        setSelectedRow={setSelectedRow}
        historyType={historyType}
        selectedRow={selectedRow}
      />
      <AddHistoryModal
        setIsModalVisible={() => {
          setIsModalVisible(false);
        }}
        isModalVisible={isModalVisible}
        form={form}
        vendorList={vendorList}
        userData={userData}
        vendorLoading={vendorLoading}
        setSelectedRow={setSelectedRow}
        historyType={historyType}
        selectedRow={selectedRow}
      />
    </Wrapper>
  );
};

export default LinkItems;
