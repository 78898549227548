import { Link, Outlet, useNavigate } from "react-router-dom";

export const AdminMenus = [
  {
    label: "Dashboard",
    key: "dashboard",
  },

  {
    label: "Manage User",
    key: "manage-user",
  },
  {
    label: "Financial Summary",
    key: "financial-summary",
  },
];
