import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { MakeApiCall } from "../../../../../apis";

const AddPoReferenceModal = ({ visible, onClose, userData }) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const AddPoReferenceAction = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `add-reference`,
        "POST",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSubmitLoading(false);
        form.resetFields();
        onClose();
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setSubmitLoading(false);
      message.error(error?.message);
    }
  };

  // Submit button handler outside the modal
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        AddPoReferenceAction(values);
      })
      .catch((info) => {});
  };

  return (
    <div>
      {/* Modal with Form */}
      <Modal
        title="Add PO Reference Number"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button type="dashed" onClick={onClose} style={{ marginTop: 16 }}>
            Close
          </Button>,
          <Button
            type="primary"
            loading={submitLoading}
            onClick={handleSubmit}
            style={{ marginTop: 16 }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="po_reference_form">
          <Form.Item
            name="po_reference"
            label="PO Reference Number"
            rules={[
              {
                required: true,
                message: "Please enter the PO Reference Number!",
              },
              {
                pattern: /^[0-9]*$/,
                message: "Only numbers are allowed!",
              },
            ]}
          >
            <Input placeholder="Enter PO Reference Number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddPoReferenceModal;
