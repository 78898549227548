import {
  DatePicker,
  Pagination,
  Select,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { ConvertParams, DefaultPerPage } from "../../../config";
import moment from "moment";
import { CFTooltip } from "../../../Components/tooltip";
import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Table1 from "../../../Components/tables/table1";

export default function CentralLog({ userData }) {
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  const [tableList, setTableList] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const getFilterList = async () => {
    const response = await MakeApiCall(
      `application-logs/filters`,
      "get",
      null,
      true,
      {},
      userData?.user?.auth_token
    );
    if (response?.status) {
      setFilters(response?.data || {});
    } else {
      message.warning(response?.message);
      setFilters([]);
    }
  };

  const getTableList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `application-logs/central-logs${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.user?.auth_token
    );
    if (response?.status) {
      setTableLoading(false);
      setTotalPage(response.data.pagination.totalCount);
      setTableList(response?.data?.records || []);
    } else {
      setTableLoading(false);
      message.warning(response?.message);
      setTableList([]);
    }
  };

  useEffect(() => {
    getTableList({ page: page, perPage: pageSize, ...selectedFilters });
    getFilterList();

    return () => {};
  }, []);

  function convertToCamelCase(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const onPageNo = (e) => {
    setTableLoading(true);
    setTableList([]);
    getTableList({
      page: e,
      perPage: pageSize,
      ...selectedFilters,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setTableLoading(true);
    getTableList({
      page: 1,
      perPage: e,
      ...selectedFilters,
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      width: 100,
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log created",
      width: 250,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Date",
      width: 200,
      render: (item) => {
        return item?.event_date || "-";
      },
    },
    {
      title: "Event Name",
      width: 180,
      // ellipsis: false,
      render: (item) => {
        return (
          <CFTooltip rule>
            <b>{item?.event_name || "N/A"}</b>
          </CFTooltip>
        );
      },
    },
    {
      title: "Event Type",
      width: 150,
      render: (item) => {
        return <b>{item?.event_type || "N/A"}</b>;
      },
    },
    {
      title: "Event Status",
      width: 150,
      render: (item) => {
        return (
          <Tag
            color={
              item.event_status === "PENDING"
                ? "warning"
                : item.event_status === "IN_PROGRESS"
                ? "processing"
                : item.event_status === "DONE"
                ? "success"
                : item.event_status === "FAILED"
                ? "error"
                : item.event_status === "FATAL"
                ? "error"
                : item.event_status === "RULE_APPLIED"
                ? "success"
                : item.event_status === "RULE_NOT_APPLIED"
                ? "warning"
                : item.event_status === "SCHEDULED"
                ? "blue"
                : item.event_status === "CANCELLED"
                ? "error"
                : "error"
            }
            icon={
              item.event_status === "PENDING" ? (
                <ClockCircleOutlined />
              ) : item.event_status === "IN_PROGRESS" ? (
                <SyncOutlined spin />
              ) : item.event_status === "DONE" ||
                item.event_status === "RULE_APPLIED" ||
                item.event_status === "SCHEDULED" ? (
                <CheckCircleOutlined />
              ) : item.event_status === "FAILED" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "FATAL" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "CANCELLED" ? (
                <CloseCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
          >
            {item.event_status || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Priority",
      width: 120,
      render: (item) => {
        return (
          <>
            {item?.priority == 1 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#ff0000" }}
                ></div>
                High
              </div>
            ) : item?.priority == 2 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#faad14" }}
                ></div>
                Medium
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#52c41a" }}
                ></div>
                Low
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Profile ID",
      width: 200,
      render: (item) => {
        return <b>{item?.profile_id || "N/A"}</b>;
      },
    },
    {
      title: "Marketplace",
      width: 200,
      render: (item) => {
        return <b>{item?.marketplace || "N/A"}</b>;
      },
    },
    {
      title: "Updated at",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000)).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      width: 200,
      render: (d) => {
        return (
          <Tag
            color={d.status === 0 ? "processing" : "success"}
            icon={
              (d.status === 0) === "processing" ? (
                <ClockCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )
            }
          >
            {d.status === 0 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        {Object.entries(filters)?.map(([key, value], i) => (
          <div key={i} className="position-relative">
            <Select
              size="large"
              className="w-200px"
              value={selectedFilters[key]}
              onChange={(e) => {
                const obj = { ...selectedFilters, [key]: e };
                setSelectedFilters(obj);
                setTableLoading(true);
                setTableList([]);
                setPage(1);
                getTableList({
                  page: 1,
                  perPage: pageSize,
                  ...obj,
                });
              }}
              options={Object.entries(value)?.map(([key, value]) => ({
                key: key,
                value: value,
              }))}
              placeholder={`Select ${convertToCamelCase(key)}`}
            />
          </div>
        ))}
      </div>

      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-12 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Central Logs
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-0">
              <Spin spinning={tableLoading} tip="Loading...">
                <Table1
                  columns={columns}
                  dataSource={tableList}
                  rowKey="key"
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              </Spin>

              <div className="d-none table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gx-4 gs-4">
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bolder text-gray-800 bg-main-light">
                      <th className="w-50px ">#</th>
                      {/* <th class="min-w-75px ">LogId</th> */}
                      <th className="min-w-175px ">Log Created Date</th>
                      <th className="min-w-125px ">Event Date</th>
                      <th className="min-w-125px ">Event Name</th>
                      <th className="min-w-125px ">Event Type</th>
                      <th className="min-w-125px ">Event Status</th>
                      <th className="min-w-100px ">Priority</th>
                      <th className="min-w-100px ">Marketplace</th>
                      <th className="min-w-175px ">Updated At</th>
                      <th className="min-w-150px ">Report Requested</th>
                      <th className="min-w-100px ">Reason</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>1 </td>
                      <td>Mar 11, 2024 07:19 AM</td>
                      <td>Mar 10, 2024</td>
                      <td>
                        <b>-</b>
                      </td>
                      <td>SP API Report</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                          Failed
                        </span>
                      </td>
                      <td>Medium</td>
                      <td>USA</td>
                      <td>
                        Mar 11, 2024 07:19 AM
                        <small className="text-gray-500">(10 hours ago)</small>
                      </td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">
                          Pending
                        </span>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Mar 11, 2024 07:19 AM</td>
                      <td>Mar 10, 2024</td>
                      <td>
                        <b>-</b>
                      </td>
                      <td>SP API Report</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                          Failed
                        </span>
                      </td>
                      <td>Medium</td>
                      <td>USA</td>
                      <td>
                        Mar 11, 2024 07:19 AM
                        <small className="text-gray-500">(10 hours ago)</small>
                      </td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">
                          Pending
                        </span>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Mar 11, 2024 07:19 AM</td>
                      <td>Mar 10, 2024</td>
                      <td>
                        <b>-</b>
                      </td>
                      <td>SP API Report</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                          Failed
                        </span>
                      </td>
                      <td>Medium</td>
                      <td>USA</td>
                      <td>
                        Mar 11, 2024 07:19 AM
                        <small className="text-gray-500">(10 hours ago)</small>
                      </td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">
                          Pending
                        </span>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Mar 11, 2024 07:19 AM</td>
                      <td>Mar 10, 2024</td>
                      <td>
                        <b>-</b>
                      </td>
                      <td>SP API Report</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                          Failed
                        </span>
                      </td>
                      <td>Medium</td>
                      <td>USA</td>
                      <td>
                        Mar 11, 2024 07:19 AM
                        <small className="text-gray-500">(10 hours ago)</small>
                      </td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">
                          Pending
                        </span>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Mar 11, 2024 07:19 AM</td>
                      <td>Mar 10, 2024</td>
                      <td>
                        <b>-</b>
                      </td>
                      <td>SP API Report</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                          Failed
                        </span>
                      </td>
                      <td>Medium</td>
                      <td>USA</td>
                      <td>
                        Mar 11, 2024 07:19 AM
                        <small className="text-gray-500">(10 hours ago)</small>
                      </td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">
                          Pending
                        </span>
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
            </div>
            <div className="card-footer pt-0 pb-4">
              <Pagination
                loading={tableLoading || tableList?.length === 0}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
            {/*end::Body*/}
          </div>
        </div>
      </div>
    </div>
  );
}
