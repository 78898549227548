export const CreateOrderStatusList = [
  { label: "All", value: "all_data" },
  { label: "Active", value: "active" },
  { label: "Default", value: "default" },
  { label: "Disabled", value: "disabled" },
  { label: "Inactive ", value: "inactive" },
  { label: "In Stock", value: "instock" },
  { label: "Linked", value: "linked" },
  { label: "Out Of Stock", value: "outofstock" },
  { label: "Unlinked", value: "unlinked" },
  { label: "Non-Replenishment", value: "is_replenishable" },
];
