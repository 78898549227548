import { Table } from "antd";
import React from "react";
import Table2 from "../../../../Components/tables/table2";

const MappingView = () => {
  const data = Array.from({ length: 10 }).map((_, i) => ({
    key: i,
    ASIN: `ASIN-${i}`,
    MSKU: `MSKU-${i}`,
    Title: `Title-${i}`,
    Image: `https://picsum.photos/id/${i}/50/50`,
    "Brand Name": `Brand Name-${i}`,
    "Product Line": `Product Line-${i}`,
  }));
  const columns = [
    {
      title: "ASIN",
      dataIndex: "ASIN",
      key: "ASIN",
    },
    {
      title: "MSKU",
      dataIndex: "MSKU",
      key: "MSKU",
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },
    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      render: (text) => <img src={text} width={50} height={50} />,
    },
    {
      title: "Brand Name",
      dataIndex: "Brand Name",
      key: "Brand Name",
    },
    {
      title: "Product Line",
      dataIndex: "Product Line",
      key: "Product Line",
    },
  ];
  return (
    <div>
      <Table2
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default MappingView;
