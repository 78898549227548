import React from "react";
import { Wrapper } from "./style";
import {
  Button,
  DatePicker,
  Dropdown,
  Flex,
  Input,
  Menu,
  Select,
  Space,
  Tag,
} from "antd";
import Table2 from "../../../Components/tables/table2";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";

const PoList = () => {
  const dataSource = [
    {
      key: "1",
      reference: "REF001",
      vendor: "Vendor A",
      brand: "Brand A",
      poTemplate: "Template 1",
      status: "Pending",
      submissionDate: "2024-01-12",
      actualInboundDate: "2024-01-18",
      actualOnShelfDate: "2024-01-25",
      total: "$5,000",
      totalUnits: 150,
      asinCount: 25,
      orderedAsinUnits: 100,
      updatedAt: "2024-01-15",
      note: "First shipment is delayed.",
    },
    {
      key: "2",
      reference: "REF002",
      vendor: "Vendor B",
      brand: "Brand B",
      poTemplate: "Template 2",
      status: "Completed",
      submissionDate: "2024-02-01",
      actualInboundDate: "2024-02-05",
      actualOnShelfDate: "2024-02-10",
      total: "$10,000",
      totalUnits: 250,
      asinCount: 40,
      orderedAsinUnits: 200,
      updatedAt: "2024-02-08",
      note: "On time delivery.",
    },
    // Add more rows as needed
  ];

  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      render: (e) => {
        return (
          <Link to="/po-create">
            <Tag color="blue">{e}</Tag>
          </Link>
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "PO Template",
      dataIndex: "poTemplate",
      key: "poTemplate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => {
        return (
          <Tag
            bordered={false}
            icon={
              e === "Pending" ? (
                <Icon width={18} icon="mdi:clock" />
              ) : (
                <Icon width={22} icon="lets-icons:done-duotone" />
              )
            }
            color={e === "Pending" ? "processing" : "success"}
          >
            &nbsp;{e}
          </Tag>
        );
      },
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      render: (e) => {
        return (
          <div className="d-flex align-items-center">
            {e}&nbsp;{" "}
            <Button className="p-3 ms-3" color="primary" variant="filled">
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Actual Inbound Delivery Date",
      dataIndex: "actualInboundDate",
      key: "actualInboundDate",
      render: (e) => {
        return (
          <div className="d-flex align-items-center">
            {e}&nbsp;{" "}
            <Button className="p-3 ms-3" color="primary" variant="filled">
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
            <Tag className="p-2 ms-3" color="green-inverse" bordered={false}>
              <Icon width={18} icon="material-symbols:info-outline" />
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Actual On-Shelf Date",
      dataIndex: "actualOnShelfDate",
      key: "actualOnShelfDate",
      render: (e) => {
        return (
          <div className="d-flex align-items-center">
            {e}&nbsp;{" "}
            <Button className="p-3 ms-3" color="primary" variant="filled">
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
            <Tag className="p-2 ms-3" color="red-inverse" bordered={false}>
              <Icon width={18} icon="material-symbols:info-outline" />
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Total Units",
      dataIndex: "totalUnits",
      key: "totalUnits",
    },
    {
      title: "ASIN Count",
      dataIndex: "asinCount",
      key: "asinCount",
    },
    {
      title: "Ordered ASIN Unit(s)",
      dataIndex: "orderedAsinUnits",
      key: "orderedAsinUnits",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (e) => {
        return (
          <div className="d-flex align-items-center">
            {e}&nbsp;{" "}
            <Button className="p-3 ms-3" color="primary" variant="filled">
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (e) => {
        return <Input value={e} />;
      },
    },
  ];
  return (
    <Wrapper>
      <Flex className="mb-3" justify="space-between">
        <Space>
          <Input placeholder="Search..." />
          <Select size="large" placeholder="Saved Filter" />
          <DatePicker size="large" placeholder="Expected Date" />
          <Button type="primary">
            <Icon icon="oui:export" /> Export
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">Approve Draft</Menu.Item>
                <Menu.Item key="2">Close PO</Menu.Item>
                <Menu.Item key="3">Receive</Menu.Item>
                <Menu.Item key="4">Re-Open</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button type="primary">
              Bulk Actions <Icon icon="bxs:down-arrow" />
            </Button>
          </Dropdown>
        </Space>
        <Space>
          <Button color="danger" variant="filled">
            Delete
          </Button>
        </Space>
      </Flex>
      <Table2
        scroll={{ x: "max-content" }}
        dataSource={dataSource}
        columns={columns}
      />
      <Flex justify="space-between">
        <Space>
          Total: <b>$40,108,392.03</b>
        </Space>
        <Space>
          Total Units: <b>3,604,508</b>
        </Space>
        <Space>
          Ordered ASIN Unit(s): <b>2,587,494</b>
        </Space>
        <Space>
          Shipping Cost: <b>$0.00</b>
        </Space>
        <Space>
          Discount: <b>$182,129.18</b>
        </Space>
      </Flex>
    </Wrapper>
  );
};

export default PoList;
