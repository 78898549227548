import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  // background-image: url(/assets/media/auth/bg14-dark.jpg);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background-attachment: fixed;
  #kt_app_root {
    height: 100%;
  }
  .back-change {
    background-color: rgba(255, 255, 255, 0.45) !important;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    border: 1px solid rgba(255, 255, 255, 0.225);
  }
  .card-view {
    background-color: #0000002e !important;
    border: 2px solid #ffffff26 !important;
    backdrop-filter: blur(18px) !important;
  }
  .slide-bottom {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  .fs-10 {
    font-size: 2.5rem !important;
  }
  .h-n80 {
    height: calc(100vh - 80px);
  }
  .circle-top {
    position: absolute;
    top: -33%;
    left: -14%;
    border: 120px solid #0b2947;
    height: 700px;
    width: 700px;
    display: block;
    background: 0 0;
    border-radius: 100%;
    z-index: 1;
  }
  .circle-bottom {
    position: absolute;
    bottom: -4%;
    right: 1%;
    height: 450px;
    width: 450px;
    display: block;
    background: 0 0;
    z-index: 1;
    opacity: 0.5;
  }
`;
