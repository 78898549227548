import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Row,
  Col,
  Divider,
  Space,
  message,
} from "antd";
import { useEffect } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { MakeApiCall } from "../../../../apis";

const { TextArea } = Input;

const EditItemsModal = ({
  visible,
  onCancel,
  editForm,
  onSave,
  initialData,
  brandList,
  brandLoading,
  vendorList,
  vendorLoading,
  supplyLoading,
  supplyList,
  getList,
  selectedMarketplace,
  productLinesList,
  productLinesLoading,
  userData,
  getProductLineAction,
}) => {
  const repricerList = [
    { label: "Custom", value: "custom" },
    { label: "Fixed Pricing", value: "fixed_pricing" },
    { label: "MAP Pricing", value: "map_pricing" },
    { label: "Strategy Pricing", value: "strategy_pricing" },
  ];
  const [submitLoading, setSubmitLoading] = useState(false);
  const [productLineName, setProductLineName] = useState(null);
  useEffect(() => {
    if (!visible) {
      editForm.resetFields();
      setSubmitLoading(false);
    }
    return () => {
      editForm.resetFields();
      setSubmitLoading(false);
    };
  }, [visible]);

  useEffect(() => {
    if (initialData) {
      editForm.setFieldsValue(initialData);
    }
  }, [initialData]);

  const AddProductLine = async (value) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `product-lines`,
        "post",
        {
          name: value,
          marketplace_id: selectedMarketplace,
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        getProductLineAction();
        setProductLineName(null);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  // Handle form submission
  const handleOk = () => {
    editForm
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        onSave(values); // Pass the form data to the save function
        // form.resetFields();
      })
      .catch((info) => {});
  };

  const filterOption = (input, option) =>
    option?.label?.toLowerCase()?.includes(input?.toLowerCase());

  return (
    <Modal
      visible={visible}
      title="Edit Item Details"
      onCancel={onCancel}
      width={850}
      okButtonProps={{ loading: submitLoading }}
      onOk={handleOk}
    >
      <Form form={editForm} layout="vertical" initialValues={initialData}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="item_name"
              label="Item Description"
              rules={[{ required: true }]}
            >
              <TextArea rows={2} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="product_short_description"
              label="Product Short Description"
            >
              <TextArea rows={2} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="asin_cost" label="ASIN Cost">
              <InputNumber size="large" disabled style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="asin1" label="ASIN">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="seller_sku" label="MSKU">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="prep_type" label="Prep Type">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="brand_id" label="Select Brand">
              <Select
                size="large"
                style={{ borderRadius: "4px" }}
                options={brandList?.map((d) => ({
                  label: d?.b_name,
                  value: d?.b_id,
                }))}
                loading={brandLoading}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="vendor_id" label="Select Vendor">
              <Select
                size="large"
                style={{ borderRadius: "4px" }}
                options={vendorList?.map((d) => ({
                  label: d?.name,
                  value: d?.id,
                }))}
                loading={vendorLoading}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item name="supply" label="Select Supply">
              <Select
                size="large"
                style={{ borderRadius: "4px" }}
                options={supplyList?.map((d) => ({
                  label: d?.supply,
                  value: d?.id,
                }))}
                loading={supplyLoading}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item name="moq" label="MOQ" rules={[{ required: true }]}>
              <InputNumber size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="map_price" label="Map Price">
              <InputNumber size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="product_group" label="Product Group">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="product_line_id" label="Product Line">
              <Select
                size="large"
                optionFilterProp="label"
                showSearch
                filterOption={filterOption}
                onDropdownVisibleChange={(e) => {
                  setProductLineName(null);
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Please enter item"
                        value={productLineName}
                        onChange={(e) => setProductLineName(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<Icon width={20} icon="ic:baseline-plus" />}
                        onClick={() => {
                          AddProductLine(productLineName);
                        }}
                      >
                        Add item
                      </Button>
                    </Space>
                  </>
                )}
                loading={productLinesLoading}
                options={productLinesList?.map((d) => ({
                  label: d?.name,
                  value: d?.id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="repricer_type" label="Repricer Type">
              <Select
                options={repricerList}
                size="large"
                style={{ borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="item_note" label="Item Note">
              <TextArea rows={2} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="item_days_of_stock" label="Days of Stock">
              <InputNumber size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="attribute1" label="Attribute1">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="attribute2" label="Attribute2">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="attribute3" label="Attribute3">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item hidden name="fald_id" label="Attribute3">
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditItemsModal;
