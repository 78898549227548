import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Menu,
  message,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import MarketplaceSelect from "../../../Components/marketplace";
import { TableLoading } from "../../../Components/table-animation";
import Table2 from "../../../Components/tables/table2";
import {
  ConvertParams,
  FormatNumber,
  PaginationConfig,
  PropsFilter,
} from "../../../config";
import LinkItems from "../items-link-items";
import AddComponentsModal from "./lib/add-components";
import AdvancedFilter from "./lib/advanced-filter";
import AsinHistory from "./lib/asin-history";
import ASINNote from "./lib/asin-note";
import BulkModalAction from "./lib/bulk-action";
import EditItemsModal from "./lib/edit-modal";
import PrepLogModal from "./lib/prep-log-modal";
import UnitHistory from "./lib/unit-history";
import { Wrapper } from "./style";
import LinkComponents from "./lib/link-components";

const ItemMaster = ({ userData }) => {
  const [editForm] = Form.useForm();
  const [Filterform] = Form.useForm(); // Initialize useForm hook

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [componentData, setComponentData] = useState([]);
  const [advanceFilterList, setAdvanceFilterList] = useState([]);
  const [advanceFilterLoading, setAdvanceFilterLoading] = useState(true);
  const [selectedAdvanceFilter, setSelectedAdvanceFilter] = useState(null);
  const [asinCostHistory, setAsinCostHistory] = useState({
    show: false,
  });

  const [asinNote, setAsinNote] = useState({
    show: false,
  });
  const [unitCostHistory, setUnitCostHistory] = useState({
    show: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [supplyList, setSupplyList] = useState([]);
  const [supplyLoading, setSupplyLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isModalVisibleBulk, setIsModalVisibleBulk] = useState(false);
  const [selectedBulkData, setSelectedBulkData] = useState({});
  const [form] = Form.useForm();
  const [moreFilterView, setMoreFilterView] = useState(false);

  const [prepLogModal, setPrepLogModal] = useState(false);
  const [prepLogData, setPrepLogData] = useState([]);

  const [productLinesList, setProductLinesList] = useState([]);
  const [productLinesLoading, setProductLinesLoading] = useState(true);
  const [sortFilters, setSortFilters] = useState("");
  const [linkComponentModal, setLinkComponentModal] = useState(false);
  const [selectedLinkItems, setSelectedLinkItems] = useState(null);
  const [filter, setFilter] = useState({
    itemStatus: "1",
    searchKeyword: null,
  });
  const handleCancelPrepLog = () => {
    setPrepLogData([]);
    setPrepLogModal(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const showModalBulk = (data) => {
    setSelectedBulkData(data);
    setIsModalVisibleBulk(true);
  };

  const BulkAction = async (api, data) => {
    message.destroy();
    message.loading("Please wait...", 0);
    try {
      const response = await MakeApiCall(
        api,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListItemsAction({ ...filters });
        setSelectedRow([]);

        setIsModalVisibleBulk(false);
        form.resetFields();
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleBulkAction = async (api, data) => {
    message.destroy();
    message.loading("Please wait...", 0);
    try {
      const response = await MakeApiCall(
        api,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleOkBulk = async (e) => {
    try {
      await form.validateFields();
      // Handle form submission here
      BulkAction(e?.api, {
        selectedItems: e?.selectedItems,
        ...form.getFieldsValue(),
      });

      // setIsModalVisibleBulk(false);
      // form.resetFields();
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  const handleCancelBulk = () => {
    setSelectedBulkData({});
    setIsModalVisibleBulk(false);
    form.resetFields();
  };

  const GetBrandListAction = async () => {
    setBrandLoading(true);
    setBrandList([]);
    try {
      const response = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setBrandList(response?.data?.records || []);

        setBrandLoading(false);
      } else {
        setBrandList([]);
        message.destroy();
        setBrandLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setBrandList([]);
      setBrandLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetVendorListAction = async () => {
    setVendorLoading(true);
    setVendorList([]);
    try {
      const response = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setVendorList(response?.data?.records || []);
        setVendorLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setVendorLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorLoading(false);
      setVendorList([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetSupplysListAction = async () => {
    setSupplyLoading(true);
    setSupplyList([]);
    try {
      const response = await MakeApiCall(
        `supplys?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setSupplyList(response?.data?.records || []);
        setSupplyLoading(false);
      } else {
        message.destroy();
        setSupplyList([]);
        setSupplyLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSupplyLoading(false);
      setSupplyList([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const UpdateProduct = async (id, data) => {
    try {
      const response = await MakeApiCall(
        `update-fba-item/${id}`,
        "patch",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setList((prev) => {
          return prev.map((item) => {
            if (item.fald_id === id) {
              return { ...item, ...data };
            }
            return item;
          });
        });
        editForm.resetFields();
        message.destroy();
        message.success(response?.message);
        ListItemsAction({ ...filters });
        setIsModalVisible(false);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };
  // Open the modal for editing with initial data
  const openEditModal = (data) => {
    setInitialData(data);
    setIsModalVisible(true);
  };
  const GetProductListsAction = async () => {
    setProductLinesLoading(true);
    try {
      const response = await MakeApiCall(
        `product-lines`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setProductLinesList(response?.data?.records || []);
        setProductLinesLoading(false);
      } else {
        setProductLinesLoading(false);
        setProductLinesList([]);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      setProductLinesLoading(false);
      setProductLinesList([]);
      message.error(error?.message);
    }
  };

  // Handle modal close
  const handleCancelEdit = () => {
    setIsModalVisible(false);
    setInitialData(null); // Clear initial data after closing
  };

  // Handle save action from modal
  const handleSave = (formData) => {
    const { fald_id, ...rest } = formData;

    UpdateProduct(fald_id, rest);
  };

  const [filters, setFilters] = useState({
    page: 1,
    "page-size": 20,
    totalPage: 0,
    page_count: 0,
    filter_value: null,
    action: "all_data",
  });

  const ListItemsAction = async (data) => {
    const { totalPage, page_count, loading = true, ...rest } = data;
    if (loading) {
      setLoading(true);
    }

    try {
      const response = await MakeApiCall(
        `item-master${ConvertParams(rest)}`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        if (!loading) {
          window.open(response?.data?.file);

          return;
        }
        const { totalCount, page_count, page, page_size } =
          response?.data?.pagination;
        let apiData = response.data.records;

        Object.entries(response.data.componentData).forEach((link) => {
          const index = response.data.records.findIndex(
            (d) => parseInt(d.id) === parseInt(link[0])
          );

          if (index !== -1) {
            apiData[index] = { ...apiData[index], linkStatus: true };
          } else {
            apiData[index] = { ...apiData[index], linkStatus: false };
          }
        });
        apiData.forEach((e, i) => {
          const indexSKU = Object.entries(response.data.skuFeesData).findIndex(
            (d) => d[0] === e.seller_sku
          );
          if (indexSKU !== -1) {
            apiData.splice(
              i,
              1,
              Object.assign(
                e,
                Object.entries(response.data.skuFeesData)[indexSKU][1]
              )
            );
          }
        });
        if (apiData?.length > 0) {
          setFilters({
            ...data,
            page: page,
            "page-size": page_size,
            page_count: page_count,
            totalPage: totalCount,
          });
          setList(apiData?.map((d) => ({ ...d, fald_id: d.id })) || []);
          setComponentData(response?.data?.componentData);
        } else {
          setList([]);
          setComponentData([]);
        }

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const AdvanceFiltersAction = async () => {
    setAdvanceFilterLoading(true);
    try {
      const response = await MakeApiCall(
        `advance-filter`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setAdvanceFilterList(response?.data?.getAllFilter || []);
        setAdvanceFilterLoading(false);
      } else {
        message.destroy();
        setAdvanceFilterList([]);
        setAdvanceFilterLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setAdvanceFilterList([]);
      setAdvanceFilterLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const SaveMAPPriceAction = async (data) => {
    try {
      const response = await MakeApiCall(
        `save-map-price`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListItemsAction({ ...filters });
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const ActionFire = async () => {
    await AdvanceFiltersAction();
    await GetBrandListAction();
    await GetVendorListAction();
    await GetSupplysListAction();
    await GetProductListsAction();
  };

  useEffect(() => {
    ActionFire();

    return () => {};
  }, []);

  const ASINCostHistoryAction = async (id) => {
    try {
      const response = await MakeApiCall(
        `asin-cost-history/${id}`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        if (response?.data?.records?.length > 0) {
          setAsinCostHistory({
            show: true,
            data: response?.data?.records,
          });
        } else {
          message.destroy();
          message.success(response?.message);
        }
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };
  const UnitCostHistoryAction = async (id) => {
    try {
      const response = await MakeApiCall(
        `item-unit-cost-history/${id}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        if (response?.data?.length > 0) {
          setUnitCostHistory({
            show: true,
            data: response?.data || [],
          });
        } else {
          message.destroy();
          message.success(response?.message);
        }
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };
  const UpdateProductLineAction = async (id, value) => {
    try {
      const response = await MakeApiCall(
        `update-product-line/${id}`,
        "PATCH",
        {
          productLineId: value,
        },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setList((prev) => {
          return prev?.map((d) => {
            if (d?.fald_id === id) {
              return {
                ...d,
                product_line_id: value,
              };
            }
            return d;
          });
        });
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const columns = [
    {
      title: "Detail",

      fixed: "left",
      align: "center",
      render: (row) => {
        return (
          <div className="d-flex align-items-center gap-3">
            <Icon
              icon="ri:amazon-fill"
              width={26}
              style={{ color: "orange", cursor: "pointer" }}
              onClick={() =>
                window.open(`https://www.amazon.com/dp/${row.asin1}`)
              }
            />
            <Icon
              onClick={() =>
                window.open(
                  `https://sellercentral.amazon.com/skucentral?mSku=${row.seller_sku}&ref=myi_skuc`
                )
              }
              style={{ cursor: "pointer" }}
              icon="solar:tag-bold-duotone"
              width={26}
            />
            <Icon
              onClick={() => {
                console.log(row, "row");

                const {
                  item_name,
                  product_short_description,
                  fald_id,
                  asin1,
                  asin_cost,
                  seller_sku,
                  prep_type,
                  brand,
                  brand_id,
                  vendor_id,
                  vendor,
                  supply,
                  moq,
                  map_price,
                  product_group,
                  product_line_id,
                  repricer_type,
                  item_note,
                  item_days_of_stock,
                  attribute1,
                  attribute2,
                  attribute3,
                } = row;

                openEditModal({
                  item_name,
                  product_short_description,
                  asin1,
                  asin_cost,
                  seller_sku,
                  prep_type,
                  brand: brand ? parseInt(brand) : null,
                  vendor: vendor ? parseInt(vendor) : null,
                  supply: supply ? parseInt(supply) : null,
                  moq,
                  brand_id,
                  vendor_id,
                  map_price,
                  product_group,
                  product_line_id,
                  repricer_type,
                  item_note,
                  item_days_of_stock,
                  attribute1,
                  attribute2,
                  attribute3,
                  fald_id,
                });
              }}
              style={{ cursor: "pointer" }}
              icon="flowbite:edit-solid"
              width={26}
            />
          </div>
        );
      },
    },
    {
      title: "Item Description",
      width: 473,
      fixed: "left",
      align: "left",
      dataIndex: "item_name",
      sortOn: true,

      render: (_, row) => {
        return (
          <div
            className="d-flex box-view-columns"
            style={{
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #0179b0a8",
              background: "#ecf1f4",
            }}
          >
            <Image
              loading="lazy"
              alt="Image"
              width={70}
              height={70}
              style={{
                objectFit: "contain",
                width: "70px",
              }}
              src={
                row.image_url ||
                "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png"
              }
            />
            <div className="d-grid">
              <div
                style={{ marginLeft: "10px" }}
                className="text-truncate_"
                title={row.item_name}
              >
                {row.item_name}
              </div>

              <div className="d-flex mt-2 ms-2">
                <Tag bordered={false} color="blue">
                  ASIN: {row?.asin1}
                </Tag>
                <Tag bordered={false} color="purple">
                  FNSKU: {row?.fnsku}
                </Tag>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      sortOn: true,
      align: "center",
      render: (_, row) => <span className="table-row">{row.moq || "0"}</span>,
    },
    {
      title: "MSKU",
      align: "center",
      dataIndex: "seller_sku",
      sortOn: true,
      render: (_, row) => (
        <span className="table-row">
          <Tag color="#0179af">{row.seller_sku}</Tag>
        </span>
      ),
    },
    {
      title: "ASIN Type",
      dataIndex: "asin_type",
      sortOn: true,
      align: "center",
      render: (_, row) => (
        <span className="table-row">{row.asin_type || "N/A"}</span>
      ),
    },
    {
      title: "FBA Fee",
      dataIndex: "estimated_fee_total",
      sortOn: true,
      align: "center",

      render: (_, row) => {
        return (
          <span className="table-row">
            ${FormatNumber(row.estimated_fee_total || 0) || 0}
          </span>
        );
      },
    },
    {
      title: "MAP Price",
      dataIndex: "map_price",
      sortOn: true,
      align: "center",
      render: (_, row) => (
        <Input
          placeholder="MAP Price"
          value={row.map_price}
          onChange={(e) => {
            setList((prevState) => {
              const index = prevState.findIndex(
                (e) => e.fald_id === row.fald_id
              );
              if (index !== -1) {
                const a = prevState;
                a[index] = { ...row, map_price: e.target.value };
                return [...a];
              }
              return prevState;
            });
          }}
          className="w-100px"
          variant="filled"
          onBlur={() => {
            SaveMAPPriceAction({
              map_price: row?.map_price,
              id: row.fald_id,
            });
          }}
          onPressEnter={(e) => {
            SaveMAPPriceAction({
              map_price: e.target.value,
              id: row.fald_id,
            });
          }}
        />
      ),
    },
    {
      title: "Seller Fee",
      dataIndex: "estimated_referral_fee_per_unit",
      sortOn: true,
      align: "center",

      render: (_, row) => {
        return (
          <span className="table-row">
            ${FormatNumber(row.estimated_referral_fee_per_unit || 0) ?? 0}
          </span>
        );
      },
    },
    {
      title: "Storage Fee",
      dataIndex: "storage_fee",
      sortOn: true,
      align: "center",

      render: (_, row) => {
        return (
          <span className="table-row">
            ${FormatNumber(row.storage_fee || 0) ?? 0}
          </span>
        );
      },
    },
    {
      title: "Total Fee",

      align: "center",

      render: (row) => {
        const totalFee =
          (parseFloat(row.estimated_fee_total) || 0) +
          (parseFloat(row.estimated_referral_fee_per_unit) || 0) +
          (parseFloat(row.storage_fees) || 0);
        return (
          <span className="table-row">
            ${FormatNumber(parseFloat(totalFee).toFixed(2)) ?? 0}
          </span>
        );
      },
    },
    {
      title: "FullFillment Type",
      dataIndex: "fullfillment_type",
      sortOn: true,
      align: "center",

      render: (_, row) => (
        <span className="table-row">{row.fullfillment_type || "N/A"}</span>
      ),
    },
    {
      title: "Prep Type",
      dataIndex: "prep_type",
      sortOn: true,
      align: "center",

      render: (_, row) => {
        return (
          <Link
            to={
              row.linkStatus === undefined
                ? "#"
                : row.prep_type
                ? `/link-items?searchKeyword=${row.asin1}`
                : "#"
            }
          >
            <Tag color="purple">
              {row.linkStatus === undefined ? "N/A" : row.prep_type || "N/A"}
            </Tag>
          </Link>
        );
      },
    },
    {
      title: "ASIN Cost",
      dataIndex: "asin_cost",
      sortOn: true,
      align: "center",
      render: (_, row) => (
        <div>
          <span>
            {`$` + FormatNumber(parseFloat(row.asin_cost).toFixed(2)) || 0}
          </span>
          <Button
            type="link"
            size="small"
            loading={row?.asin_cost_loading}
            icon={<Icon icon="uim:clock" width={24} />}
            className="ms-3"
            onClick={async () => {
              setList((prev) => {
                return prev?.map((d) => {
                  if (d?.fald_id === row?.fald_id) {
                    return {
                      ...d,
                      asin_cost_loading: true,
                    };
                  }
                  return { ...d };
                });
              });
              try {
                await ASINCostHistoryAction(row?.fald_id);
              } finally {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        asin_cost_loading: false,
                      };
                    }
                    return { ...d };
                  });
                });
              }
            }}
          ></Button>
        </div>
      ),
    },
    {
      title: "Unit Cost History",
      dataIndex: "unit_cost_history_loading",
      sortOn: true,
      align: "center",
      render: (_, row) => (
        <Button
          type="link"
          size="small"
          loading={row?.unit_cost_history_loading}
          icon={<Icon icon="uim:clock" width={24} />}
          className="ms-3"
          onClick={async () => {
            setList((prev) => {
              return prev?.map((d) => {
                if (d?.fald_id === row?.fald_id) {
                  return {
                    ...d,
                    unit_cost_history_loading: true,
                  };
                }
                return { ...d };
              });
            });
            try {
              await UnitCostHistoryAction(row?.fald_id);
            } finally {
              setList((prev) => {
                return prev?.map((d) => {
                  if (d?.fald_id === row?.fald_id) {
                    return {
                      ...d,
                      unit_cost_history_loading: false,
                    };
                  }
                  return { ...d };
                });
              });
            }
          }}
        ></Button>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brands",
      sortOn: true,
      align: "center",

      render: (_, row) => (
        <span className="table-row">
          {(row.brands
            ? row.brands.b_name
              ? row.brands.b_name
              : row.brandName
            : row.brandName) || "N/A"}
        </span>
      ),
    },
    {
      title: "Repricer Type",
      dataIndex: "repricer_type",
      sortOn: true,
      align: "center",

      render: (_, row) => (
        <span className="table-row">{row.repricer_type || "N/A"}</span>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendors",
      sortOn: true,
      align: "center",

      render: (_, row) => (
        <span className="table-row">
          {(row.vendors ? row.vendors.name : "N/A") || "N/A"}
        </span>
      ),
    },
    {
      title: "ASIN Note",
      dataIndex: "item_note",
      sortOn: true,
      align: "center",
      render: (_, row) => (
        <Button
          onClick={() => {
            setAsinNote({
              show: true,
              data: row,
            });
          }}
          color="primary"
          variant="filled"
          size="small"
        >
          <Icon icon={row?.item_note ? "mdi:eye" : "si:add-fill"} width={18} />
        </Button>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "open_date",
      sortOn: true,
      align: "center",

      render: (row) => {
        return (
          <span className="table-row">
            {moment(new Date(row.open_date)).format("MM/DD/YYYY")}
          </span>
        );
      },
    },
    {
      title: "Product Group",
      dataIndex: "product_group",
      sortOn: true,
      align: "center",

      render: (e, row) => {
        return (
          <div>
            <Input
              value={
                row?.product_group_edit
                  ? row?.product_group_value
                  : row?.product_group
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        product_group_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.product_group_edit ? "outlined" : "filled"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.product_group_edit ? (
                    <>
                      {row?.product_group_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={async (e) => {
                            e.stopPropagation();

                            const obj = {
                              product_group: row?.product_group_value,
                              fald_id: row?.fald_id,
                            };

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    product_group_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                            await handleBulkAction("pro-group-save", obj);
                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    product_group: row?.product_group_value,
                                    product_group_edit: false,
                                    product_group_loading: false,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.fald_id === row?.fald_id) {
                                delete d?.product_group_value;
                                return {
                                  ...d,
                                  product_group_edit: false,
                                  product_group_loading: false,
                                  product_group_value: row?.product_group,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.fald_id === row?.fald_id) {
                              return {
                                ...d,
                                product_group_edit: true,
                                product_group_loading: false,
                                product_group_value: row?.product_group || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      style={{ cursor: "pointer" }}
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: "Product Line",
      width: 140,
      align: "left",
      dataIndex: "product_line_id",
      sortOn: true,
      render: (t, row) => {
        return (
          <div>
            <Select
              className="w-150px"
              variant="filled"
              onChange={(e) => {
                UpdateProductLineAction(t?.fald_id, e);
              }}
              loading={productLinesLoading}
              value={productLinesLoading ? null : row?.product_line_id}
              options={productLinesList?.map((d) => ({
                label: d?.name,
                value: d?.id,
              }))}
            />
          </div>
        );
      },
    },
    {
      title: "Product Short Description",
      width: 240,
      align: "center",
      dataIndex: "product_short_description",
      sortOn: true,
      render: (e, row) => {
        return (
          <div>
            <Input
              value={
                row?.product_short_description_edit
                  ? row?.product_short_description_value
                  : row?.product_short_description
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        product_short_description_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={
                row?.product_short_description_edit ? "outlined" : "filled"
              }
              suffix={
                <div className="d-flex align-items-center">
                  {row?.product_short_description_edit ? (
                    <>
                      {row?.product_short_description_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={async (e) => {
                            e.stopPropagation();

                            const obj = {
                              product_short_description:
                                row?.product_short_description_value,
                              fald_id: row?.fald_id,
                            };

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    product_short_description_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                            await handleBulkAction("pro-desc-save", obj);
                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    product_short_description:
                                      row?.product_short_description_value,
                                    product_short_description_edit: false,
                                    product_short_description_loading: false,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.fald_id === row?.fald_id) {
                                delete d?.product_short_description_value;
                                return {
                                  ...d,
                                  product_short_description_edit: false,
                                  product_short_description_loading: false,
                                  product_short_description_value:
                                    row?.product_short_description,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.fald_id === row?.fald_id) {
                              return {
                                ...d,
                                product_short_description_edit: true,
                                product_short_description_loading: false,
                                product_short_description_value:
                                  row?.product_short_description || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      style={{ cursor: "pointer" }}
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: "Attribute1",
      width: 220,
      align: "center",
      dataIndex: "attribute1",
      sortOn: true,
      render: (e, row) => {
        return (
          <div>
            <Input
              value={
                row?.attribute1_edit ? row?.attribute1_value : row?.attribute1
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        attribute1_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.attribute1_edit ? "outlined" : "filled"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.attribute1_edit ? (
                    <>
                      {row?.attribute1_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={async (e) => {
                            e.stopPropagation();

                            const obj = {
                              attribute: row?.attribute1_value,
                              fald_id: row?.fald_id,
                              attribute_type: 1,
                            };

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    attribute1_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                            await handleBulkAction("pro-attribute-save", obj);
                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    attribute1: row?.attribute1_value,
                                    attribute1_edit: false,
                                    attribute1_loading: false,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.fald_id === row?.fald_id) {
                                delete d?.attribute1_value;
                                return {
                                  ...d,
                                  attribute1_edit: false,
                                  attribute1_loading: false,
                                  attribute1_value: row?.attribute1,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.fald_id === row?.fald_id) {
                              return {
                                ...d,
                                attribute1_edit: true,
                                attribute1_loading: false,
                                attribute1_value: row?.attribute1 || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      style={{ cursor: "pointer" }}
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: "Attribute2",
      width: 220,
      align: "center",
      dataIndex: "attribute2",
      sortOn: true,

      render: (e, row) => {
        return (
          <div>
            <Input
              value={
                row?.attribute2_edit ? row?.attribute2_value : row?.attribute2
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        attribute2_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.attribute2_edit ? "outlined" : "filled"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.attribute2_edit ? (
                    <>
                      {row?.attribute2_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={async (e) => {
                            e.stopPropagation();

                            const obj = {
                              attribute: row?.attribute2_value,
                              fald_id: row?.fald_id,
                              attribute_type: 2,
                            };

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    attribute2_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                            await handleBulkAction("pro-attribute-save", obj);
                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    attribute2: row?.attribute2_value,
                                    attribute2_edit: false,
                                    attribute2_loading: false,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.fald_id === row?.fald_id) {
                                delete d?.attribute2_value;
                                return {
                                  ...d,
                                  attribute2_edit: false,
                                  attribute2_loading: false,
                                  attribute2_value: row?.attribute2,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.fald_id === row?.fald_id) {
                              return {
                                ...d,
                                attribute2_edit: true,
                                attribute2_loading: false,
                                attribute2_value: row?.attribute2 || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      style={{ cursor: "pointer" }}
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: "Attribute3",
      width: 220,
      align: "center",
      dataIndex: "attribute3",
      sortOn: true,
      render: (e, row) => {
        return (
          <div>
            <Input
              value={
                row?.attribute3_edit ? row?.attribute3_value : row?.attribute3
              }
              className="w-150px"
              onChange={(e) => {
                setList((prev) => {
                  return prev?.map((d) => {
                    if (d?.fald_id === row?.fald_id) {
                      return {
                        ...d,
                        attribute3_value: e.target.value,
                      };
                    }
                    return d;
                  });
                });
              }}
              variant={row?.attribute3_edit ? "outlined" : "filled"}
              suffix={
                <div className="d-flex align-items-center">
                  {row?.attribute3_edit ? (
                    <>
                      {row?.attribute3_loading ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />
                      ) : (
                        <Icon
                          icon="lets-icons:check-fill"
                          color="green"
                          width={20}
                          onClick={async (e) => {
                            e.stopPropagation();

                            const obj = {
                              attribute: row?.attribute3_value,
                              fald_id: row?.fald_id,
                              attribute_type: 3,
                            };

                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    attribute3_loading: true,
                                  };
                                }
                                return d;
                              });
                            });
                            await handleBulkAction("pro-attribute-save", obj);
                            setList((prev) => {
                              return prev?.map((d) => {
                                if (d?.fald_id === row?.fald_id) {
                                  return {
                                    ...d,
                                    attribute3: row?.attribute3_value,
                                    attribute3_edit: false,
                                    attribute3_loading: false,
                                  };
                                }
                                return d;
                              });
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      <Icon
                        className="ms-1"
                        icon="gridicons:cross-circle"
                        color="red"
                        width={20}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setList((prev) => {
                            return prev?.map((d) => {
                              if (d?.fald_id === row?.fald_id) {
                                delete d?.attribute3_value;
                                return {
                                  ...d,
                                  attribute3_edit: false,
                                  attribute3_loading: false,
                                  attribute3_value: row?.attribute3,
                                };
                              }
                              return d;
                            });
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setList((prev) => {
                          return prev?.map((d) => {
                            if (d?.fald_id === row?.fald_id) {
                              return {
                                ...d,
                                attribute3_edit: true,
                                attribute3_loading: false,
                                attribute3_value: row?.attribute3 || 0,
                              };
                            }
                            return d;
                          });
                        });
                      }}
                      icon="iconamoon:edit-duotone"
                      style={{ cursor: "pointer" }}
                      width={20}
                    />
                  )}
                </div>
              }
            />
          </div>
        );
      },
    },
  ];

  const filterBy = [
    { label: "All", value: "all_data" },
    { label: "Linked", value: "linked" },
    { label: "Unlinked", value: "unlinked" },
    { label: "Disabled", value: "disabled" },
  ];

  // Table columns configuration
  const columnsASINCost = [
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "ASIN Cost",
      dataIndex: "asin_cost",
      key: "asin_cost",
      render: (e) => {
        return `$${parseFloat(e || 0).toFixed(2)}`;
      },
    },
    {
      title: "Active Date",
      dataIndex: "asin_date",
      key: "asin_date",
    },
  ];
  // Table columns configuration
  const columnsUnitCost = [
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "History ID",
      dataIndex: "unique_id",
      key: "unique_id",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (text) => `$${parseFloat(text || 0)?.toFixed(2)}`,
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: "Notes",
      dataIndex: "note",
      key: "note",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Date Active",
      dataIndex: "date_active",
      key: "date_active",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Popconfirm
    //       title="Are you sure to delete this unit cost?"
    //       onConfirm={() => handleDeleteUnitCost(record)}
    //       okText="Yes"
    //       placement="left"
    //       cancelText="No"
    //     >
    //       <Button
    //         color="danger"
    //         variant="filled"
    //         icon={<Icon width={22} icon="ic:round-delete" />}
    //       ></Button>
    //     </Popconfirm>
    //   ),
    // },
  ];

  const bulkActionObj = {
    days_of_stock: {
      label: "Days of Stock",
      key: "item_days_of_stock",
      api: "save-item-days-of-stock",
      componentType: "number",
    },
    add_asin_lead_time: {
      label: "Add ASIN Lead Time",
      key: "asin_lead_time",
      api: "save-item-asin-leadtime",
      componentType: "number",
    },
    add_asin_note: {
      label: "Add ASIN Note",
      key: "itemNote",
      api: "fba-save-item-note",
      componentType: "textarea",
    },
    add_pericer_type: {
      label: "Repricer Type",
      key: "repricer_type",
      api: "save-repricer",
      componentType: "select",
      options: [
        {
          label: "Custom",
          value: "custom",
        },
        {
          label: "Fixed Pricing",
          value: "fixed_pricing",
        },
        {
          label: "MAP Pricing",
          value: "map_pricing",
        },
        {
          label: "Strategy Pricing",
          value: "strategy_pricing",
        },
      ],
    },
    add_supply_type: {
      label: "Add Supply",
      key: "supply",
      api: "save-supply",
      componentType: "select",
      options: supplyList?.map((d) => ({ label: d?.supply, value: d?.id })),
    },
    enable_listing: {
      api: "update-item-enabled",
      componentType: "api-call",
    },
    disable_listing: {
      api: "update-item-disabled",
      componentType: "api-call",
    },
    set_brand: {
      label: "Add Brand",
      key: "brand",
      api: "set-brand",
      componentType: "select",
      options: brandList?.map((d) => ({ label: d?.b_name, value: d?.b_id })),
    },
    set_vendor: {
      label: "Add Vendor",
      key: "vendor",
      api: "set-vendor",
      componentType: "select",
      options: vendorList?.map((d) => ({ label: d?.name, value: d?.id })),
    },
  };

  const handleMenuClick = (e) => {
    if (e?.key === "create_prep_log") {
      showPrepLog();
      return;
    }

    const selectedIDs = selectedRow?.map((d) => d?.id);
    if (bulkActionObj?.[e.key]?.componentType === "api-call") {
      BulkAction(bulkActionObj?.[e.key]?.api, { selectedItems: selectedIDs });
    } else {
      showModalBulk({ ...bulkActionObj?.[e.key], selectedItems: selectedIDs });
    }
  };

  // Functions to show and hide the modal

  const handleCancel = () => {
    setAsinCostHistory({
      show: false,
    });
    setAsinNote({
      show: false,
    });
    setUnitCostHistory({
      show: false,
    });
  };

  const showPrepLog = () => {
    setPrepLogData([]);
    const prepLogDataSet = prepLogData;
    selectedRow.forEach((e) => {
      const index = Object.keys(componentData).findIndex(
        (d) => parseInt(d) === parseInt(e.fald_id)
      );
      prepLogDataSet.push({
        ...e,
        isLinked: index === -1 ? false : true,
      });
      setPrepLogData([...prepLogDataSet]);
      if (prepLogDataSet.length === selectedRow.length) {
        setPrepLogModal(true);
      }
    });
  };

  return (
    <Wrapper>
      <div className="d-flex  justify-content-between">
        <div className="d-flex gap-4 flex-wrap">
          <div className="position-relative col-auto">
            <MarketplaceSelect
              callAfterMarketPlace={(e) => {
                setLoading(true);
                ListItemsAction({
                  ...filters,
                  marketplace_id: e,
                });
              }}
              showSearch
              MarketplaceData={(e) => {
                setSelectedMarketplace(e?.[0]?.value);
              }}
              onChange={(e) => {
                setSelectedMarketplace(e);
                setLoading(true);
                ListItemsAction({
                  ...filters,
                  marketplace_id: e,
                });
              }}
              userData={userData}
            />
          </div>
          <Select
            className="w-125px"
            options={filterBy}
            size="large"
            placeholder="Status"
            onChange={(e) => {
              const obj = {
                ...filters,
                page: 1,
                action: e,
              };
              setFilters(obj);
              ListItemsAction({
                ...obj,
              });
              setLoading(true);
            }}
            value={filters.action}
          />

          <Tooltip title="Search by Brand, Prep Type, Title">
            <Input
              className="w-150px"
              placeholder="Search..."
              onChange={(e) => {
                const obj = {
                  ...filters,
                  page: 1,
                  filter_value: e.target.value,
                };
                setFilters(obj);
              }}
              value={filters.filter_value}
              onPressEnter={() => {
                ListItemsAction({
                  ...filters,
                });
                setLoading(true);
              }}
            />
          </Tooltip>
          <Select
            options={advanceFilterList?.map((d) => ({
              label: d?.user_filter_name,
              value: d?.usf_id,
              ...d,
            }))}
            onChange={(e, _) => {
              const obj = {
                action: _?.component_type,
                "filters[unit_cost]": _?.unit_cost,
                "filters[single_pack]": _?.single_pack ? 1 : 0,
                "filters[multi_pack]": _?.multi_pack ? 1 : 0,
                "filters[kit]": _?.kit ? 1 : 0,
                "filters[standard_size]": _?.standard_size ? 1 : 0,
                "filters[oversize]": _?.oversize ? 1 : 0,
                "filters[vendor_id]": _?.vendor_id,
                "filters[brand_id]": _?.brand_id,
                search_type: "advance",
                filter_value: _?.user_filter_value,
                usf_id: _?.usf_id,
              };

              Filterform.setFieldsValue({
                user_filter_name: _?.user_filter_name,
                user_filter_value: _?.user_filter_value,
                link_type: _?.component_type,
                single_pack: _?.single_pack ? true : false,
                multi_pack: _?.multi_pack ? true : false,
                kit: _?.kit ? true : false,
                standard_size: _?.standard_size ? true : false,
                oversize: _?.oversize ? true : false,
                vendor_id: _?.vendor_id,
                brand_id: _?.brand_id,
                unit_cost: _?.unit_cost,
              });
              setSelectedAdvanceFilter(_);
              setFilters({
                ...filters,
                ...obj,
              });
              ListItemsAction({
                ...filters,
                ...obj,
              });
            }}
            value={filters?.usf_id}
            allowClear
            className="w-200px"
            size="large"
            placeholder="Saved Filter"
          />
          <Button
            onClick={() => setMoreFilterView(true)}
            color="primary"
            variant="filled"
          >
            <Icon width={22} icon="solar:filter-bold-duotone" />
          </Button>
        </div>
        <div className="d-flex gap-2">
          <Tooltip placement="left" title="Export">
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) => {
                    const { key } = e;
                    ListItemsAction({
                      export: key === "export_current_view" ? "perpage" : "all",
                      loading: false,
                      page: filters.page,
                      marketplace_id: selectedMarketplace,
                    });
                  }}
                >
                  <Menu.Item key="export_current_view">
                    Export Current View
                  </Menu.Item>
                  <Menu.Item key="export_all">Export All</Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button type="primary">
                <Icon width={24} icon="lets-icons:export-fill" />
              </Button>
            </Dropdown>
          </Tooltip>
          <Dropdown
            disabled={selectedRow?.length === 0}
            overlay={
              <Menu onClick={handleMenuClick}>
                <Menu.Item key="create_prep_log">
                  {selectedRow.length > 0
                    ? `Prep Log ${selectedRow.length} item`
                    : "Create Prep Log"}
                </Menu.Item>
                <Menu.Item key="days_of_stock">Days of Stock</Menu.Item>
                <Menu.Item key="add_asin_lead_time">
                  Add ASIN Lead Time
                </Menu.Item>
                <Menu.Item key="add_asin_note">Add ASIN Note</Menu.Item>
                <Menu.Item key="add_pericer_type">Add Repricer Type</Menu.Item>
                <Menu.Item key="add_supply_type">Add Supply Type</Menu.Item>
                <Menu.Item key="enable_listing">Enable Listing</Menu.Item>
                <Menu.Item key="disable_listing">Disable Listing</Menu.Item>
                <Menu.Item key="set_brand">Set Brand</Menu.Item>
                <Menu.Item key="set_vendor">Set Vendor</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button color="primary" variant="filled">
              Bulk Actions <Icon icon="bxs:down-arrow" />
            </Button>
          </Dropdown>
          <Tooltip placement="left" title="Components List">
            <Button type="dashed">
              <Link className="d-flex align-items-center" to="/component-list">
                <Icon icon="icon-park-outline:list" width={20} />
                &nbsp;
              </Link>
            </Button>
          </Tooltip>
          <Tooltip placement="left" title="Add Components">
            <Button onClick={showModal} type="primary">
              <Icon icon="mdi:add-bold" width={24} />
            </Button>
          </Tooltip>
          <Tooltip placement="left" title="Link Components">
            <Button onClick={() => setLinkComponentModal(true)} type="primary">
              <Icon icon="mingcute:link-fill" width={24} />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="card p-3 mt-3">
        <div className="card-body p-0">
          {loading ? (
            <TableLoading
              id="test-table"
              row={10}
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={false}
              actions={[]}
              style={{ height: "calc(100vh - 500px)", overflow: "hidden" }}
            />
          ) : list?.length === 0 ? (
            <Empty />
          ) : (
            <Table2
              columns={columns?.map((d) => ({
                ...d,
                ...PropsFilter(d?.dataIndex),
              }))}
              dataSource={list}
              loading={loading}
              setSortFilters={setSortFilters}
              sortFilters={sortFilters}
              onSortChange={(sort, currentPage, pageSize) => {
                const obj = {
                  ...filters,
                  page: currentPage,
                  "page-size": pageSize,
                };
                setFilters(obj);
                ListItemsAction({
                  ...obj,
                  sort,
                });
              }}
              pagination={PaginationConfig({
                pageSize: filters["page-size"],
                currentPage: filters.page,
                totalPage: filters.totalPage,
              })}
              rowKey="fald_id"
              size="small"
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedRow?.map((d) => d?.fald_id),
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRow(selectedRows);
                },
              }}
              rowClassName={(e) => {
                return filters.action === "disabled" ? "disabled-row" : "";
              }}
              expandIcon={({ expanded, onExpand, record }) => (
                <Icon
                  icon={
                    record.linkStatus !== undefined &&
                    record.linkStatus === true
                      ? "bx:link"
                      : "tabler:unlink"
                  }
                  style={{
                    color:
                      record.linkStatus !== undefined &&
                      record.linkStatus === true
                        ? "green"
                        : "red",
                    cursor: "pointer",
                    transition: "all 0.3s",
                  }}
                  width={22}
                  onClick={(e) => onExpand(record, e)}
                  rotate={expanded ? 90 : 0}
                />
              )}
              expandedRowRender={(record) => (
                <div className="py-1">
                  <LinkItems
                    userData={userData}
                    asin={record?.asin1}
                    record={record}
                    id={record?.id}
                    expandView={false}
                  />
                </div>
              )}
              scroll={{ x: "max-content", y: "calc(100vh - 450px)" }}
            />
          )}
        </div>
      </div>

      <AsinHistory
        asinCostHistory={asinCostHistory}
        handleCancel={handleCancel}
        columnsASINCost={columnsASINCost}
      />
      <UnitHistory
        unitCostHistory={unitCostHistory}
        handleCancel={handleCancel}
        columnsUnitCost={columnsUnitCost}
      />
      <ASINNote
        userData={userData}
        onSubmit={(e, id) => {
          setList((prev) => {
            return prev.map((d) => {
              if (d.fald_id === id) {
                return {
                  ...d,
                  item_note: e?.itemNote,
                };
              }
              return d;
            });
          });
        }}
        asinNote={asinNote}
        handleCancel={handleCancel}
      />
      {isModalVisible && (
        <EditItemsModal
          editForm={editForm}
          visible={isModalVisible}
          onCancel={handleCancelEdit}
          onSave={handleSave}
          selectedMarketplace={selectedMarketplace}
          initialData={initialData}
          brandList={brandList}
          brandLoading={brandLoading}
          vendorList={vendorList}
          vendorLoading={vendorLoading}
          // getList={() => ListItemsAction({ ...filters })}
          supplyList={supplyList}
          supplyLoading={supplyLoading}
          productLinesList={productLinesList}
          userData={userData}
          productLinesLoading={productLinesLoading}
          getProductLineAction={GetProductListsAction}
        />
      )}
      <AddComponentsModal
        visible={visible}
        brandList={brandList}
        brandLoading={brandLoading}
        userData={userData}
        vendorList={vendorList}
        vendorLoading={vendorLoading}
        onClose={handleClose}
      />
      <BulkModalAction
        show={isModalVisibleBulk}
        onSubmit={(e) => handleOkBulk(e)}
        onClose={handleCancelBulk}
        selectedBulkData={selectedBulkData}
        form={form}
      />
      <AdvancedFilter
        setMoreFilterView={setMoreFilterView}
        moreFilterView={moreFilterView}
        brandList={brandList}
        brandLoading={brandLoading}
        vendorList={vendorList}
        vendorLoading={vendorLoading}
        userData={userData}
        advanceFilterLoading={advanceFilterLoading}
        advanceFilterList={advanceFilterList}
        setSelectedAdvanceFilter={setSelectedAdvanceFilter}
        selectedAdvanceFilter={selectedAdvanceFilter}
        getList={AdvanceFiltersAction}
        form={Filterform}
        applyFilter={(e) => {
          setMoreFilterView(false);
          setFilters({
            ...filters,
            ...e,
          });
          ListItemsAction({
            ...filters,
            ...e,
          });
        }}
      />
      <PrepLogModal
        visible={prepLogModal}
        userData={userData}
        onCancel={handleCancelPrepLog}
        view={prepLogData}
      />
      {linkComponentModal && (
        <LinkComponents
          show={linkComponentModal}
          userData={userData}
          setFilter={setFilter}
          filter={filter}
          getList={() => {
            ListItemsAction({ ...filters });
          }}
          onClose={() => {
            setLinkComponentModal(false);
          }}
        />
      )}
    </Wrapper>
  );
};

export default ItemMaster;
