import React, { useEffect } from "react";
import { CompoenntWrapper } from "../style";
import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import Table2 from "../../../../Components/tables/table2";
import { MakeApiCall } from "../../../../apis";
import { useState } from "react";
import {
  ConvertParams,
  FormatNumber,
  PaginationConfig,
} from "../../../../config";
import { render } from "less";
// import AddHistoryModal from "./components-lib/add-history";
import AddComponentsModal from "./add-components";
import GetUnitCostHistory from "./components-lib/add-history";
import AddHistoryModal from "./components-lib/add-history-plus";

const ComponentList = (props) => {
  const { userData } = props;
  const [brandList, setBrandList] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [advanceList, setAdvanceList] = useState([]);
  const [advanceLoading, setAdvanceLoading] = useState(true);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
    page_count: 0,
    filter: "enabled",
    filter_value: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [formHistory] = Form.useForm();
  const [selectedRow, setSelectedRow] = useState({});
  const [visible, setVisible] = useState(false);
  const [unitCostHistory, setUnitCostHistory] = useState(false);
  const [historyType, setHistoryType] = useState("view");

  const filterBy = [
    { label: "All", value: "all" },
    { label: "Enabled", value: "enabled" },
    { label: "Disabled", value: "disabled" },
  ];
  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalAdd = () => {
    setVisible(true);
    setSelectedRow({});
  };

  const handleClose = () => {
    setVisible(false);
    setSelectedRow({});
  };

  const GetBrandListAction = async () => {
    setBrandLoading(true);
    setBrandList([]);
    try {
      const response = await MakeApiCall(
        `brands?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setBrandList(response?.data?.records || []);

        setBrandLoading(false);
      } else {
        setBrandList([]);
        message.destroy();
        setBrandLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setBrandList([]);
      setBrandLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetVendorListAction = async () => {
    setVendorLoading(true);
    setVendorList([]);
    try {
      const response = await MakeApiCall(
        `vendors?page=all`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setVendorList(response?.data?.records || []);
        setVendorLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setVendorLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorLoading(false);
      setVendorList([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const GetAdvanceFilterListAction = async () => {
    setAdvanceLoading(true);
    setAdvanceList([]);
    try {
      const response = await MakeApiCall(
        `advance-filter-component`,
        "post",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setAdvanceList(response?.data?.records || []);
        setAdvanceLoading(false);
      } else {
        message.destroy();
        setVendorList([]);
        setAdvanceLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setVendorList([]);
      setAdvanceLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const ComponentListAction = async (data) => {
    const { totalPage, page_count, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `components${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { pagination, records } = response?.data;
        const { totalCount, page_count, page, page_size } = pagination;
        if (records?.length > 0) {
          setList(records || []);
          setFilters({
            ...data,
            page: page,
            "per-page": page_size,
            page_count: page_count,
            totalPage: totalCount,
          });
        } else {
          setList([]);
          setLoading(false);
        }

        setLoading(false);
      } else {
        message.destroy();
        setList([]);
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      setList([]);
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ComponentListAction({
      ...filters,
    });
    GetAdvanceFilterListAction();
    GetVendorListAction();
    GetBrandListAction();
    return () => {};
  }, []);

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          size="small"
          color="primary"
          className="p-2 py-4"
          variant="filled"
          onClick={() => handleAction(record)}
        >
          <Icon width={24} icon="flowbite:edit-solid" />
        </Button>
      ),
    },
    {
      title: "Component ID",
      dataIndex: "component_id",
      key: "component_id",
      render: (e) => {
        return <Tag color="blue">{e || "-"}</Tag>;
      },
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "unit_cost",
      render: (e) => {
        return (
          <Flex gap={5}>
            <Typography.Text>${FormatNumber(e || 0)}</Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: ["brands", "b_name"],
      key: ["brands", "b_name"],
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Vendor",
      dataIndex: ["vendors", "name"],
      key: ["vendors", "name"],
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Unit Cost History",
      dataIndex: "unitCostHistory",
      key: "unitCostHistory",
      render: (_, e) => {
        return (
          <Flex gap={5}>
            <Button
              color="default"
              className="p-2 py-4"
              variant="filled"
              size="small"
              onClick={() => {
                setUnitCostHistory(true);
                setSelectedRow(e);
                setHistoryType("view");
              }}
            >
              <Icon width={20} icon="material-symbols:history" />
            </Button>
            <Button
              size="small"
              color="primary"
              className="p-2 py-4"
              variant="filled"
              onClick={() => {
                setUnitCostHistory(true);
                setSelectedRow(e);
                setHistoryType("edit");
              }}
            >
              <Icon width={20} icon="flowbite:edit-solid" />
            </Button>
            <Button
              size="small"
              color="primary"
              className="p-2 py-4"
              variant="solid"
              onClick={() => {
                showModal();
                setSelectedRow(e);
                setHistoryType("edit");
              }}
            >
              <Icon width={20} icon="ic:baseline-plus" />
            </Button>
          </Flex>
        );
      },
    },
    {
      title: "Item Note",
      dataIndex: "note",
      render: (e) => {
        return e || "-";
      },
      key: "note",
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Case Quantity",
      dataIndex: "case_quantity",
      key: "case_quantity",
      render: (e) => {
        return (
          <Flex gap={5}>
            <Typography.Text>{FormatNumber(e || 0)}</Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Component MOQ",
      dataIndex: "moq",
      key: "moq",
    },
    {
      title: "Vendor SKU",
      dataIndex: "vendor_sku",
      key: "vendor_sku",
    },
    {
      title: "Date Active",
      dataIndex: "date_active",
      key: "date_active",
    },
  ];

  // Action handler (Customize it for your use case)
  const handleAction = (record) => {
    setSelectedRow(record);
    setVisible(true);
  };
  return (
    <CompoenntWrapper>
      <Flex justify="space-between">
        <Flex wrap gap={10}>
          <Select
            size="large"
            options={filterBy}
            value={filters.filter}
            onChange={(e) => {
              setFilters({
                ...filters,
                filter: e,
              });
              ComponentListAction({
                ...filters,
                filter: e,
              });
            }}
            className="w-125px"
            placeholder="Status"
          />
          <Input
            onChange={(e) => {
              setFilters({
                ...filters,
                filter_value: e.target.value,
              });
            }}
            onPressEnter={(e) => {
              ComponentListAction({
                ...filters,
              });
            }}
            className="w-250px"
            placeholder="Search By Title etc..."
          />
          <Select
            size="large"
            allowClear
            options={[
              { label: "Filter 1", value: "1" },
              { label: "Filter 2", value: "2" },
              { label: "Filter 3", value: "3" },
            ]}
            placeholder="Saved Filters"
          />
          <Button color="primary" variant="dashed">
            <Icon width={22} icon="solar:filter-bold-duotone" />
          </Button>
        </Flex>
        <Button onClick={showModalAdd} type="primary">
          + Add Component
        </Button>
      </Flex>
      <Table2
        dataSource={list}
        className="mt-3"
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={PaginationConfig({
          pageSize: filters["per-page"],
          currentPage: filters.page,
          totalPage: filters.totalPage,
          onChange: (e, f) => {
            const obj = {
              ...filters,
              page: e,
              "per-page": f,
            };
            setFilters(obj);
            ComponentListAction({
              ...obj,
            });
          },
        })}
      />
      <AddHistoryModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        form={form}
        vendorList={vendorList}
        userData={userData}
        vendorLoading={vendorLoading}
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
      />
      <AddComponentsModal
        visible={visible}
        selectedRow={selectedRow}
        getList={() =>
          ComponentListAction({
            ...filters,
          })
        }
        brandList={brandList}
        brandLoading={brandLoading}
        userData={userData}
        vendorList={vendorList}
        vendorLoading={vendorLoading}
        onClose={handleClose}
      />

      <GetUnitCostHistory
        setIsModalVisible={setUnitCostHistory}
        isModalVisible={unitCostHistory}
        historyType={historyType}
        form={formHistory}
        vendorList={vendorList}
        userData={userData}
        vendorLoading={vendorLoading}
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
      />
    </CompoenntWrapper>
  );
};

export default ComponentList;
