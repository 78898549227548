import React from "react";
import { Table1Wrapper } from "./style";
import { Empty, Table } from "antd";
import { TableLoading } from "../table-animation";

export default function Table1(props) {
  const { dataSource, loading } = props;
  if (dataSource?.length === 0 && !loading) {
    return (
      <Empty
        description={false}
        style={{
          height: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }
  if (loading) {
    return (
      <TableLoading
        id="test-table"
        row={10}
        columns={[1, 2, 3, 4]}
        checkBoxVal={false}
        actions={[]}
        // style={{ height: "260px", overflow: "hidden" }}
      />
    );
  }
  return (
    <Table1Wrapper>
      <Table dataSource={dataSource} {...props} />
    </Table1Wrapper>
  );
}
