import { Badge, Button, Empty, Flex, message, Modal, Spin, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { MakeApiCall } from "../../../../apis";

const InTransitInventoryModal = (props) => {
  const { isOpen, data, closeModal, userData } = props;

  console.log(data?.value?.fnsku, "data?.value?.fnsku");

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const getInTransitInventoryAction = async () => {
    setLoading(true);
    setListData([]);
    const response = await MakeApiCall(
      `model-in-transit`,
      "POST",
      {
        fnsku: data?.value?.fnsku,
      },
      true,
      {},
      userData?.user?.auth_token
    );
    if (response?.status === true) {
      setListData(response?.data?.model || []);
      setLoading(false);
    } else if (response?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getInTransitInventoryAction();

    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Status",
      dataIndex: "shipment_status",
      key: "shipment_status",
      render: (shipment_status) => (
        <Badge status="success" text={shipment_status} />
      ),
    },

    {
      title: "Shipment ID",
      dataIndex: "shipment_id",
      key: "shipment_id",
    },
    {
      title: "Quantity Shipped",
      dataIndex: "quantity_shipped",
      key: "quantity_shipped",
      render: (quantity_shipped) => quantity_shipped ?? 0,
    },
    {
      title: "Quantity Received",
      dataIndex: "quantity_received",
      key: "quantity_received",
      render: (quantity_received) => quantity_received ?? 0,
    },
    {
      title: "Quantity Remaining",
      dataIndex: "quantity_incase",
      key: "quantity_incase",
      render: (quantity_incase) => quantity_incase ?? 0,
    },
  ];

  return (
    <Modal
      open={isOpen}
      onCancel={closeModal}
      title="In Transit Inventory"
      width={800}
      footer={[
        <Button
          key="close"
          size="small"
          variant="solid"
          color="default"
          onClick={closeModal}
        >
          Close
        </Button>,
      ]}
    >
      {listData.length === 0 ? (
        <Flex justify="center" align="center" style={{ height: "200px" }}>
          {loading ? <Spin tip="Loading..." /> : <Empty />}
        </Flex>
      ) : (
        <Table
          columns={columns}
          dataSource={listData?.map((d, i) => ({ ...d, key: i + 1 }))}
          rowKey={"key"}
          pagination={false}
          loading={loading}
        />
      )}
    </Modal>
  );
};

export default InTransitInventoryModal;
