import styled from "styled-components";

export const TopbarWrapper = styled.div`
  .ant-menu-light {
    background-color: transparent !important;
    border-bottom: none !important;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom-width: 0px !important;
    border-bottom-color: transparent !important;
  }
  .ant-menu-light .ant-menu-item {
    color: rgb(219 223 233) !important;
    font-weight: 500 !important;
    &:hover {
      color: #ffffff !important;
    }
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
    border-bottom-width: 0px !important;
    border-bottom-color: transparent !important;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
    color: #ffffff !important;
    border-radius: 0.75rem !important;
    background-color: #07587f !important;
    font-weight: 600 !important;
  }
  .ant-menu-submenu-title {
    span {
      color: rgb(219 223 233) !important;
      font-weight: 500 !important;
      &:hover {
        color: #ffffff !important;
      }
    }
  }
`;
export const BackToAdminWrapper = styled.button`
  font-weight: bold;
  color: white;
  background-color: #171717;
  padding: 1em 2em;
  border: none;
  border-radius: 0.6rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  span:not(:nth-child(6)) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 50px;
    background-color: #0079af;
    border-radius: 50%;
    transition: 0.6s ease;
  }

  span:nth-child(6) {
    position: relative;
  }

  span:nth-child(1) {
    transform: translate(-6em, -5em);
  }

  span:nth-child(2) {
    transform: translate(-9em, 1.3em);
  }

  span:nth-child(3) {
    transform: translate(-0.6em, 2em);
  }

  span:nth-child(4) {
    transform: translate(5em, 1.4em);
  }

  span:nth-child(5) {
    transform: translate(5em, -3.8em);
  }

  &:hover span:not(:nth-child(6)) {
    transform: translate(-50%, -50%) scale(4);
    transition: 1.5s ease;
  }
`;
export const FooterWrapper = styled.div``;
