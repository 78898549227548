import React, { useEffect } from "react";
import Table2 from "../../../../Components/tables/table2";
import { useState } from "react";
import { message } from "antd";
import { MakeApiCall } from "../../../../apis";
import moment from "moment";

const ProductLine = ({ userData, marketplace_id }) => {
  const columns = [
    {
      title: "Product Line",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created At",
      render: (d) => {
        return moment(new Date(parseInt(d.created_at) * 1000)).format(
          "MM/DD/YYYY hh:mm A"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (d) => {
        return (
          <div className="d-flex flex-shrink-0 justify-content-center">
            <a className="btn btn-icon bg-light btn-active-color-primary btn-sm me-2">
              <i className="ki-outline ki-pencil fs-2 text-gray-800" />
            </a>
            <a className="btn btn-icon bg-light btn-active-color-primary btn-sm">
              <i className="ki-outline ki-trash fs-2 text-primary" />
            </a>
          </div>
        );
      },
    },
  ];

  const [productList, setProductList] = useState([]);
  const [productListLoading, setProductListLoading] = useState(true);

  const GetProductLineData = async () => {
    try {
      setProductList([]);
      setProductListLoading(true);
      const response = await MakeApiCall(
        `product-lines`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setProductList(response?.data?.records || []);
        setProductListLoading(false);
      } else {
        setProductList([]);
        setProductListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setProductList([]);
      setProductListLoading(false);
      message.warning(error?.message);
    }
  };

  useEffect(() => {
    GetProductLineData();
    return () => {};
  }, []);

  return (
    <div>
      <Table2
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={productList?.filter(
          (d) => d?.marketplace_id === marketplace_id
        )}
        loading={productListLoading}
      />
    </div>
  );
};

export default ProductLine;
