export const UserMenus = [
  {
    label: "Dashboard",
    key: "dashboard",
  },
  {
    label: "Item Master",
    key: "item-master",
  },
  // {
  //   label: "Purchase Order",
  //   key: "purchase-order",
  //   children: [
  //     {
  //       label: "Create Order",
  //       key: "create-order",
  //     },
  //     {
  //       label: "PO List",
  //       key: "po-list",
  //     },
  //   ],
  // },
  {
    label: "Purchase",
    key: "purchase",
    children: [
      {
        label: "Purchase Analysis",
        key: "purchase",
      },
      {
        label: "Create Order",
        key: "create-order",
      },
      {
        label: "PO List",
        key: "po-list",
      },
    ],
  },
  {
    label: "Insights",
    key: "insights",
  },

  {
    label: "Variance",
    key: "variance",
  },

  {
    label: "Inventory",
    key: "inventory",
  },
  {
    label: "Subscribe & Save",
    key: "subscribe-&-save",
  },
  {
    label: "Financial Summary",
    key: "financial-summary",
  },

  // {
  //   label: "Item Setup",
  //   key: "item-setup",
  //   children: [
  //     {
  //       label: "Brand Setup",
  //       key: "brand-setup",
  //     },
  //     {
  //       label: "Vendors Setup",
  //       key: "vendors-setup",
  //     },
  //     {
  //       label: "Supply Setup",
  //       key: "supply-setup",
  //     },
  //   ],
  // },
];
