import $ from "jquery";
import { useEffect } from "react";

import { Button, Modal } from "antd";

const VendorDropdownList = (props) => {
  useEffect(() => {
    setTimeout(() => {
      $(".listData").append(props.linkList);
    }, 10);
    return () => {
      setTimeout(() => {
        $(".listData").append(null);
      }, 10);
    };
  }, []);

  return (
    <Modal
      title="Add To Existing Purchase Order"
      open={props.isOpen}
      onCancel={props.closeModal}
      centered
      footer={[
        <Button
          key="close"
          type="primary"
          size="small"
          onClick={props.closeModal}
        >
          Close
        </Button>,
      ]}
      width={800}
    >
      <div className="listData" />
    </Modal>
  );
};

export default VendorDropdownList;
