import { DatePicker, Select } from "antd";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import React, { useEffect, useRef } from "react";

export default function Inventory() {
  const chartRef = useRef(null);
  const chartRef1 = useRef(null);
  useEffect(() => {
    // Create root element
    const root = am5.Root.new(chartRef.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create pie chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 180,
        endAngle: 360,
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    );

    // Define data
    const data = [
      {
        status: "In Stock",
        sales: 3,
        sliceSettings: {
          fill: am5.color(0x0079ae),
          stroke: am5.color(0xffffff),
        },
      },
      {
        status: "Out of Stock",
        sales: 5,
        sliceSettings: {
          fill: am5.color(0xd32027),
          stroke: am5.color(0xffffff),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "sales",
        categoryField: "status",
        startAngle: 180,
        endAngle: 360,
        alignLabels: false,
      })
    );

    series.slices.template.setAll({
      templateField: "sliceSettings",
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
    });

    series.data.setAll(data);

    // Customize series appearance
    series.states.create("hidden", {
      startAngle: 180,
      endAngle: 180,
    });

    series.slices.template.setAll({
      cornerRadius: 5,
    });

    series.ticks.template.setAll({
      forceHidden: true,
    });

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.horizontalLayout,
      })
    );

    legend.data.setAll(series.dataItems);

    series.appear(1000, 100);

    // Clean up on component unmount
    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    // Create root element
    const root = am5.Root.new(chartRef1.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create pie chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 180,
        endAngle: 360,
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    );

    // Define data
    const data = [
      {
        status: "Over Stock",
        sales: 2,
        sliceSettings: {
          fill: am5.color(0x0079ae),
          stroke: am5.color(0xffffff),
        },
      },
      {
        status: "Low Stock",
        sales: 4,
        sliceSettings: {
          fill: am5.color(0xd32027),
          stroke: am5.color(0xffffff),
        },
      },
      {
        status: "Optimal",
        sales: 1,
        sliceSettings: {
          fill: am5.color(0x17c653),
          stroke: am5.color(0xffffff),
        },
      },
      {
        status: "Running Low",
        sales: 1,
        sliceSettings: {
          fill: am5.color(0xf6c000),
          stroke: am5.color(0xffffff),
        },
      },
    ];

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "sales",
        categoryField: "status",
        startAngle: 180,
        endAngle: 360,
        alignLabels: false,
      })
    );

    // Configure slices
    series.slices.template.setAll({
      templateField: "sliceSettings",
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 5,
    });

    series.data.setAll(data);

    // Configure series
    series.states.create("hidden", {
      startAngle: 180,
      endAngle: 180,
    });

    // Hide ticks
    series.ticks.template.setAll({
      forceHidden: true,
    });

    // Add legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.horizontalLayout,
      })
    );

    legend.data.setAll(series.dataItems);

    series.appear(1000, 100);

    // Cleanup on unmount
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            placeholder="Select Marketplace"
          />
        </div>
        <div className="position-relative">
          <DatePicker.RangePicker
            size="large"
            className="w-250px"
            suffixIcon={
              <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
              </i>
            }
          />
        </div>

        <div className="position-relative">
          <Select size="large" className="w-150px" placeholder="Select ASIN" />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            placeholder="Select Product Line"
          />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            placeholder="Select Product"
          />
        </div>
        <div className="position-relative">
          <Select size="large" className="w-175px" placeholder="Select Brand" />
        </div>
      </div>
      <div class="row gx-5 gx-xl-7">
        <div class="col-xl-6 mb-5 mb-xl-5">
          <div class="card card-flush h-xl-100">
            <div class="card-header min-h-50px mb-0">
              <h3 class="card-title pt-0 align-items-start flex-column">
                <span class="card-label fw-bold text-gray-800">SKU Health</span>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body pt-0 pb-0">
              <div
                ref={chartRef1}
                style={{ width: "100%", height: "300px" }}
              ></div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 mb-5 mb-xl-5">
          <div class="card card-flush h-xl-100 mb-5">
            <div class="card-header min-h-50px mb-0">
              <h3 class="card-title pt-0 align-items-start flex-column">
                <span class="card-label fw-bold text-gray-800">
                  Stock Status
                </span>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body pt-0 pb-0">
              <div
                ref={chartRef}
                style={{ width: "100%", height: "300px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gx-5 gx-xl-7 mb-7">
        <div className="col-lg-12">
          <div className="card card-flush h-xl-100">
            <div className="card-header  mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Overview
                </span>
              </h3>
              <div className="card-toolbar">
                <div className="d-flex flex-wrap pt-0 ">
                  {/*begin::Item*/}
                  <div className="d-flex flex-row gap-6">
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-0 mb-sm-0">
                      <span className="bullet bullet-dot bg-primary me-2 h-15px w-15px" />
                      <span className="fw-bold text-gray-900 fs-6">
                        Overstock &gt;= 40 Days
                      </span>
                    </div>
                    {/*ed::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center">
                      <span className="bullet bullet-dot bg-danger me-2 h-15px w-15px" />
                      <span className="fw-bold text-gray-900 fs-6">
                        Optimal 21-31 Days
                      </span>
                    </div>
                    {/*ed::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-0 mb-sm-0">
                      <span className="bullet bullet-dot bg-success me-2 h-15px w-15px" />
                      <span className="fw-bold text-gray-900 fs-6">
                        Running Low 14-21 Days
                      </span>
                    </div>
                    {/*ed::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center">
                      <span className="bullet bullet-dot bg-warning me-2 h-15px w-15px" />
                      <span className="fw-bold text-gray-900 fs-6">
                        Low Stock 1-14 Days
                      </span>
                    </div>
                    {/*ed::Item*/}
                  </div>
                  {/*ed::Item*/}
                </div>
              </div>
            </div>
            {/*begin::Body*/}
            <div className="card-body pt-2">
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed border-gray-300 table-row-gray-300 gy-3 gx-4 gs-4 mb-0">
                  {/*begin::Table head*/}
                  <thead>
                    <tr className="fw-bolder text-gray-800 bg-main-light">
                      <th className="min-w-175px ">Item</th>
                      <th className="min-w-100px ">Brand</th>
                      <th className="min-w-125px ">Product Line</th>
                      <th className="min-w-175px ">Inventory Valuation</th>
                      <th className="min-w-150px ">Is Replenishable</th>
                      <th className="min-w-125px ">Stock Status</th>
                      <th className="min-w-125px ">Amazon DOB</th>
                      <th className="min-w-100px ">DOB</th>
                      <th className="min-w-125px ">DOB + Inbound</th>
                      <th className="min-w-175px ">Unit Sold Yesterday</th>
                      <th className="min-w-125px ">Ordered Unit</th>
                      <th className="min-w-125px ">Stock out days</th>
                      <th className="min-w-125px ">Current Stock</th>
                      <th className="min-w-100px ">In Transit</th>
                      <th className="min-w-125px ">Available Qty</th>
                      <th className="min-w-125px ">Total Inbound</th>
                    </tr>
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Water Filter, 1 pk (2)
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Zerowater
                          </a>
                        </div>
                      </td>
                      <td>Filter</td>
                      <td>$17,900.00</td>
                      <td>-</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-success">
                          In Stock
                        </span>
                      </td>
                      <td>45.1</td>
                      <td>
                        <div className="d-flex align-items-center mb-0 mb-sm-0">
                          <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px" />
                          <span className="text-gray-900 ">209</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center mb-0 mb-sm-0">
                          <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px" />
                          <span className="text-gray-900 ">209</span>
                        </div>
                      </td>
                      <td>8</td>
                      <td>29</td>
                      <td>0</td>
                      <td>890</td>
                      <td>0</td>
                      <td>399</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Water Filter, 1 pk (2)
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Zerowater
                          </a>
                        </div>
                      </td>
                      <td>Filter</td>
                      <td>$17,900.00</td>
                      <td>-</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-success">
                          In Stock
                        </span>
                      </td>
                      <td>45.1</td>
                      <td>
                        <div className="d-flex align-items-center mb-0 mb-sm-0">
                          <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px" />
                          <span className="text-gray-900 ">209</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center mb-0 mb-sm-0">
                          <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px" />
                          <span className="text-gray-900 ">209</span>
                        </div>
                      </td>
                      <td>8</td>
                      <td>29</td>
                      <td>0</td>
                      <td>890</td>
                      <td>0</td>
                      <td>399</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <a href className="fw-bolder fs-6 text-gray-800">
                            Water Filter, 1 pk (2)
                          </a>
                          <div className="fs-7 mt-1 text-gray-600">
                            ASIN: <b>B0189G520K</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <a href className="fw-bold fs-6">
                            Zerowater
                          </a>
                        </div>
                      </td>
                      <td>Filter</td>
                      <td>$17,900.00</td>
                      <td>-</td>
                      <td>
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">
                          Out of Stock
                        </span>
                      </td>
                      <td>45.1</td>
                      <td>
                        <div className="d-flex align-items-center mb-0 mb-sm-0">
                          <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px" />
                          <span className="text-gray-900 ">209</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center mb-0 mb-sm-0">
                          <span className="bullet bullet-dot bg-primary me-2 h-10px w-10px" />
                          <span className="text-gray-900 ">209</span>
                        </div>
                      </td>
                      <td>8</td>
                      <td>29</td>
                      <td>0</td>
                      <td>890</td>
                      <td>0</td>
                      <td>399</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                  <tfoot>
                    <tr className="bg-gray-100 fw-bolder border-top border-bottom border-dashed border-gray-300">
                      <th colSpan={3}>Total</th>
                      <th>$381,911.19</th>
                      <th>8</th>
                      <th>10</th>
                      <th>199.55</th>
                      <th>361</th>
                      <th>571</th>
                      <td>167</td>
                      <td>921</td>
                      <td>70</td>
                      <td>10,524</td>
                      <td>1607</td>
                      <td>3803</td>
                      <td>2487</td>
                    </tr>
                  </tfoot>
                </table>
                {/*end::Table*/}
              </div>
            </div>
          </div>
        </div>
        {/*begin::Col*/}
      </div>
    </div>
  );
}
