import React from "react";
import { FooterWrapper } from "./style";

export default function Footer() {
  return (
    <FooterWrapper id="kt_app_footer" className="app-footer pb-3">
      <>
        <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack">
          {/*begin::Copyright*/}
          <div className="text-dark order-2 order-md-1">
            <span className="fw-semibold me-.0">Copyright ©</span>
            <a
              href
              target="_blank"
              className="text-primary fw-bolder text-hover-primary"
            >
              Cheddy.
            </a>
            <span className="fw-semibold me-1">
              {" "}
              {new Date().getFullYear()} All rights reserved.
            </span>
          </div>
          {/*end::Copyright*/}
          {/*begin::Menu*/}
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <a href target="_blank" className="menu-link px-2">
                About
              </a>
            </li>
            <li className="menu-item">
              <a href target="_blank" className="menu-link px-2">
                Support
              </a>
            </li>
          </ul>
          {/*end::Menu*/}
        </div>
      </>
    </FooterWrapper>
  );
}
