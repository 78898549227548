import React, { useState } from "react";
import { Wrapper } from "./style";
import { Button, message, Tabs } from "antd";
import Brand from "./lib/brand";
import ProductLine from "./lib/product-line";
import MappingView from "./lib/mapping";
import ImportFIle from "../../../Components/import-file";
import { Icon } from "@iconify/react/dist/iconify.js";
import MarketplaceSelect from "../../../Components/marketplace";
import { URL } from "../../../config";

const Mapping = ({ userData }) => {
  const [selectedTab, setSelectedTab] = useState("1");
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  return (
    <Wrapper>
      <Tabs
        onChange={(e) => {
          setSelectedTab(e);
        }}
        activeKey={selectedTab}
        tabBarExtraContent={{
          right: (
            <div className="d-flex align-items-center">
              {selectedTab === "2" && (
                <>
                  <div className="position-relative me-3">
                    <MarketplaceSelect
                      showSearch
                      callAfterMarketPlace={(e) => {
                        setSelectedMarketplace(e);
                      }}
                      MarketplaceData={(e) => {}}
                      onChange={(e) => {
                        setSelectedMarketplace(e);
                      }}
                      userData={userData}
                    />
                  </div>
                  <ImportFIle
                    fileType=".xlsx"
                    multiple={false}
                    documentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    downloadSamplePath={`${URL}Product Line Template.xlsx`}
                    trigger={["click"]}
                    endpoint="import-product-lines"
                    methodKey="POST"
                    bodyKey={{
                      marketplace_id: selectedMarketplace,
                    }}
                    paramKey="UploadedDataFile[import_file]"
                    onResponse={(e) => {
                      if (e?.status === true) {
                        message.destroy();
                        message.success(e?.message);
                      } else {
                        message.destroy();
                        message.error(e?.message);
                      }
                    }}
                    tokenKey={userData?.token}
                  >
                    <Button
                      type="primary"
                      style={{ marginRight: "10px", padding: "5px 10px" }}
                    >
                      <Icon icon="bi:filetype-xlsx" width={18} /> Import
                    </Button>
                  </ImportFIle>
                </>
              )}
            </div>
          ),
        }}
        type="card"
      >
        <Tabs.TabPane tab="Brand" key="1">
          <Brand />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Product Line" key="2">
          <ProductLine
            marketplace_id={selectedMarketplace}
            userData={userData}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mapping" key="3">
          <MappingView />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default Mapping;
