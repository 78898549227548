import React, { useState, createContext } from "react";

export const GlobalContext = createContext();
const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
    loginType: localStorage.getItem("loginType")
      ? localStorage.getItem("loginType")
      : null,
  });

  return (
    <GlobalContext.Provider
      value={{
        data: commonGlobalVal,
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;
