import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Card,
  Flex,
  Form,
  Image,
  message,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import Table1 from "../../../../Components/tables/table1";
import { MakeApiCall } from "../../../../apis";
import { ConvertParams, URL } from "../../../../config";
import TemplateModal from "./lib/add-template";
import AddPoReferenceModal from "./lib/add-reference";

const PoTemplate = ({ userData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState(null); // Holds the data for editing
  const [addReferenceModal, setAddReferenceModal] = useState(false);
  const ListPoTemplate = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `po-templates${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const DeleteTemplate = async (id) => {
    try {
      const response = await MakeApiCall(
        `po-template/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListPoTemplate({
          ...filters,
        });
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListPoTemplate({
      ...filters,
    });
    setLoading(true);
    return () => {};
  }, []);

  const showCreateModal = () => {
    setEditData(null); // Reset to create mode
    setIsModalVisible(true);
  };

  const showEditModal = (data) => {
    setEditData(data); // Set data for edit mode
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const CreateTemplate = async (data) => {
    try {
      const response = await MakeApiCall(
        `po-template`,
        "post",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        handleCloseModal();
        ListPoTemplate({
          ...filters,
        });
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };
  const EditTemplate = async (data) => {
    try {
      const response = await MakeApiCall(
        `po-template/${editData?.t_id}`,
        "patch",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        handleCloseModal();
        ListPoTemplate({
          ...filters,
        });
        setEditData(null);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleSubmit = (values, type) => {
    message.destroy();
    message.loading("Loading...", 0);
    if (type === "edit") {
      EditTemplate(values);
    } else {
      CreateTemplate(values);
    }
  };

  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Logo",

      render: (row) => (
        <Image
          src={URL + "/images/" + row?.logo}
          style={{ height: "50px", marginTop: "10px", marginBottom: "10px" }}
          alt="Template image"
          onError={(e) => {
            e.target.src =
              "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      title: "Bill To Address",
      dataIndex: "bill_to_address",
      key: "bill_to_address",
    },
    {
      title: "Ship To Address",
      dataIndex: "ship_to_address",
      key: "ship_to_address",
    },
    {
      title: "Outbound Lead Time",
      dataIndex: "outbound_lead_time",
      key: "outbound_lead_time",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => showEditModal(record)}
            className="p-2"
            color="primary"
            variant="filled"
          >
            <Icon width={22} icon="flowbite:edit-solid" />
          </Button>
          <Popconfirm
            placement="left"
            title="Are you sure to permanently delete this PO Template?"
            onConfirm={() => DeleteTemplate(record.t_id)} // Define handleDelete function for actual deletion
            okText="Yes"
            cancelText="No"
          >
            <Button className="p-2" color="danger" variant="filled">
              <Icon width={22} icon="ic:round-delete" />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const GetPoTemplate = async (data) => {
    try {
      setLoading(true);
      setList([]);
      const response = await MakeApiCall(
        `po-templates${ConvertParams(data)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        setList(response?.data?.records || []);
        setLoading(false);
      } else {
        setLoading(false);
        setList([]);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      setList([]);
      message.warning(error?.message);
    }
  };

  useEffect(() => {
    GetPoTemplate({ ...filters });
    return () => {};
  }, []);

  return (
    <Wrapper>
      <Flex justify="end" flex="wrap" align="center">
        <Flex gap={10}>
          <Button
            color="primary"
            variant="filled"
            onClick={() => setAddReferenceModal(true)}
            icon={<Icon icon="material-symbols:add" />}
          >
            Add Reference Number
          </Button>
          <Button
            onClick={showCreateModal}
            type="primary"
            icon={<Icon icon="material-symbols:add" />}
          >
            Add Template
          </Button>
        </Flex>
      </Flex>
      <Card bordered={false}>
        <Table1
          dataSource={list}
          loading={loading}
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </Card>
      <TemplateModal
        visible={isModalVisible}
        onClose={handleCloseModal}
        userData={userData}
        onSubmit={handleSubmit}
        editData={editData}
      />
      <AddPoReferenceModal
        visible={addReferenceModal}
        userData={userData}
        onClose={() => {
          setAddReferenceModal(false);
        }}
      />
    </Wrapper>
  );
};

export default PoTemplate;
